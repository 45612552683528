// import { Modal } from "@material-ui/core";
import { Modal} from "@mui/material"

import classNames from "classnames";
import React, {
  createRef,
  Dispatch,
  FC,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { CloseIcon, SearchIcon } from "../common/Icons";
import styles from './LogoutPopup.module.scss';
import { deleteToken, getMessaging } from "firebase/messaging";
// import { apiKey, appId, authDomain, firebaseConfig, measurementId, messagingSenderId, projectId, storageBucket } from "../../app/services/firebase";
import { initializeApp } from "firebase/app";
interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  confirmEvent:any;
}
const LogoutPopup: FC<ICreateData> = ({ show, setShow,confirmEvent }) => {
  const navigate = useNavigate();
  // const app = initializeApp(firebaseConfig);
  // const messaging = getMessaging(app);  
  const handleConfirm=()=>{  
    localStorage.setItem('userData','');
    //deleteToken(messaging);
    navigate("/login");
    setShow(false);
    confirmEvent();
  }
  return (
    <div>
      <Modal open={show} onClose={() => setShow(false)}>
        <div>
          <div className={classNames("z-10 fixed top-0 left-0 w-full h-full")}>
            <div
              className={classNames(
                "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 md:max-w-[320px] max-w-[300px] bg-[#fff] rounded-xl"
              )}
            >
              <div className="relative text-center px-12 pt-[35px] rounded-tl-xl rounded-tr-xl">
                <p className="text-center font-poppins font-medium text-sm text-[#3F4254]">
                Are you sure to logout?
                </p>
                <span
                  onClick={() => setShow(false)}
                  className={classNames(styles.popupClose,"text-[#B5B5C3] text-20 cursor-pointer absolute top-[15px] right-[15px]")}
                >
                  <CloseIcon />
                </span>
              </div>
              <div className="pt-[30px]">
                <div className="flex justify-start">
                  <button onClick={()=>handleConfirm()}
                    className={classNames(
                      "w-1/2 px-5 py-[10px] rounded-bl-lg font-poppins font-medium text-center lg:text-sm text-xs text-[#fff] bg-darkblue hover:text-darkblue hover:bg-white"
                    )}
                  >
                    Yes, logout.
                  </button>
                  <button
                    onClick={() => {setShow(false)}}
                    className={classNames(
                      "w-1/2 px-5 py-[10px] rounded-br-lg font-poppins font-medium text-center lg:text-sm text-xs text-[#979797] bg-grayLight hover:bg-anncolor"
                    )}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default LogoutPopup;
