import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Fade, Modal } from "@mui/material";
import classNames from "classnames";
import "react-phone-input-2/lib/style.css";
import { CloseIcon, SendIcon } from "../../components/common/Icons";
import Button from "../../components/common/Button";
import { StringDropdownList } from "../../components/common/DropdownList";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import CustomDatePicker from "../../components/common/BaseDatePicker";
import { EmployeeData } from "../../components/EmployeeTable/EmployeeTable";
import eyeshow from "../../login/img/eye-show.svg";
import eyehide from "../../login/img/eye-open.svg";
import styles from './Employees.module.scss';
import {
  renderDateFormat,
  renderDateFormatYMD,
} from "../../components/DashboardCalendarView/DashboardAddEventPopup";

export type TNameOnlyPopup = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  editedData?: any;
  setEditData?: any;
  savePopupData: any;
  updatePopupData: any;
  title: string;
  isSubmit: boolean;
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  uploading: boolean;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
  departments: EmployeeData[];
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  errorMsg: string;
};
const validateEmail = (email: any) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
const genderList = [
  {
    id: 1,
    name: "Male",
  },
  {
    id: 2,
    name: "Female",
  },
  {
    id: 3,
    name: "Other",
  },
];
const EmployeePopup: FC<TNameOnlyPopup> = ({
  isOpen,
  setIsOpen,
  editedData,
  setEditData,
  savePopupData,
  updatePopupData,
  title,
  isSubmit,
  setIsSubmit,
  setUploading,
  uploading,
  departments,
  setErrorMsg,
  errorMsg,
}) => {
  const [department, setDepartment] = useState<string>("");
  const [status, setStatus] = useState<boolean>(false);
  const [gender, setGender] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [locationData, setLocation] = useState<string>("");
  const [medical, setMedical] = useState<string>("");
  const [id, setID] = useState<string>("");
  const [jobtitle, setJobtitle] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [joinedDate, setJoinedDate] = useState<Date | undefined>();
  const [selectedFileName, setSelectedFileName] = useState("No file chosen");
  const [allFile, setFile] = useState<any>({});
  const uploadRef: any = useRef(null);
  const clearData = () => {
    setName("");
    setUploading(false);
    setSelectedFileName("");
    setFile("");
    setMobile("");
    setJoinedDate(undefined);
    setDepartment("");
    setGender("");
    setPassword("");
    setEmail("");
    setStatus(false);
    setLocation("");
    setMedical("");
    setID("");
  };
  const UploadFile = useCallback(() => {
    const file = uploadRef?.current.files[0];
    setFile(file);
    setSelectedFileName(file.name);
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!file) {
      setErrorMsg("Please choose a file");
      return;
    }
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > MAX_FILE_SIZE) {
        setErrorMsg("File size exceeds the maximum allowed (100MB).");
        return;
      } else {
        setErrorMsg("");
      }
    }
  }, [setSelectedFileName, setFile, setErrorMsg]);
  const checkValidation = () => {
    if (
      department &&
      gender &&
      name &&
      jobtitle &&
      mobile &&
      (id ? true : password) &&
      email &&
      (joinedDate != null || joinedDate != undefined)
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleClick = () => {
    uploadRef.current.click();
  };
  const handleSubmit = async () => {
    setIsSubmit(true);
    setUploading(true);
    const saveData = {
      id: id,
      name: name,
      jobTitle: jobtitle,
      mobile: mobile,
      password: password,
      workEmail: email,
      department_id: department,
      status: status,
      joinedDate: joinedDate,
      gender: gender,
      location: locationData,
      medicalPlan: medical,
    };
    const check = checkValidation();
    console.log("check ", check);
    // let formSaveData = new FormData();
    // formSaveData.append("name", saveData.name);
    // formSaveData.append("jobTitle", saveData.jobTitle);
    // formSaveData.append("mobile", saveData.mobile);
    // formSaveData.append("password", saveData.password);
    // formSaveData.append("workEmail", saveData.workEmail);
    // formSaveData.append("department_id", saveData.department_id);
    // formSaveData.append("status", saveData.status.toString());
    // formSaveData.append("joinedDate", saveData.joinedDate?renderDateFormatYMD(saveData.joinedDate,'-'):'');
    // formSaveData.append("gender", saveData.gender);
    // formSaveData.append("location", saveData.location);
    // formSaveData.append("medicalPlan", saveData.medicalPlan);
    // formSaveData.append("profile", uploadRef?.current?.files[0]);
    if (!check) {
      if (editedData) {
        updatePopupData(saveData, id);
      } else {
        savePopupData(saveData, id);
      }
    } else {
      setUploading(false);
    }
  };
  const switchEyeHandler = (event: any) => {
    var hideimg = eyehide;
    var showimg = eyeshow;
    const type =
      event.target.previousElementSibling.getAttribute("type") == "password"
        ? "text"
        : "password";
    const img =
      event.target.previousElementSibling.getAttribute("type") == "password"
        ? hideimg
        : showimg;
    event.target.previousElementSibling.setAttribute("type", type);
    event.target.setAttribute("src", img);
  };
  useEffect(() => {
    if (editedData) {
      setID(editedData.id);
      setName(editedData.name);
      setStatus(editedData.status);
      setJobtitle(editedData.jobTitle);
      setMobile(editedData.mobile);
      setEmail(editedData.workEmail);
      setPassword(editedData.password);
      const genderData = genderList
        ?.find((x) => x.name == editedData.gender)
        ?.id.toString();
      setGender(genderData ? genderData : "");
      setDepartment(editedData.department?.id);
      setJoinedDate(new Date(editedData.joinedDate));
      setSelectedFileName(editedData.photo);
      setLocation(editedData.location);
      setMedical(editedData.medical);
    }
  }, [editedData]);
  useEffect(() => {
    if (!editedData) {
      clearData();
    }
    setUploading(false);
  }, [isOpen, editedData]);
  const handleChange = (e: any) => {
    const input = e.target.value;
    const formattedInput = formatPhoneNumber(input);
    setMobile(formattedInput);
  };
  const formatPhoneNumber = (input: string) => {
    // Remove all non-numeric characters
    const numbersOnly = input.replace(/\D/g, "");

    // Insert a "+" at the beginning if not present
    if (numbersOnly[0] !== "+") {
      return `+${numbersOnly}`;
    }

    return numbersOnly;
  };
  return (
    <Modal
      className="project_popup"
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
          setEditData("");
          setIsSubmit(false);
          clearData();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={isOpen} timeout={500}>
        <div className="xl:max-w-[1000px] max-w-[90%] w-full m-auto">
          <div className="modalContent w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999]">
            <div className="rounded-xl bg-white font-primary w-full">
              <div className="px-2 sm:pl-4 sm:pr-4 py-3 bg-vorpblue flex justify-between rounded-t-lg items-center relative z-[999]">
                <h3 className="text-18 leading-[27px] font-semibold text-white">
                  {title}
                </h3>
                <button
                  key={Math.random() * 100}
                  type="button"
                  aria-label="Close Modal"
                  onClick={() => {
                    setIsOpen(false);
                    setIsSubmit(false);
                    setEditData("");
                    setUploading(false);
                    setErrorMsg("");
                    clearData();
                  }}
                >
                  <CloseIcon />
                </button>
              </div>

              <div className="flex flex-col justify-between w-full px-5 py-2">
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                      Name<span className=" text-red-500">*</span>
                    </label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Name"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        isSubmit && name === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Name required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                      Job Title<span className=" text-red-500">*</span>
                    </label>
                    <input
                      value={jobtitle}
                      onChange={(e) => setJobtitle(e.target.value)}
                      type="text"
                      placeholder="Job Title"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        isSubmit && jobtitle === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Job Title required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label
                      className="text-black2 font-normal body-15"
                      htmlFor=""
                    >
                      Department <span className="text-redPink">*</span>
                    </label>
                    <div className="mt-1">
                      <StringDropdownList
                        state={department}
                        setState={setDepartment}
                        placeholder="Department"
                        className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                        dropdownItems={departments?.map((dept: any) => ({
                          value: dept.id,
                          label: dept.name_en,
                        }))}
                        textAlign={"text-left"}
                        fontSize="md:text-sm text-xs"
                        border="border-goalinputborder"
                        color="text-black2"
                        paddingLi="py-2"
                      />
                    </div>
                    {(isSubmit == true &&
                      (department == "" || department == "0")) == true ? (
                      <span className="text-redPink font-normal text-xs my-1">
                        {"Department is required."}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label
                      className="text-black2 font-normal body-15"
                      htmlFor=""
                    >
                      Gender <span className="text-redPink">*</span>
                    </label>
                    <div className="mt-1">
                      <StringDropdownList
                        state={gender}
                        setState={setGender}
                        placeholder="Gender"
                        className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                        dropdownItems={genderList?.map((location: any) => ({
                          value: location.id,
                          label: location.name,
                        }))}
                        textAlign={"text-left"}
                        fontSize="md:text-sm text-xs"
                        border="border-goalinputborder"
                        color="text-black2"
                        paddingLi="py-2"
                      />
                    </div>
                    {(isSubmit == true && (gender == "" || gender == "0")) ==
                    true ? (
                      <span className="text-redPink font-normal text-xs my-1">
                        {"Gender is required."}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {!id ? (
                    <div className="mb-0 last:mb-0 w-full">
                      <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                        Password<span className=" text-red-500">*</span>
                      </label>
                      <div className="relative">
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                        <img
                          src={eyeshow}
                          alt="eye-open"
                          className={classNames(
                            styles.theeye,
                            "absolute top-1/2 right-4 cursor-pointer w-[22px] h-[16px] object-cover"
                          )}
                          onClick={switchEyeHandler}
                        />
                      </div>
                     
                      <p
                        className={classNames(
                          isSubmit && name === "" ? "" : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Password required
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                      Mobile<span className=" text-red-500">*</span>
                    </label>
                    <input
                      onKeyDown={(evt) =>
                        ["e", "E", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={mobile}
                      onChange={handleChange}
                      type="tel"
                      placeholder="Mobile"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        isSubmit && mobile === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Mobile required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                      Email<span className=" text-red-500">*</span>
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      placeholder="Email"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        isSubmit && email === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Email required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                      Joined Date<span className=" text-red-500">*</span>
                    </label>
                    <div className="w-full">
                      <CustomDatePicker
                        format="yyyy-mm-dd"
                        date={joinedDate}
                        //minDate={warantyFromDate ? warantyFromDate : new Date()}
                        setDate={setJoinedDate}
                        isFilterSection={false}
                        placeholder="yyyy-mm-dd"
                        iconColor="#858795"
                        borderColor="border-goalinputborder"
                        fontSize="md:text-sm text-xs"
                      />
                    </div>
                    <p
                      className={classNames(
                        isSubmit &&
                          (joinedDate === null || joinedDate === undefined)
                          ? ""
                          : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Joined Date required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                      Location<span className=" text-red-500">*</span>
                    </label>
                    <input
                      value={locationData}
                      onChange={(e) => setLocation(e.target.value)}
                      type="text"
                      placeholder="Location"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        isSubmit && locationData === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Location is required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                      Medical Plan<span className=" text-red-500">*</span>
                    </label>
                    <input
                      value={medical}
                      onChange={(e) => setMedical(e.target.value)}
                      type="text"
                      placeholder="Medical Plan"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        isSubmit && medical === "" ? "" : "hidden",
                        "mb-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      Medical plan is required
                    </p>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label
                      className="text-black2 font-normal body-15"
                      htmlFor=""
                    >
                      Status <span className="text-redPink">*</span>
                    </label>
                    <ToggleSwitch
                      id={editedData?.id ? editedData.id : 0}
                      state={status}
                      setState={setStatus}
                      updateData={setStatus}
                    />
                  </div>
                  {/* <div className=" border border-gray-300 text-gray-400">
                      <p className=" text-15 px-4 pt-3">Upload Profile Image</p>
                      <div className="flex items-center p-4">
                        <input
                          type="file"
                          id="upload"
                          name="file"
                          onChange={UploadFile}
                          ref={uploadRef}
                          className="hidden"
                          accept=".jpg,.png,.svg,.jpeg,.webp"
                        />
                        <button
                          onClick={handleClick}
                          className={classNames(
                            uploading
                              ? "pointer-events-none"
                              : "",'text-15'
                          )}
                        >
                          Choose file
                        </button>
                        <p className="font-primary text-15 text-black2 ml-3">
                          {allFile?.name ? allFile.name :selectedFileName}
                        </p>
                      </div>
                    </div> */}
                </div>
              </div>

              <div className="px-2 pb-5 pt-2">
                <p
                  className={classNames(
                    isSubmit || errorMsg ? "" : "hidden",
                    "mb-5 font-poppins text-12 text-redPink"
                  )}
                >
                  {errorMsg}
                </p>
                <div className="flex flex-wrap">
                  <div className="flex px-2 justify-center items-center">
                    <Button
                      type="button"
                      label={
                        <span className="flex items-center text-12 leading-[18px]">
                          <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                          {editedData ? "Update" : "Save"}
                        </span>
                      }
                      onClick={() => {
                        handleSubmit();
                      }}
                      variant="no-outline"
                      size="lg"
                      customClass={classNames(
                        uploading ? " pointer-events-none opacity-50" : "",
                        "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                      )}
                    />

                    <Button
                      type="button"
                      label={
                        <span className="flex items-center text-12 leading-[18px] ">
                          <CloseIcon
                            width={14}
                            height={14}
                            className="mr-[10px] [&_path]:fill-graydark"
                          />
                          Cancel
                        </span>
                      }
                      onClick={() => {
                        setIsOpen(false);
                        setIsSubmit(false);
                        setEditData("");
                        setErrorMsg("");
                        clearData();
                        setUploading(false);
                        setIsSubmit(false);
                      }}
                      variant="no-outline"
                      size="lg"
                      customClass=" mr-2 py-2 lg:py-2 px-3 bg-offwhite text-black2 border-none custom-button-size"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EmployeePopup;
