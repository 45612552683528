import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type UsefulLineData = {
  id: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
  link_en: string;
  link_tc: string;
  link_sc: string;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
export type PaginationData = {
  page: number;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export type TaskCategoriesList = {
  categoryList: TaskDepartmentListData[];
};
export type CMSData = {
  cms: TData;
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const cmsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCMSList: build.query<CMSData, PaginationData>({
      query: ({ limit, page, search }) => ({
        url: `cmspages?limit=${limit}&page=${page}&search=${search}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createCMS: build.mutation<CMSData, UsefulLineData>({
      query(body) {
        return {
          headers: authHeader(),
          url: `cmspages`,
          body,
          method: "POST",
        };
      },
    }),
    updateCMS: build.mutation<TaskCategoriesList, UsefulLineData>({
      query(body) {
        return {
          headers: authHeader(),
          url: `cmspages/${body.id}`,
          body,
          method: "PUT",
        };
      },
    }),
    deleteCMS: build.mutation<TaskCategoriesList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/cmspages/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetCMSListQuery,
  useCreateCMSMutation,
  useUpdateCMSMutation,
  useDeleteCMSMutation
} = cmsApi;

export const {
  endpoints: { getCMSList, createCMS,
     updateCMS, deleteCMS },
} = cmsApi;
