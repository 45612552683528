import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
  id: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
};
type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  data: TData;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
type departmentInput = {
  // sort: string;
  // search: string;
};
export type EventTypeList = {
  eventTypeList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const eventTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEventTypesData: build.query<DepartmentsResponse, departmentInput>({
      query: () => ({
        url: `eventtypes`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getEventTypeslList: build.query<EventTypeList, void>({
      query: () => ({
        url: `geteventtypeList`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createEventType: build.mutation<
    EventTypeList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `eventtypes`,
          body,
          method: "POST",
        };
      },
    }),
    updateEventType: build.mutation<
    EventTypeList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `eventtypes/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteEventType: build.mutation<EventTypeList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/eventtypes/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetEventTypesDataQuery,
  useGetEventTypeslListQuery,
  useCreateEventTypeMutation,
  useUpdateEventTypeMutation,
  useDeleteEventTypeMutation
} = eventTypeApi;

export const {
  endpoints: {
    getEventTypesData,
    getEventTypeslList,
    createEventType,
    updateEventType,
    deleteEventType,
  },
} = eventTypeApi;
