import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../common/Button";
import { CloseIcon, SendIcon } from "../common/Icons";
import {
  Fade,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import classNames from "classnames";
import "react-phone-input-2/lib/style.css";
export type TNameOnlyPopup = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  editedData?: any;
  setEditData?: any;
  savePopupData: any;
  updatePopupData: any;
  title: string;
  isSubmit: boolean;
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  uploading: boolean;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const image_type = [
  "image/svg+xml",
  "image/png",
  "image/jpeg",
  "image/webp",
  "image/gif",
  "image/tiff",
];
const CreatePopupLogo: FC<TNameOnlyPopup> = ({
  isOpen,
  setIsOpen,
  editedData,
  setEditData,
  savePopupData,
  updatePopupData,
  title,
  isSubmit,
  setIsSubmit,
  setUploading,
  uploading,
}) => {
  const [logoError, setLogoError] = useState<string>("");
  const [bannerError, setBannerError] = useState<string>("");
  const [logoFile, setLogoFile] = useState<any>({});
  const [bannerFile, setBannerFile] = useState<any>({});
  const [selectedLogo, setSelectedLogo] = useState("No file chosen");
  const [selectedBanner, setSelectedBanner] = useState("No file chosen");
  const logoRef: any = useRef(null);
  const bannerRef: any = useRef(null);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const clearData = () => {
    setUploading(false);
    setSelectedLogo("");
    setSelectedBanner("");
    setLogoFile(null);
    setBannerFile(null);
  };
  const UploadFileLogo = useCallback(() => {
    const file = logoRef?.current?.files[0];
    setLogoFile(file);
    setSelectedLogo(file.name);
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!file) {
      setLogoError("Please choose a file");
      return;
    }
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > MAX_FILE_SIZE) {
        setLogoError("File size exceeds the maximum allowed (100MB).");
        return;
      } else {
        setLogoError("");
      }
    }
  }, [setSelectedLogo, setLogoFile, setLogoError]);
  const UploadFileBanner = useCallback(() => {
    const file = bannerRef?.current.files[0];
    setBannerFile(file);
    setSelectedBanner(file.name);
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!file) {
      setBannerError("Please choose a file");
      return;
    }
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > MAX_FILE_SIZE) {
        setBannerError("File size exceeds the maximum allowed (100MB).");
        return;
      } else {
        setBannerError("");
      }
    }
  }, [setSelectedBanner, setBannerFile, setBannerError]);
  const handleLogoClick = () => {
    logoRef.current.click();
  };
  const handleBannerClick = () => {
    bannerRef.current.click();
  };
  const checkValidation = (saveData: any) => {
    if (saveData.logo === "") {
      setLogoError("Logo Image is Required");
      return true;
    }
    if (saveData.banner === "") {
      setBannerError("Banner Image is Required");
      return true;
    }
    if (image_type.includes(logoFile?.type)) {
      setLogoError("");
    } else {
      setLogoError("The uploaded file should be image type");
      return true;
    }
    if (image_type.includes(bannerFile?.type)) {
      setBannerError("");
    } else {
      setBannerError("The uploaded file should be image type");
      return true;
    }
  };
  const handleSubmit = async () => {
    setIsSubmit(true);
    setUploading(true);
    const saveData = {
      logo: logoFile,
      banner: bannerFile,
    };

    let formSaveData = new FormData();
    formSaveData.append("logo", saveData.logo);
    formSaveData.append("banner", saveData.banner);    
    console.log("logoFile ",logoFile,bannerFile)
    if(logoFile.type && bannerFile.type){
      const check = checkValidation(saveData);
      if (!check) {
        if (editedData) {
          updatePopupData(formSaveData);
        } else {
          savePopupData(formSaveData);
        }
      } else {
        setUploading(false);
      }
    }else{
      setUploading(false);
      setIsOpen(false);
    }
    
  };
  useEffect(() => {
    if (editedData) {
      let bannerImg = editedData?.banner;
      let logoImg = editedData?.logo;
      if (bannerImg) {
        const bannerImgSplit = bannerImg.split("/");
        bannerImg = bannerImgSplit[bannerImgSplit.length - 1];
      }
      if (logoImg) {
        const logoImgSplit = logoImg.split("/");
        logoImg = logoImgSplit[logoImgSplit.length - 1];
      }
      setSelectedBanner(bannerImg);
      setSelectedLogo(logoImg);
    }
  }, [editedData]);

  return (
    <Modal
      className="project_popup"
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
          setIsSubmit(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={isOpen} timeout={500}>
        <div className="lg:max-w-[800px] max-w-full w-full m-auto">
          <div className="modalContent w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999]">
            <div className="rounded-xl bg-white font-primary w-full">
              <div
                id="modal-modal-title"
                className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
              >
                <h3 className="text-18 leading-[27px] font-semibold text-white">
                  {title}
                </h3>
                <button
                  key={Math.random() * 100}
                  type="button"
                  aria-label="Close Modal"
                  onClick={() => {
                    setIsOpen(false);
                    setIsSubmit(false);
                    setUploading(false);
                    setErrorMsg("");
                    clearData();
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
              <div
                id="modal-modal-description"
                className="pb-9 px-5 bg-white rounded-b-xl  overflow-y-auto max-h-[70vh] h-full custom_scrollbar_popup"
              >
                <div className="mb-5 last:mb-0 pt-5">
                  <p className="text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">
                    Logo
                  </p>
                  <div className=" border border-gray-300 text-gray-400">
                    <p className=" text-sm px-4 pt-3">Upload file (.pdf)</p>
                    <div className="flex items-center p-4">
                      <input
                        type="file"
                        id="upload"
                        name="file"
                        onChange={UploadFileLogo}
                        ref={logoRef}
                        className="hidden"
                        accept=".png,.jpg,.svg,.wepb"
                      />
                      <button
                        onClick={handleLogoClick}
                        className={classNames(
                          uploading ? "pointer-events-none" : ""
                        )}
                      >
                        Choose file
                      </button>
                      <p className="font-primary text-10 sm:text-12 text-black2 ml-3">
                        {logoFile?.name ? logoFile.name : selectedLogo}
                      </p>
                    </div>
                  </div>
                  <p
                    className={classNames(
                      logoError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {logoError ? logoError : ""}
                  </p>
                </div>
                <div className="mb-5 last:mb-0">
                  <p className="text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">
                    Banner
                  </p>
                  <div className=" border border-gray-300 text-gray-400">
                    <p className=" text-sm px-4 pt-3">Upload file (.pdf)</p>
                    <div className="flex items-center p-4">
                      <input
                        type="file"
                        id="upload"
                        name="file"
                        onChange={UploadFileBanner}
                        ref={bannerRef}
                        className="hidden"
                        accept=".png,.jpg,.svg,.wepb"
                      />
                      <button
                        onClick={handleBannerClick}
                        className={classNames(
                          uploading ? "pointer-events-none" : ""
                        )}
                      >
                        Choose file
                      </button>
                      <p className="font-primary text-10 sm:text-12 text-black2 ml-3">
                        {bannerFile?.name ? bannerFile.name : selectedBanner}
                      </p>
                    </div>
                  </div>
                  <p
                    className={classNames(
                      bannerError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {bannerError ? bannerError : ""}
                  </p>
                </div>

                <div className="px-2 pb-5 pt-5 flex justify-between items-center">
                  <div className="flex flex-wrap">
                    <div className="flex px-2 justify-center items-center">
                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px]">
                            <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                            {editedData ? "Update" : "Save"}
                          </span>
                        }
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass={classNames(
                          uploading ? " pointer-events-none opacity-50" : "",
                          "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                        )}
                      />

                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px] ">
                            <CloseIcon
                              width={14}
                              height={14}
                              className="mr-[10px] [&_path]:fill-graydark"
                            />
                            Cancel
                          </span>
                        }
                        onClick={() => {
                          setIsOpen(false);
                          setIsSubmit(false);
                          setErrorMsg("");
                          clearData();
                          setUploading(false);
                          setIsSubmit(false);
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass=" mr-2 py-2 lg:py-2 px-3 bg-offwhite text-black2 border-none custom-button-size"
                      />
                    </div>
                  </div>
                  <p
                    className={classNames(
                      isSubmit || errorMsg ? "" : "hidden",
                      "mb-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {errorMsg}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CreatePopupLogo;
