import { useEffect, useState } from 'react';
import './App.scss';
import MainRouter from './MainRouter';
//import { requestPermission } from './app/services/firebase';
import { getAuthUser } from './app/services/dashboard';
import { useNavigate } from 'react-router-dom';
import GenericForm from './components/common/FormFields/FormFieldProvider';
import TestingInput from './components/common/FormFields/examples/TestingInput';
import { ZodType, z } from 'zod';

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    var getUser = getAuthUser();
    if (getUser?.token) {
      //requestPermission();
    }

    // const unsubscribe = onMessageListener().then((payload:any) => {
    //   setNotification({
    //     title: payload?.notification?.title,
    //     body: payload?.notification?.body,
    //   });
    //   // toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
    //   //   duration: 60000,
    //   //   position: 'top-right',
    //   // });
    // });
    // return () => {
    //   unsubscribe.catch((err) => console.log('failed: ', err));
    // };
    // console.clear();
  }, []);

  return (
    <div className="App">
      <MainRouter />
    </div>
  );
}

export default App;
