import React, { useMemo } from "react";
import { NavigateFunction, useLocation } from "react-router-dom";
import EmailSentComponent from "../../emailsent/components/EmailSentComponent";
import ForgetPasswordRoute from "../../forgetpassword";
import LoginRoute from "../../login";
import ResetPasswordComponent from "../../reset-password/components/ResetPasswordComponent";
import AccessDenied from "../../access-denied/views/AccessDenied";
import DashboardRoute from "../../dashboard";
import UsersRoute from "../../userProfile";
import { useJwt } from "react-jwt";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getAuthUser } from "../../app/services/dashboard";

type SectionRouteProps = {
  permissions?: any[];
  children: React.ReactNode;
  loginData?: any;
  isValid?: boolean;
};
export const checkAllPersmissions = (navigate: NavigateFunction, userData: any) => {  
  // const navigate = useNavigate();
  var authPermisson = userData?.permissions[0]?.name;
  var route = "";
  if (authPermisson == "View_Admin_Dashboard") {
    route = '/';
   // return navigate('/')
  }
  else if (authPermisson == "View_My_Profile") {
    route = '/profile';
    // return navigate('/profile')
  }
  else if (authPermisson == "View_Project") {
    route = '/projects';
    // return navigate('/projects')
  }
  else if (authPermisson == "View_Employee") {
    route = '/employeeList';
    // return navigate('/employeeList')
  }
  else if (authPermisson == "View_Leave_Application") {
    route = '/employeeList/leave';
    // return navigate('/employeeList/leave')
  }
  else if (authPermisson == "View_Active_Time_Report") {
    route = '/employeeList/active-time-report';
    // return navigate('/employeeList/active-time-report')
  }
  else if (authPermisson == "View_Password") {
    route = '/password';
    // return navigate('/password')
  }
  else if (authPermisson == "View_Document") {
    route = '/documents';
    // return navigate('/documents')
  }
  else if (authPermisson == "View_Announcement") {
    route = '/announcement';
    //return navigate('/announcement')
  }
  else if (authPermisson == "View_Media_Library") {
    route = '/media';
    // return navigate('/media')
  }
  else if (authPermisson == "View_User") {
    route = '/users';
    // return navigate('/media')
  }
  return navigate('/dashboard');
}

// @ts-ignore
export const SectionRoute: React.FC<SectionRouteProps> = ({
  permissions,
  loginData,
  children,
  isValid,
}) => {
  // var hasPermissions = true;
  const location = useLocation();
  const navigate = useNavigate();
  const getAuthPermissions: string[] =useMemo(() => [],[]);

  if (loginData?.permissions) {
    const getPer = loginData?.permissions?.forEach((per: any) => {
      getAuthPermissions.push(per?.name)
    })
  }
  
  const allPermissions: string[] = permissions ? permissions : [];

  const getMatch = (a: any, b: any) => {
    // var matches = [];
    for (var i = 0; i < a.length; i++) {
      for (var e = 0; e < b.length; e++) {
        if (a[i] === b[e])
          // matches.push( a[i] );
          return true
      }
    }
    // return matches;
    return false
  }

  const checkPermis = getMatch(allPermissions, getAuthPermissions);

  const [viewMyProfiles, setViewMyProfile] = useState<boolean>(false);
  const [viewAdminDashboard, setViewAdminDashboard] = useState<boolean>(false);

  var getUser = getAuthUser();
  const getAuthPermis: string[] =useMemo(() => [],[]);

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name)
    })
  }

  // useEffect(() => {
  //   const viewMyProfile = getAuthPermis.filter((f) => f === 'View_My_Profile');
  //   const viewAdminDashboard = getAuthPermis.filter((f) => f === 'View_Admin_Dashboard');
  //   if (viewMyProfile.length > 0) {
  //     setViewMyProfile(true)
  //   }else{
  //     setViewMyProfile(false)
  //   }
  //   if (viewAdminDashboard.length > 0) {
  //     setViewAdminDashboard(true)
  //   }else{
  //     setViewAdminDashboard(false)
  //   }
  // }, [getUser, getAuthPermis])
  const token = loginData.accessToken;
  const { decodedToken, isExpired } = useJwt(token);

  if (isValid) {
    if (isExpired) {
      return <LoginRoute />;
    }
    if (location.pathname == "/") {
      navigate('/users');
    } else if (checkPermis && !isExpired) {
      return children;
    } else if (!checkPermis) {
      if (location.pathname === "/forgetpassword") {
        return <ForgetPasswordRoute />;
      } else if (location.pathname === "/emailsent") {
        return <EmailSentComponent />;
      } else if (location.pathname === "/resetpassword") {
        return <ResetPasswordComponent />;
      }
      return <AccessDenied />;
    }

  } else {
    if (location.pathname === "/forgetpassword") {
      return <ForgetPasswordRoute />;
    } else if (location.pathname === "/emailsent") {
      return <EmailSentComponent />;
    } else if (location.pathname === "/resetpassword") {
      return <ResetPasswordComponent />;
    } else {
      return <LoginRoute />;
    }
  }

};

SectionRoute.displayName = "Route";
export default SectionRoute;
