import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import ActivityLog from './views/ActivityLog';

const ActivityLogRoute = (props:any): ReactElement => {
  const data=props.loginData;
  const permissions = props.permissions;

 return (
    <BasicLayout userData={data} permissions={permissions}>
      <ActivityLog/>
      <Outlet />
    </BasicLayout>
  )
};

export default ActivityLogRoute;
