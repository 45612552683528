// import { Modal } from "@material-ui/core";
import { Modal } from "@mui/material"

import classNames from "classnames";
import React, {
  createRef,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { CloseIcon } from "../../components/common/Icons";
import { twMerge } from "tailwind-merge";
import { dummyData, IEquipment, IEquipmentLog, IViewEquipment } from "./Equipments";
import EquipmentLogTable from "../../components/EquipmentTable/EquipmentLogTable";
import EquipmentReturnNotePopup from "./EquipmentReturnNotePopup";
interface ICreateData {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  editedData: IViewEquipment | undefined;
  equipmentLogs: IEquipmentLog[];
}
const EquipmentViewPopup: FC<ICreateData> = ({ isOpen, setIsOpen, editedData, equipmentLogs }) => {
  const [tab, setTab] = useState<number>(1);
  const [logsData, setLogsData] = useState<IEquipmentLog>();
  const [isLogOpen, setLogIsOpen] = useState<boolean>(false);
  const changeEquipmentTab = (id: number) => {
    setTab(id);
  }
  useEffect(() => {

  }, [editedData])
  return (
    <div>
      <Modal
        open={isOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modalContent rounded-xl lg:w-[1000px] max-w-[90%] annoucement-modal overflow-y-auto max-h-[90vh] w-full focus-visible:outline-none">
          <div
            id="modal-modal-title"
            className="bg-vorpblue relative flex justify-between py-6 px-5 rounded-t-xl"
          >
            <h3 className="text-18 font-primary font-semibold text-white">
              View Equipment
            </h3>
            <button
              className="flex items-center justify-center"
              onClick={() => {
                setIsOpen(false);
                setTab(1);
              }}
            >
              <CloseIcon />
            </button>
          </div>

          <div
            id="modal-modal-description"
            className="py-9 pl-5 pr-2 bg-white rounded-b-xl"
          >
            <div className="w-full">
              <div className="flex">
                <button onClick={() => changeEquipmentTab(1)} className={twMerge(tab == 1 ? 'text-vorpblue' : 'text-vorpgraylight', "font-semibold md:text-sm text-xs pr-3 border-r border-r-filtergray")}>Equipment Information</button>
                <button onClick={() => changeEquipmentTab(2)} className={twMerge(tab == 2 ? 'text-vorpblue' : 'text-vorpgraylight', "font-semibold md:text-sm text-xs px-3")}>Equipment Log</button>
              </div>
              <div className="overflow-y-auto max-h-[50vh] custom_scrollbar_popup">
                <div className={twMerge(tab == 1 ? 'grid' : 'hidden', "grid-cols-1 md:grid-cols-2 gap-4 pr-3")}>
                  <div>
                    <p className="font-semibold lg:text-lg text-sm mb-5 text-black2 mt-5">{editedData?.name}</p>
                    {editedData?.image?<img src={editedData?.image} alt="equipment" className="mb-5 rounded-4 border border-vorpgraylight" />:null}
                    <div>
                      <span className="body-15 font-normal text-black2 mb-3">Tags</span>
                      <div className="flex flex-wrap mt-3">
                        {editedData?.tags?.map((tag) => {
                          return <div className="mr-1 bg-white px-[10px] rounded-3xl text-black2 text-sm font-normal text-center border border-votagBorder">{tag.name}</div>
                        })}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Equipment ID</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.code}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Serial Number</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.serialno}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Category</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.category?.name}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Location</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.location?.name}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Condition</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.condition}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Version</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.version}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Service Provider</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.service_provider}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Pricing Detail</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.pricing_detail}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Warranty</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.warranty?.fromDate ? (editedData?.warranty?.fromDate + ' to ' + editedData?.warranty?.toDate) : ''}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">License Type</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.license}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">License Expiry Date</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.license_date?.fromDate ? (editedData?.license_date?.fromDate + ' to ' + editedData?.license_date?.toDate) : ''}</p>
                    </div>
                    <div className="flex mb-5">
                      <p className="text-black2 font-medium body-15 text-left md:min-w-[160px] min-w-[130px] mr-3">Remark</p>
                      <p className="text-graydark font-normal lg:text-sm text-xs text-left">{editedData?.remark}</p>
                    </div>
                  </div>
                </div>
                <div className={twMerge(tab == 2 ? 'block' : 'hidden', "w-full")}>
                  <div className="w-full mt-5">
                    {equipmentLogs ? <EquipmentLogTable setLogIsOpen={setLogIsOpen} isLogOpen={isLogOpen} data={equipmentLogs} logsData={logsData} setLogsData={setLogsData} setIsOpen={setIsOpen}/> : ''}

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </Modal>
      <EquipmentReturnNotePopup setIsOpen={setLogIsOpen} isOpen={isLogOpen} setParentIsOpen={setIsOpen}
        editedData={logsData}
      />
    </div>
  );
};
export default EquipmentViewPopup;
