import {
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./Permissions.module.scss";
//import search from "./img/search-icon.svg";
import {
  getPermissions,
  useChangePermissionMutation,
  useGetPermissionListDataQuery,
  useGetPermissionsListMutation,
  useGetPermissionsQuery,
} from "../../app/services/permissions";
//import TableSearchbox from "../../components/common/TableSearchbox";
import {
  NumberDropdownList,
  //StringDropdownList,
} from "../../components/common/DropdownList";
import PermissionTable from "../../components/BackendUsers/PermissionTable";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { baseObj } from "../../utils/constants";
import {
  AddIcon,
  CustomizeLayoutIcon,
  SearchIcon,
} from "../../components/common/Icons";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { connect } from "react-redux";
// import { makeStyles, Tooltip } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import SuccessPopup from "../../components/SuccessPopup/SuccessPopup";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
//import CustomCommonError from "../../components/common/CustomCommonError";
import { useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import { getAuthUser } from "../../app/services/dashboard";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const Permissions = (props: any) => {
  const navigate = useNavigate();
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [permissionFilter, setPermissionFilter] = useState("");
  const [stateChange, setStateChange] = useState(false);
  // const [permissionFilterList, setPermissionListFilter] = useState<
  //   Permission[] | []
  // >([]);
  // const [statusFilter, setStatusFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);

  const [limit, setLimit] = useState<number>(20);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [statusId, setStatusID] = useState<number>(0);
  const [roleID, setRoleD] = useState<string>("");
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [status, setStatus] = useState<boolean>(false);
  const [tempList, setTempList] = useState<any>(templateList);
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  // const defaultFields = [
  //   ''
  // ];
  // const [visibleColumns, setVisibleColumns] = useState<{
  //   [key: string]: boolean;
  // }>({});
  const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("---");
  const [sorting, setSorting] = useState<SortingState>([]);
  const {
    data: permissionsData,
    isFetching,
    isLoading: getLoading,
    error,
  } = useGetPermissionsQuery({
    limit: limit,
    currentPage: currentPage,
    search: globalSearch,
    permission: permissionFilter,
    sort: sorting.map((s) => `${s.desc ? '-' : ''}${s.id=="permission"?"name":s.id}`).join(','),
  });
  const { data: perList } = useGetPermissionListDataQuery();
  const [getPermissions, { isLoading }] = useGetPermissionsListMutation();
  // const { data: templateList } = useGetPermissionTemplateListQuery();
  const [permissionsTempList, setPermissionsTempList] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [columnsData, setColumnsData] = useState<any>([]);
  const [data, setData] = useState<baseObj[]>([]);
  const [dataOld, setDataOld] = useState<baseObj[]>([]);

  let [localModuleId, setLocalModuleId] = useState<number>(0);
  // const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [moduleList, setModuleList] = useState<any>([]);

  const [checkManage, setManage] = useState<boolean>(false);
  const [checkUpdate, setUpdate] = useState<boolean>(false);
  var getUser: any = getAuthUser();
  const getAuthPermis: string[] = [];

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    const filterManage = getAuthPermis.filter((f) => f == "Manage_Permission");
    const editRole = getAuthPermis.filter((f) => f === "Manage_Permission");
    if (filterManage.length > 0) {
      setManage(true);
      setUpdate(true);
    }
  }, [getUser]);

  useEffect(() => {
    if (permissionsData) {
      var permissions = permissionsData.permissions?.data;
      setItemCount(permissionsData.permissions?.total);
      setPagination({
        pageIndex: permissionsData.permissions?.current_page,
        pageSize: permissionsData.permissions?.per_page,
      });
      var permissionsNewData = permissions.map((x: any) => {
        return { ...x, permission: x.name };
      });
      setData(permissionsNewData);
      // setPermissionsTempList(permissionsNewData);
      setLocalModuleId(permissionsData?.module_id);

      // const noneData = new Array(permissionsNewData[0]?.data.length)
      //   .fill(1)
      //   .map((_, index: number) => {
      //     return columnHelper.display({
      //       header: "",
      //       id: "none" + index,
      //     });
      //   });

      const tableHeaders = permissionsNewData[0]?.data.map((x: any) => {
        if (x?.name == "Super Admin") {
          return columnHelper.accessor(x.name.replace(/\s/g, ""), {
            header: x.name,
            id: x.name.replace(/\s/g, ""),
            enableSorting:false,
            cell: (props) => {
              const status = props.getValue();
              const id = props.row.original.id;
              const roleId = props.column.id;
              const isSuperAdmin = true;
              return renderCustomSwitch(id, roleId, props, isSuperAdmin);
            },
          });
        }else {
          return columnHelper.accessor(x.name.replace(/\s/g, ""), {
            header: x.name,
            enableSorting:false,
            id: x.name.replace(/\s/g, ""),
            cell: (props) => {
              const status = props.getValue();
              const id = props.row.original.id;
              const roleId = props.column.id;
              return renderCustomSwitch(id, roleId, props);
            },
          });
        }
      });


      var header = columnHelper.accessor("permission", {
        header: "Permission",
        id: "permission",
        cell: (props) => {
          const name = props.getValue();
          const description=props.cell.row.original.description;          
          return (
            <div className="flex items-center">
              <>
              <p className="tracking-normal font-poppins lg:text-sm text-12 font-normal text-[#464E5F] text-left w-[97%] inline-block overflow-hidden">
                    {name}
                  </p>
              </>
            </div>
          );
        },
      });
      if (permissions.length > 0) {
        setColumnsData([header, ...tableHeaders]);
        // setColumnsData([header, ...noneData.concat(tableHeaders)]);
      } else {
        setColumnsData([]);
      }
    }
  }, [permissionsData, status, currentPage, perList, permissionFilter]);

  // useEffect(() => {
  //   if (perList) {
  //     var all = {
  //       id: 0,
  //       name: "All",
  //       permission: "All",
  //       data: [],
  //     };
  //     setPermissionsTempList([all, ...perList?.permissionLists]);
  //   }
  // }, [perList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, permissionFilter, limit]);

  useEffect(() => {
    // if (permissionFilter) {
    //   if (permissionsData) {
    //     var permissions = permissionsData.permissions?.data;
    //     var permissionsNewData = permissions.map((x: any) => {
    //       return { ...x, permission: x.name };
    //     });
    //     setData(
    //       permissionFilter != "All" ? permissionsNewData.filter((x: any) => x.name == permissionFilter) : permissionsNewData
    //     );
    //   }
    // }
    if (permissionsData) {
      // var permissions = permissionsData.permissions?.data;
      // var permissionsNewData = permissions.map((x: any) => {
      //   return { ...x, permission: x.name };
      // });
      // var permissionsFilter = permissionsNewData ? permissionsNewData : [];
      // var all = {
      //   id: 0,
      //   name: "All",
      //   permission: "All",
      //   data: [],
      // };
      // var allFilter = permissionsFilter.concat(all);
      // allFilter.sort(function (a: any, b: any) {
      //   if (a.id < b.id) {
      //     return -1;
      //   }
      //   if (a.id > b.id) {
      //     return 1;
      //   }
      //   return 0;
      // });
      // setPermissionsTempList(allFilter);
      // if (globalSearch !== "" && permissionFilter) {
      //  // setDataOld([]);
      //   var filterResult = permissionsNewData
      //     ?.filter((x: any) =>
      //       x.permission.toUpperCase().includes(globalSearch.toUpperCase())
      //     )
      //     ?.filter((x: any) =>
      //       permissionFilter !== "All" ? x.permission == permissionFilter : true
      //     );
      //   setData(filterResult);
      // } else if (globalSearch !== "") {
      //   var filterResult = permissionsNewData?.filter((x: any) =>
      //     x.permission.toUpperCase().includes(globalSearch.toUpperCase())
      //   );
      //   setData(filterResult);
      // } else if (permissionFilter) {
      //   if (permissionFilter !== "All") {
      //     var filterResult = permissionsNewData?.filter(
      //       (x: any) => x.permission == permissionFilter
      //     );
      //     setData(filterResult);
      //   }
      //   // else if (limit) {
      //   //   var filterResult = permissions ? permissions.slice(0, limit) : [];
      //   //   setPermissionsTempList(filterResult);
      //   // }
      //   else {
      //     setData(permissionsNewData);
      //   }
      // }
      // }
      //   // else if (limit) {
      //   //   var filterResult = permissions ? permissions.slice(0, limit) : [];
      //   //   setPermissionsTempList(filterResult);
      //   // }
      //   else {
      //     setData(permissions);
      //   }
      //   if (roleID && statusId) {
      //     const filterData = permissions.map((p) => {
      //       if (p.id == statusId) {
      //         const updatedPermisson = p.data.map((perm: any) =>
      //           perm.name == roleID ? { ...perm, access: !perm.access } : perm
      //         );
      //         return { ...p, data: updatedPermisson };
      //       } else {
      //         return p;
      //       }
      //     });
      //     setData(filterData);
      //     setStateChange(true);
      //   }
    }
  }, [
    stateChange,
    roleID,
    statusId,
    globalSearch,
    permissionFilter,
    permissionsData,
    pagination,
  ]);

  useEffect(() => {
    if (dataOld.length > 0) {
      // var ddd = dataOld.map((d: any) => {
      //   var dd = data.find(x => x.id == d.id);
      //   return dd;
      // });
      setData(
        dataOld.slice(
          currentPage == 1 ? 0 : (currentPage - 1) * limit,
          limit * currentPage
        )
      );
    }
    // setData(dataOld)
  }, [currentPage, globalSearch, permissionFilter]);

  // useEffect(() => {
  //   if (templateData) {
  //     var updatedTemplate = templateData?.templates.map((x: any, i) => {
  //       return { ...x, index: i + 1 };
  //     });
  //     var defaultTemplate = {
  //       id: 0,
  //       index: 0,
  //       module: 0,
  //       name: "All",
  //     };

  //     setModuleList([defaultTemplate, ...updatedTemplate]);
  //   }
  // }, [templateData]);

  // useEffect(() => {
  //   if (templateList) {
  //     setTempList(templateList);
  //   }
  //   var viewData = templateData?.templates.find(
  //     (x: any) => x.id.toString() == currentView
  //   );
  //   // var noneData = data.length - 1;
  //   if (!currentView) {
  //     var permissionsList = permissionsData?.permissions?.data[0]?.data;
  //     var allData = permissionsList?.map((x) => x.name.replace(/\s/g, ""));

  //     if (allData) {
  //       setHiddenFields(["permission", ...allData]);
  //       // setHiddenFields(["permission", ...addNoneData(noneData), ...allData]);
  //     }
  //   } else {
  //     setHiddenFields(
  //       viewData
  //         ? [
  //           "permission",
  //           ...viewData.viewColumns,
  //         ]
  //         : []
  //     );
  //   }
  //   document.querySelectorAll("th,td").forEach((x) => {
  //     if (x.id.includes("none")) {
  //       x.classList.add("hidden");
  //     }
  //     if (x.id.includes("action")) {
  //       x.classList.add("absolute");
  //       x.classList.add("right-0");
  //     }
  //     x.classList.remove("absolute");
  //     x.classList.add("left-0");
  //     x.classList.add("relative");
  //     var div = document.getElementById(x.id);
  //     if (div) {
  //       div.style.setProperty("left", "");
  //     }
  //   });
  //   var view = moduleList?.find((x: any) => x.id == currentView)?.index;
  //   setTemplatePlaceholder("View#" + view);
  // }, [templateList, currentView]);

  // useEffect(() => {
  //   if (viewCols?.view_columns?.length > 0) {
  //     setHiddenFields(["permission", "none0", ...viewCols?.view_columns]);
  //   }
  // }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList && isTemplate) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
      setCurrentView(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [isTemplate, moduleList]);

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList?.filter(
      (x: any) => x.id !== parseInt(id)
    );
    setTempList(filterTempList ? filterTempList : []);
  };
  const hangleTableSearch = (event: any) => {
    setSearchInput(event.target.value);
  };
  const addNoneData = (noneData: number) => {
    var noneStrings = [];
    for (var i = 0; i < noneData; i++) {
      noneStrings.push("none" + i);
    }
    return noneStrings;
  };
  const [changePermission] = useChangePermissionMutation();

  const handleChangePermission = (
    event: any,
    id: number,
    roleId: string,
    parsedData: any,
    mainData: any
  ) => {
    var uniqID = id.toString() + roleId.toString();
    document.getElementById(uniqID)?.classList.remove("hidden");
    document
      .getElementById(uniqID)
      ?.previousElementSibling?.classList.add("hidden");
    setStatusID(id);
    setRoleD(roleId);
    // setStatus(event.target.checked);
    var statusData = {
      id: id,
      roleID: parsedData?.id,
      status: event.target.checked == true ? 1 : 0,
    };
    // var isChecked = event.target.checked;
    // var permissions = permissionsData?.permissions;
    // var permissionsNewData = permissions?.map((x: any) => {
    //   return { ...x, permission: x.name };
    // });

    var old = dataOld;
    var testData = data;
    setData((prev: any) => {
      var testD = prev.map((p: any) => {
        if (p.id == id) {
          var pData = p.data?.map((d: any) => {
            if (d.id == statusData.roleID) {
              return { ...d, access: event.target.checked };
            } else {
              return { ...d, access: d.access };
            }
          });
          return { ...p, data: pData };
        }
        return p;
      });
      testData = testD;
      if (old) {
        testData = [...old, ...testData];
      }
      // console.log("event.target.checked ", testData)
      setDataOld(testData);
      return testD;
    });

    changePermission(statusData).then((res: any) => {
      // console.log("OKKK", res)
      const da: any = JSON.stringify({
        status: true,
        id: 5,
        accessToken:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTcwMWE4YTgzYWQ0ODI0MmU3NjVmNTYwM2UzNmZiNWRhMmIzNjkzYTRlMzQ3MGMyYTIxNTFkMTVmMjNhNzZjZjBkNzI1NzYwNTkxNmM0ZmIiLCJpYXQiOjE2ODkyMzY1MDUuODcyOTU0LCJuYmYiOjE2ODkyMzY1MDUuODcyOTYzLCJleHAiOjE2ODkzMjI5MDUuODYwMDAzLCJzdWIiOiI1Iiwic2NvcGVzIjpbXX0.kLS52bvK0h_c0fj1xM2YoKp7ZtPkyMH5CcJGf1w6IJi_n_pqmo1uwvxnmh1_ToDyH0qE9dx9VOw-BvZsrQjLsD1LZ3yLME3FIlT_iaOmCIjSFT9UjJs_w0pheZpnJxmYFgFcfJCjtI_t1gsrXAARiP24gEJ_MRJat7ul5jHHyIM8RLT7lvA89W1HHfzG3B00aaQM-Ld7nFHZziderbEVjyFn_B9VnaMVE85UoETLD4BhfiMnbdCa0tcWi23sjRpM4d5_tYFFMzFhP4Wps85gBxY18XdZDx0af9LX-CTIAaNW0Ge1drKs-UUrpsYNY4H1g7Ngt8qn1zaXAIO_W6LO7VIMHtm6SdGFdGmv3rUvq3QVWIKJnTtgpunfinVqOJNPYBDhfB1JGGC24Eau1jkEYbgDASH-QcHj1NzlWmz7iVRc1UDih6m-IlkaWZzHEdXWYZqBZusbe3gum0QhU0MF_we6OLjrXQjo6DiP6cOfAXD_k1ARk1rUgfp41N8XEnDXt6O_9B7coh8IuYQdxXxR-wp313KBSGfnCLm3VhxVqNsXB3yGC_Ex7TPe-Sx3EDaMSgq0TkSLj77DTFfYrIWM1osesOkYDfM5gVKLu3y-ApYJ_zsKafUyE0lzY-q-q7-WMCNK9jU3JgI1_5PM07P68yhUwriz_MpsC1w2yMRfcGo",
        user: {
          id: 5,
          name: "Maine",
          email: "maine@visibleone.com",
          photo: "",
          enable: true,
          activated: true,
          editable: false,
          employee_id: 4,
          isAdmin: false,
          role_names: [
            "System Admin",
            "HR Manager",
            "Project Account Manager",
            "Standard User",
          ],
          roles: [
            {
              id: 2,
              name: "System Admin",
              label: "System Setting Access",
            },
            {
              id: 3,
              name: "HR Manager",
              label: "HR Management[Full Access]",
            },
            {
              id: 5,
              name: "Project Account Manager",
              label: "Project Management[Full Access]",
            },
            {
              id: 10,
              name: "Standard User",
              label:
                "Only can access for own profile; Connect with leader for leave approval purpose",
            },
          ],
          createdBy: {
            name: "Maine",
            createdAt: "2023-06-14T19:44:40.000000Z",
          },
          updatedAt: "2023-07-12T04:37:40.000000Z",
        },
        roles: [
          "System Admin",
          "HR Manager",
          "Project Account Manager",
          "Standard User",
        ],
        permissions: [
          {
            id: 1,
            name: "View_Admin_Dashboard",
            label: null,
          },
          {
            id: 2,
            name: "Create_New_Event",
            label: null,
          },
          {
            id: 4,
            name: "View_Project",
            label: null,
          },
          {
            id: 5,
            name: "Import_Project",
            label: null,
          },
          {
            id: 6,
            name: "Export_Project",
            label: null,
          },
          {
            id: 7,
            name: "Create_New_Project",
            label: null,
          },
          {
            id: 8,
            name: "Manage_Project",
            label: null,
          },
          {
            id: 9,
            name: "View_Employee",
            label: null,
          },
          {
            id: 10,
            name: "Import_Employee",
            label: null,
          },
          {
            id: 11,
            name: "Export_Employee",
            label: null,
          },
          {
            id: 12,
            name: "Create_New_Employee",
            label: null,
          },
          {
            id: 13,
            name: "Manage_Employee",
            label: null,
          },
          {
            id: 14,
            name: "View_Leave_Application",
            label: null,
          },
          {
            id: 15,
            name: "Manage_All_Leave_Application",
            label: null,
          },
          {
            id: 17,
            name: "View_Active_Time_Report",
            label: null,
          },
          {
            id: 18,
            name: "View_Time_Tracking",
            label: null,
          },
          {
            id: 19,
            name: "Import_Active_Time_Report",
            label: null,
          },
          {
            id: 20,
            name: "Export_Active_Time_Report",
            label: null,
          },
          {
            id: 21,
            name: "View_Password",
            label: null,
          },
          {
            id: 22,
            name: "Import_Password",
            label: null,
          },
          {
            id: 23,
            name: "Export_Password",
            label: null,
          },
          {
            id: 24,
            name: "Create_New_Password",
            label: null,
          },
          {
            id: 25,
            name: "Manage_Password",
            label: null,
          },
          {
            id: 26,
            name: "View_Document",
            label: null,
          },
          {
            id: 27,
            name: "Manage_Document",
            label: null,
          },
          {
            id: 28,
            name: "View_Announcement",
            label: null,
          },
          {
            id: 29,
            name: "Import_Announcement",
            label: null,
          },
          {
            id: 30,
            name: "Export_Announcement",
            label: null,
          },
          {
            id: 31,
            name: "Create_New_Announcement",
            label: null,
          },
          {
            id: 32,
            name: "Management_Announcement",
            label: null,
          },
          {
            id: 33,
            name: "View_User",
            label: null,
          },
          {
            id: 34,
            name: "Import_User",
            label: null,
          },
          {
            id: 35,
            name: "Export_User",
            label: null,
          },
          {
            id: 36,
            name: "Create_New_User",
            label: null,
          },
          {
            id: 37,
            name: "Manage_User",
            label: null,
          },
          {
            id: 38,
            name: "View_Role",
            label: null,
          },
          {
            id: 39,
            name: "Create_New_Role",
            label: null,
          },
          {
            id: 40,
            name: "Manage_Role",
            label: null,
          },
          {
            id: 41,
            name: "View_Permission",
            label: null,
          },
          {
            id: 42,
            name: "Manage_Permission",
            label: null,
          },
          {
            id: 43,
            name: "View_Media_Library",
            label: null,
          },
          {
            id: 44,
            name: "Manage_Media_Library",
            label: null,
          },
          {
            id: 46,
            name: "Export_Leave",
            label: null,
          },
          {
            id: 47,
            name: "View_Own_Password",
            label: null,
          },
          {
            id: 48,
            name: "View_Own_Document",
            label: null,
          },
        ],
        designation: "Frontend Developer",
        message: "Login successful",
      });
      // const userDatas: any = da;

      // localStorage.setItem("userData", da);

      document.getElementById(uniqID)?.classList.add("hidden");
      document
        .getElementById(uniqID)
        ?.previousElementSibling?.classList.remove("hidden");
    });
  };

  const renderCustomSwitch = (
    id: number,
    roleId: string,
    dataProps: any,
    isSuperAdmin?: any
  ) => {
    var roleID = dataProps?.column?.columnDef?.header;    
    var isDisabled =
      dataProps?.row?.original?.id == id
        ? dataProps?.row?.original?.data?.find((x: any) => x.name == roleID)
        : {};
        console.log("dataProps?.row?.original ",isDisabled)
    return (
      <div
        className={classNames("text-center px-8", styles.customSwitchContainer)}
      >
        <label
          className={classNames(
            isDisabled?.isDisabled == true ? "pointer-events-none" : "",
            "cursor-pointer relative inline-block w-12 h-[30px]"
          )}
        >
          <input
            onChange={(e) =>
              handleChangePermission(e, id, roleId, isDisabled, dataProps)
            }
            type="checkbox"
            checked={isDisabled?.access}
            //disabled={isDisabled?.access == false ? true : false}
            className={classNames("opacity-0 w-full h-full")}
            disabled={isDisabled?.isDisabled || !checkUpdate}
          />
          <span
            className={classNames(
              isDisabled?.access == true ? "bg-[#3699FF]" : "bg-disabledColor",
              isDisabled?.isDisabled == true ? "opacity-50" : "",
              checkUpdate ? "" : "pointer-events-none bg-disabledColor",
              styles.customSwitch,
              " absolute w-12 h-[30px] rounded-full top-0 left-0 bottom-0 right-0"
            )}
          ></span>
        </label>
        <div
          id={id?.toString() + roleId?.toString()}
          className={classNames(styles.loader, "hidden")}
        ></div>
      </div>
    );
  };

  const changePage = (pageNo: number) => {
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  const propsPer = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1100,
  });

  if (error) {
    if (FetchBaseError(error)) {
      if (error?.status == 400) {
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        setErrorPopup(true);
        setMessage(
          error?.data?.message
            ? [error?.data?.message]
            : ["You are something wrong. Pls login Again!"]
        );
      }
    }
  }

  return (
    <>
      {getLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <>
          <SuccessPopup
            descText="Done Successfully!"
            confirmBtnText="Yes, Close."
            show={successPopup}
            setShow={setSuccessPopup}
            message={message}
            setMessage={setMessage}
          />

          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />
          <animated.div style={propsPer}>
            <div className="p-4 bg-white mt-[25px] font-poppins text-13 tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 px-[10px] 5xs:px-4 sm:px-6 6xl:px-9 ">
              <div className="flex flex-wrap justify-between mb-1">
                <div className="sm:mr-4 flex flex-wrap items-center htzxs:w-auto w-full sm:mb-0 mb-1">
                  <div className="md:mr-4 htzxs:mr-2 htzxs:w-auto w-full">
                    <div className="flex items-center mr-2 xs:mr-4 sm:w-auto w-[225px]">
                      <DebounceSearchInput
                        setState={setGlobalSearch}
                        debounce={800}
                      >
                        {(setState) => (
                          <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                            <input
                              type="text"
                              placeholder="Search"
                              className="focus:outline-none py-[3px] px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                              onChange={(e) => setState(e.target.value)}
                            />
                            <SearchIcon className="mr-2" />
                          </div>
                        )}
                      </DebounceSearchInput>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      styles.permissionDropdown,
                      "flex items-center htzxs:mt-0 htzxs:mb-0 mt-2 mb-1"
                    )}
                  >
                    <p className="hidden uppercase font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                      Permissions
                    </p>
                    <CustomDropdownListForFilter
                      placeholder="Permissions"
                      isFilter={true}
                      state={permissionFilter}
                      setState={setPermissionFilter}
                      dropdownItems={
                        permissionsTempList
                          ? permissionsTempList?.map((type: any) => ({
                            value: type.name,
                            label: type.name,
                          }))
                          : []
                      }
                      textAlign="text-left"
                      classNameCus="max-w-[78px] xs:max-w-[250px] 2xl:min-w-[240px] min-w-[200px]"
                      className="max-h-[38px] max-w-[78px] xs:max-w-[250px] 2xl:min-w-[220px] min-w-[250px]"
                    />
                  </div>
                </div>
                <div className="flex items-center md:w-auto w-full justify-end">
                  <div className="flex items-center my-1">
                    <p className="font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-3 uppercase">
                      Display
                    </p>
                    <NumberDropdownList
                      state={pagination.pageSize}
                      setState={(value) => {
                        setPagination((prev) => ({
                          ...prev,
                          pageSize: value as number,
                        }));
                        setLimit(value);
                      }}
                      dropdownItems={new Array(5)
                        .fill(1)
                        .map((_, index: number) => {
                          let display = (index + 1) * 20;
                          return {
                            value: display,
                            label: String(display),
                          };
                        })}
                      placeholder="20"
                      paddingClass="py-[2px]"
                      className="max-h-[34px] max-w-[65px] mr-[10px]"
                    />
                  </div>
                  {/* <div className="">
                    <StringDropdownListWithDeleteBtn
                      haveDeleteBtn={true}
                      setIsTemplate={setIsTemplate}
                      state={isTemplate ? currentViewData : currentView}
                      // state={currentView}
                      setState={setCurrentView}
                      deleteTemplate={deleteTemplateData}
                      dropdownItems={moduleList.map((x: any, index: number) => {
                        return {
                          label: x.id == 0 ? x.name : "View #" + x.index,
                          value: x.id,
                        };
                      })}
                      className="w-full min-w-[90px] 2xl:min-w-[128px] 6xs:text-12"
                      placeholder={templatePlaceholder}
                      textAlign="text-left py-2 pl-2 pr-3"
                      custPadding="py-[2px]"
                      setErrorPopup={setErrorPopup}
                      setSuccessPopup={setSuccessPopup}
                      setMessage={setMessage}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => setIsLayoutControlOpen(true)}
                    className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                  >
                    <CustomizeLayoutIcon />
                    <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                      <AddIcon width={8} height={8} color="white" />
                    </div>
                  </button> */}
                </div>
              </div>
              {/* {isFetching ? (
                  <div>Loading</div>
                ) : ( */}
              {/* <animated.div style={propsPer}> */}
              <PermissionTable
                data={data ? data : []}
                columnsData={columnsData}
                setData={setData}
                pagination={pagination}
                setPagination={setPagination}
                isLayoutControlOpen={isLayoutControlOpen}
                setIsLayoutControlOpen={setIsLayoutControlOpen}
                globalSearch={globalSearch}
                setGlobalSearch={setGlobalSearch}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                setHiddenFields={setHiddenFields}
                setCurrentView={setCurrentView}
                status={status}
                setStatus={setStatus}
                getPermissions={getPermissions}
                localModuleId={localModuleId}
                templateList={tempList}
                hiddenFields={hiddenFields}
                total={itemCount}
                changePage={changePage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setCols={setCols}
                setIsTemplate={setIsTemplate}
                sorting={sorting}
                setSorting={setSorting}
              />
              {/* </animated.div> */}
              {/* )} */}
            </div>
          </animated.div>
        </>
      )}
    </>
  );
};
// export default Permissions;
export default connect(null, { getPermissions })(Permissions);
