import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import TaskList from './view/TaskList';
import Language from '../components/Language/Language';

const LanuageRoute = (props:any): ReactElement => {
  const data=props.loginData;

  const permissions = props.permissions;

 return (
    <BasicLayout userData={data} permissions={permissions}>
      <Language/>
      <Outlet />
    </BasicLayout>
  )
};

export default LanuageRoute;
