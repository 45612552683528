import axios from "axios";
import { EmployeeListData } from "../../components/EmployeeTable/EmployeeTable";
// import { api } from "./api";
import { api, endpointUrl, ssoEndPointUrl } from "./api";
import authHeader from "./auth-header";
import { ILogs, IPasswordLogResponse } from "./password-log-activity";
import { redirectToLogin } from "./annoucement-sheet";
export type DashboardResponse = { data: Dashboard[] };
type Dashboard = {
  eventType: EventType[];
  leaveType: EventType[];
};
export type EventTypeDataList = {
  data: EventTypeData[];
};
export type EventTypeData = {
  uniqeID: number;
  typeName: string;
  indicator: string;
  flag: string;
};
export type EventData = {
  id: number;
  year: number;
  month: number;
  data: EventList[];
};
export type Document = {
  dataLists: DataLists[];
  totalDataUse: TotalDataUse;
  freeSpace: FreeSpace;
};
export type EventListData = {
  data: EventList[];
};
export type EventList = {
  title: string;
  date: string;
  start?: string;
  end?: string;
  note: string;
  eventType: string;
  description?: string;
  eventTarget: string;
  eventNote: boolean;
  allday?: boolean;
};
export type CreateEventData = {
  title: string;
  date: string;
  start?: string;
  end?: string;
  note: string;
  TargetId: number;
  eventType: string;
  // description: string;
  eventTarget: string;
  eventNote: boolean;
  leaveType: string;
  day: string;
  uniqeID?: number;
  keyId: number;
  projectId: number;
};
type EventType = {
  id: string;
  name: string;
};
export type ActivitiesData = {
  avatar: string;
  name: string;
  time: string;
  message: string;
  date: string;
};
type AnnoucementType = {
  id: number;
  name: string;
  clr: string;
};
type AnnoucementData = {
  date: string;
  description: string;
  type: number;
};
type DashboardAnnoucement = {
  announcementData: DashboardAnnoucementData[];
};
type DashboardAnnoucementData = {
  date: string;
  title: string;
  tags: string[];
  type: string;
};
type DataLists = {
  name: string;
  total: any;
  amount: any;
  icon: string;
  iconalt: string;
};
type TotalDataUse = number;
type FreeSpace = number;
export type DocumentCountData = {
  listData: DashboardCountData[];
};
export type DashboardCountList={
  listData:DashboardCountData[]
}
export type DashboardCountData = {
  total: number;
  label: string;
};
type ProjectListsTotal = {
  total: number;
  label: string;
};
type PaymentListsTotal = {
  total: number;
  label: string;
};
type ApplicantListsTotal = {
  total: number;
  label: string;
};
type EmBottomLabel = {
  id: number;
  label: string;
  total: number;
  color: string;
  borderColor: string;
};
export type EmployeeChartData = {
  data: ProjectData;
};
export type DocumentChart = {
  data: DocumentChartData[];
  totalAmount: string;
  totalFiles: string;
};
export type DocumentChartData = {
  name: string;
  volume: number;
  total: number;
};
type ProjectLabelData = {
  data: ProjectLabel[];
};
export type ProjectData = {
  labels: string[];
  datasets: DatasetArr[];
};
export type CustomProjectData = {
  labels: any;
  name: string[];
  datasets: DatasetArr[];
};
export type DatasetArr = {
  id: number;
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  borderRadius: number;
};
export type ProjectDatasetArr = {
  id: number;
  label: string;
  name: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  borderRadius: number;
};
export type ProjectLabel = {
  id: number;
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  borderRadius: number;
};
type projectArray = [];
type PathArrays = [
  {
    label: string;
    value: string;
  }
];
type DateData = {
  month: number;
  year: number;
};
export type DocumentTableData = {
  documents: docData;
  userList: docUserData[];
  module_id: number;
  folderList: MediaList[];
};
type docData = {
  data: doc[];
  total: number;
  current_page: number;
};
export type docUserData = {
  id: number;
  name: string;
  email: string;
  profile_image: string;
};
type doc = {
  id: number;
  filepath: string;
  name: string;
  tag: {};
  owner: string;
  path: string;
  lastModified: string;
  postedBy: string;
  isImportant: boolean;
};
type IChartData = {
  option: string;
  year: number;
  week: number;
  month: number;
};
type IIndicatorData = {
  data: IIndicator[];
};
export type IProjectData = {
  data: IProject[];
};
export type IProject = {
  uniqeID: number;
  projectCode: string;
  projectName: string;
};
type IIndicator = {
  id: number;
  name: string;
  flag: string;
};
export const httpErrorHandler = (error: any) => {
  if (error === null) throw new Error("Unrecoverable error!! Error is null!");
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response;
    const request = error?.request;
    const config = error?.config; //here we have access the config used to make the api call (we can make a retry using this conf)

    if (error.code === "ERR_NETWORK") {
      console.log("connection problems..");
    } else if (error.code === "ERR_CANCELED") {
      console.log("connection canceled..");
    }
    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status;
      if (statusCode === 404) {
        console.log(
          "The requested resource does not exist or has been deleted"
        );
      } else if (statusCode === 401) {
        console.log("Please login to access this resource");
        //redirect user to login
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
    }
  }
  //Something happened in setting up the request and triggered an Error
  console.log(error.message);
};
export const LeaveTypeData = [
  {
    id: 1,
    name: "Full",
  },
  {
    id: 2,
    name: "Half(AM)",
  },
  {
    id: 3,
    name: "Half(PM)",
  },
];
export type LoginState = {
  code: string;
  state: string;
};
export type AddEventType = {
  name: string;
  description: string;
  flag: string;
  indicator: string;
};
export const getAuthUser = () => {
  var userData = localStorage.getItem("userData");
  var userJson: any = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token = userJson.accessToken;
  }
  var data = userJson;
  return data;
};
export type documentProp = {
  start?: string;
  end?: string;
  limit: number;
  search?: string;
  type?: string;
  tag?: string;
  id: number;
  page: number;
  sort: string;
  // page?: number;
};

export interface IPwdlogsResponse {
  activity_logs: ILogs[];
}

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDashboardData: build.query<DashboardCountList, void>({
      query: () => ({
        url: `dashboardCountData`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getEventsData: build.query<Dashboard, void>({
      query: () => ({
        url: `eventsData`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getEventTypes: build.query<EventTypeDataList, void>({
      query: () => ({
        url: `eventTypes`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.data?.map(
              (id: any) => ({ type: "EventTypes", id } as const)
            ),
            { type: "EventTypes" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "EventTypes" as const, id: "LIST" }];
        }
      },
    }),
    getEventsList: build.query<EventListData, DateData>({
      query: ({ month, year }) => ({
        url: `eventsList`,
        params: {
          month: month,
          year: year,
        },
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.data?.map(
              (id: any) => ({ type: "EventList", id } as const)
            ),
            { type: "EventList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "EventList" as const, id: "LIST" }];
        }
      },
    }),
    getEventsListForLeave: build.query<EventListData, DateData>({
      query: ({ month, year }) => ({
        url: `leave-events`,
        params: {
          month: month,
          year: year,
        },
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.data?.map(
              (id: any) => ({ type: "EventList", id } as const)
            ),
            { type: "EventList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "EventList" as const, id: "LIST" }];
        }
      },
    }),
    getProfileEventsList: build.query<EventListData, DateData>({
      query: ({ month, year }) => ({
        url: `profileEventsList`,
        params: {
          month: month,
          year: year,
        },
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.data?.map(
              (id: any) => ({ type: "EventList", id } as const)
            ),
            { type: "EventList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "EventList" as const, id: "LIST" }];
        }
      },
    }),
    getActivitiesData: build.query<ActivitiesData[], void>({
      query: () => ({
        url: `http://localhost:3004/activitiesData`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getAnnoucementData: build.query<DashboardAnnoucement, void>({
      query: () => ({ url: `/annoucementData`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getAnnoucementTypeData: build.query<AnnoucementType, void>({
      query: () => ({
        url: `http://localhost:3004/annoucementType`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getDocumentData: build.query<Document, void>({
      query: () => ({
        url: `http://localhost:3004/documentsData`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getDashboardCountData: build.query<DocumentCountData, void>({
      query: () => ({ url: `/dashboardCountData`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getEmBottomLabelData: build.query<EmBottomLabel, void>({
      query: () => ({ url: `http://localhost:3004/emBottomLabel` }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getProjectLabelData: build.query<ProjectLabelData, IChartData>({
      query: ({ option, month, year, week }) => ({
        url: `projectLabel?option=${option}&year=${year}&month=${month}&week=${week}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getEmployeeLabelData: build.query<EmployeeChartData, IChartData>({
      query: ({ option, month, year, week }) => ({
        url: `/emLabel?option=${option}&year=${year}&month=${month}&week=${week}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getDocumentLabelData: build.query<DocumentChart, void>({
      query: () => ({
        url: `/documentLabel`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getPathArraysData: build.query<PathArrays, void>({
      query: () => ({
        url: `http://localhost:3004/pathArrays`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getDocumentTableData: build.query<DocumentTableData, documentProp>({
      query: ({ start, end, search, limit, type, tag, page, sort }) => ({
        url: `documents?start=${start ? start : ""}&end=${
          end ? end : ""
        }&limit=${limit}&search=${search}&type=${type}&tag=${tag}&page=${page}&sort=${sort}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => [
        ...result?.documents?.data?.map(
          (id: any) => ({ type: "Documents", id } as const)
        ),
        { type: "Documents" as const, id: "LIST" },
      ],
    }),

    // getEmployeeData: build.query<ListResponse<EmployeeDataWithPagination>, PaginationData>({
    //   query: ({ department, nationality, status ,limit, page }) => ({
    //     url: `${endpointUrl}employeeListData?department=${department}&nationality=${nationality}&status=${status}&limit=${limit}&page=${page}`,
    //     headers: authHeader(),
    //   }),
    //   transformResponse: (res: any) => {
    //     console.log("dashboar", res);
    //     return res;
    //   },
    //   providesTags: (result: any = []) => [
    //     ...result?.employeeList?.data.map(
    //       (id: any) => ({ type: "EmployeeListData", id } as const)
    //     ),
    //     { type: "EmployeeListData" as const, id: "LIST" },
    //   ],
    // }),

    // getEmployeeData: build.query<Employee, void>({
    //   query: () => ({
    //     url: `employeeListData`,
    //     headers: authHeader(),
    //   }),
    //   transformResponse: (res: Employee) => {
    //     return res;
    //   },
    //   providesTags: (result:any={}) => [
    //     ...result?.employeeList?.map((id:any) => ({ type: 'EmployeeListData', id } as const)),
    //     { type: 'EmployeeListData' as const, id: 'LIST' },
    //   ],
    // }),

    createEvent: build.mutation<EmployeeListData, Partial<CreateEventData[]>>({
      query: (body) => {
        return {
          url: `events`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (events) => [{ type: "EventList", id: events?.id }],
    }),
    getIndicators: build.query<IIndicatorData, void>({
      query: () => ({
        url: `indicators`,
        headers: authHeader(),
      }),
    }),
    getProjects: build.query<IProjectData, void>({
      query: () => ({
        url: `project-list`,
        headers: authHeader(),
      }),
    }),
    createEventTypeData: build.mutation<EventTypeData, Partial<AddEventType>>({
      query: (body) => {
        return {
          url: `eventTypes`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (events) => [
        { type: "EventTypes", id: events?.uniqeID },
      ],
    }),
    editNoteData: build.mutation<EmployeeListData, Partial<CreateEventData[]>>({
      query: (body) => {
        return {
          url: `editNote`,
          method: "POST",
          body,
          headers: authHeader(),
        };
      },
      invalidatesTags: (events) => [{ type: "EventList", id: events?.id }],
    }),
    viewAnnoucement: build.mutation<DashboardAnnoucement, number>({
      query: (id) => ({
        url: `${endpointUrl}viewAnnoucement/${id}`,
        method: "POST",
        headers: authHeader(),
      }),
      //invalidatesTags: (permissions) => [{ type: 'Permissions', id:"LIST" }],
    }),

    getActivityLogsData: build.query<IPwdlogsResponse, void>({
      query: () => ({
        url: `${endpointUrl}dashboard/activity-logs`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result?.activity_logs?.length > 0) {
          return [
            ...result?.activity_logs?.map(
              (id: any) => ({ type: "PasswordLog", id } as const)
            ),
            { type: "PasswordLog" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "PasswordLog" as const, id: "LIST" }];
        }
      },
    }),
    loginWithSSO: build.mutation<void, void>({
      query: () => ({
        url: `${ssoEndPointUrl}/ssologin.php?option=oauthredirect`,
        method: "GET",
      }),
    }),
    getLoginData: build.mutation<any, LoginState>({
      query: ({ code, state }) => ({
        url: `${ssoEndPointUrl}/ssologin.php?code=${code}&state=${state}`,
        method: "GET",
      }),
      transformResponse: (userData: any) => {
        if (userData) {

          if (userData.privateKey) {
            const data = { privateKey: userData.privateKey };
            const request = indexedDB.open("myDatabase", 1);

            request.onsuccess = (event) => {
              const db = (event.target as IDBOpenDBRequest).result;
              const transaction = db.transaction(["myStore"], "readwrite");
              const store = transaction.objectStore("myStore");
              store.clear();
              const addRequest = store.add(data);

              addRequest.onsuccess = () => {
                console.log("Data added successfully");
              };

              addRequest.onerror = (event) => {
                console.error(
                  "Error adding data",
                  (event.target as IDBOpenDBRequest).error
                );
              };
            };
          }

          localStorage.setItem("userData", JSON.stringify(userData));
        }
      },
      //invalidatesTags: (permissions) => [{ type: 'Permissions', id:"LIST" }],
    }),
  }),
});

export const {
  useGetDashboardDataQuery,
  useGetProfileEventsListQuery,
  useGetEventsDataQuery,
  useGetEventsListQuery,
  useGetActivitiesDataQuery,
  useGetAnnoucementDataQuery,
  useGetAnnoucementTypeDataQuery,
  useGetDocumentDataQuery,
  useGetEmBottomLabelDataQuery,
  useGetProjectLabelDataQuery,
  useGetPathArraysDataQuery,
  useGetDocumentTableDataQuery,
  useGetDashboardCountDataQuery,
  useGetEmployeeLabelDataQuery,
  // useGetEmployeeDataQuery,
  useGetEventTypesQuery,
  useCreateEventMutation,
  useGetIndicatorsQuery,
  useGetActivityLogsDataQuery,
  useGetProjectsQuery,
  useCreateEventTypeDataMutation,
  useEditNoteDataMutation,
  useGetDocumentLabelDataQuery,
  useGetEventsListForLeaveQuery,
  useViewAnnoucementMutation,
  useLoginWithSSOMutation,
  useGetLoginDataMutation,
} = dashboardApi;

export const {
  endpoints: {
    getLoginData,
    getProfileEventsList,
    getEventsData,
    getEventsList,
    getEventsListForLeave,
    getDocumentData,
    getActivitiesData,
    getAnnoucementData,
    getAnnoucementTypeData,
    getDashboardCountData,
    getEmBottomLabelData,
    getProjectLabelData,
    getEmployeeLabelData,
    getPathArraysData,
    getDocumentTableData,
    // getEmployeeData,
    getEventTypes,
    createEvent,
    getIndicators,
    getProjects,
    createEventTypeData,
    editNoteData,
    getDocumentLabelData,
    viewAnnoucement,
    getActivityLogsData,
    loginWithSSO,
    getDashboardData
  },
} = dashboardApi;
