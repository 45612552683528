import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
  uniqueID: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
};
type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  data: TData;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
type departmentInput = {
  page: number;
  limit: number | string | undefined,
  search: string | number,
};
export type TaskCategoriesList = {
  categoryList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const eventRemainderApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEventRemainderList: build.query<DepartmentsResponse, departmentInput>({
      query: ({limit,page,search}) => ({
        url: `events?limit=${limit}&page=${page}&search=${search}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getAllEventRemainderList: build.query<TaskCategoriesList, void>({
      query: () => ({
        url: `events`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createEventRemainder: build.mutation<
    TaskCategoriesList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `events`,
          body,
          method: "POST",
        };
      },
    }),
    updateEventRemainder: build.mutation<
    TaskCategoriesList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `events/${body.uniqueID}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteEventRemainder: build.mutation<TaskCategoriesList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/events/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetEventRemainderListQuery,
  useGetAllEventRemainderListQuery,
  useCreateEventRemainderMutation,
  useUpdateEventRemainderMutation,
  useDeleteEventRemainderMutation
} = eventRemainderApi;

export const {
  endpoints: {
    getEventRemainderList,
    getAllEventRemainderList,
    createEventRemainder,
    deleteEventRemainder,
    updateEventRemainder,
  },
} = eventRemainderApi;
