import { initializeApp } from 'firebase/app';
import { api, endpointUrl } from './api';
import authHeader from './auth-header';
import { deleteToken, getMessaging } from 'firebase/messaging';

export type MobileLogoResponse = {
    logoData: AnnounSheet[]
};


export type NoticeBoardDataResponseCus = {
    noticeBoardTableData: MobileLogoResponse,
    module_id: number,
};
export type AnnounSheet = {
    id: number,
    title: string,
    tag: string[],
    postedDate: string,
    expiryDate: string,
    postedBy: string,
    updatedBy: string,
    type: string,
    viewBy?: [],
    content: string,
    data?: any,
}

export type TagsResponse = {
    tagsData: TagsData[]
}
export type TagsResponseCustom = {
    tagList: TagsData[]
}
export type TagsData = {
    id: number,
    name: string,
    flag: string
}

export type IMobileLogo = {
    logo: string,
    banner: string
}


export type TemplateData = {
    id: number;
    name: string;
    viewColumns: string[];
};

export type ErrorState = {
    status?: number | string,
    data?: any | undefined,
    error?: string
}
export type TagInput = {
    name: string;
    flag: string;
}
export type PaginationData = {
    page: number;
    limit: number | string | undefined,
    search: string | number,
    tag: string | number | undefined,
    type: string | number | undefined,
    startDate?: string;
    endDate?: string;
    sort?:string;
}
export const deleteNotiToken = () => {
    // const app = initializeApp(firebaseConfig);
    // const messaging = getMessaging(app);
    // deleteToken(messaging).then((res) => {
    // }).catch(function (error) {
    //     console.error('Error deleting FCM token:', error);
    //   });;
}
export const redirectToLogin = () => {
    localStorage.clear();
    window.location.href = '/';
    deleteNotiToken();
}
export const mobile_api = api.injectEndpoints({
    endpoints: (build) => ({
        getMobileLogo: build.query<MobileLogoResponse, void>({
            query: () => ({
                url: `${endpointUrl}mobilelogos`,
                headers: authHeader()
            }),
            keepUnusedDataFor:0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
        }),
        
    })
});


export const {
    useGetMobileLogoQuery
} = mobile_api;

export const {
    endpoints: {
        getMobileLogo
    },
} = mobile_api;