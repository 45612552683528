import { FC, useEffect, useMemo, useState, useCallback } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
// import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
// import Button from "../../components/common/Button";
// import { checkedIsNew } from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useSpring, animated } from "react-spring";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
// import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
// import Claim2Component from "../../components/ClaimType/ClaimType2Component";
// import ClaimTypeTable from "../../components/ClaimType/ClaimTypeTable";
// import { useGetClaimTypeListQuery } from "../../app/services/claim-type";
import { RootState } from "../../app/store";
// import ProjectCheckerTable from "../../components/ProjectChecker/ProjectCheckerTable";
// import ProjectChecker2Component from "../../components/ProjectChecker/ProjectChecker2Component";
import ProjectCheckerAccTable from "../../components/ProjectCheckerAcc/ProjectChackerAccTable";
import ProjectCheckerAcc2Component from "../../components/ProjectCheckerAcc/ProjectCheckerAcc2component";
import {
  TaskCategory,
  useGetProgressCheckerAccFilterDataQuery,
  useGetProgressCheckerAccQuery,
} from "../../app/services/progress-checker-acc";

export interface IOTData {
  id: number;
  employeeID: string;
  employeeName: string;
  department: string;
  date: string;
  fullHalfDay: string;
  timeIn: string;
  timeOut: string;
  otAmt: string;
}

interface IOT {}

export interface IDepartment {
  id: number;
  name: string;
  label: string;
}
const defaultShowFields = [
  "project_id",
  "project_name",
  "sale_hkd",
  "cost_spent_cny",
  "cost_spent_hkd",
];
const defaultFields = [
  "project_id",
  "project_name",
  "stage",
  "team_leader_name",
  "project_manager_name",
  "employees",
  "time_spent",
  "start_date",
  "launch_date",
  "sale_aud",
  "sale_cny",
  "sale_hkd",
  "sale_mmk",
  "sale_myr",
  "sale_php",
  "sale_sgd",
  "sale_usd",
  "cost_spent_aud",
  "cost_spent_cny",
  "cost_spent_hkd",
  "cost_spent_mmk",
  "cost_spent_myr",
  "cost_spent_php",
  "cost_spent_sgd",
  "cost_spent_usd",
  "profit",
  "remark",
  "actions",
];

const claimTypeDataList: any = [
  {
    id: 0,
    name: "All",
    label: "All",
  },
  {
    id: 1,
    name: "by Year",
    label: "year",
  },
  {
    id: 2,
    name: "by Month",
    label: "month",
  },
  ,
  {
    id: 3,
    name: "by Week",
    label: "week",
  },
  {
    id: 4,
    name: "by Day",
    label: "day",
  },
];

export type TStatusListData = {
  id: number | string;
  name: string;
  label?: string;
};

const statusListData: TStatusListData[] = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "InActive",
  },
];
const limitedData = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "Limited",
  },
  {
    id: 0,
    name: "Unlimited",
  },
];

const dymmyData = [
  {
    id: 1,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
  {
    id: 2,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
  {
    id: 3,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
  {
    id: 4,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
  {
    id: 5,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
  {
    id: 6,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
  {
    id: 7,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
  {
    id: 8,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
  {
    id: 9,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
  {
    id: 10,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    sale: "HK 1234",
    cost_spent: "MMK 90000000",
    profit_lost: "+10%",
    remark: "",
  },
];

export const addNewChecker = () => {
  return {
    id: 0,
    project_id: "",
    project_name: "",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "",
    project_manager: "",
    time_spent: "",
    sale: "",
    cost_spent: "",
    profit_lost: "",
    remark: "",
    isNew: true,
  };
};

const ProjectCheckerAcc: FC<IOT> = () => {
  // const navigate = useNavigate();
  const { alerts } = useSelector((state: RootState) => state.alertClaim);

  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const [department, setDepartment] = useState<string | number>("All");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  // const [otStatus, setOtStatus] = useState<any>("All");
  const [limitStatus, setLimitStatus] = useState<any>([]);
  const [message, setMessage] = useState<any>([]);
  // const [limit, setLimit] = useState<number>(10);
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  // const [startDate, setStartDate] = useState<Date>();
  // const [endDate, setEndDate] = useState<Date>();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  // const [departData, setDepartData] =
  //   useState<IDepartment[]>(claimTypeDataList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [tempList, setTempList] = useState<any>(templateList);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [moduleList, setModuleList] = useState<any>([]);
  const [data, setData] = useState<baseObj[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(false);
  // const [listSkip, setListSkip] = useState<boolean>(false);
  const [stageData, setStageData] = useState<TaskCategory[]>();
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const { data: progressCheckerAcc } = useGetProgressCheckerAccQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${s.id == "project_id" ? "project_code" : s.id}`
      )
      .join(","),
    task_category_id: limitStatus,
  });
  const { data: progressCheckerAccFilter } =
    useGetProgressCheckerAccFilterDataQuery();
  const [totalTime, setTotalTime] = useState<string>();
  const [totalAudTime, setTotalAudTime] = useState<string>();
  const [totalCnyTime, setTotalCnyTime] = useState<string>();
  const [totalHKDTime, setTotalHKDTime] = useState<string>();
  const [totalMMKTime, setTotalMMKTime] = useState<string>();
  const [totalMYRTime, setTotalMYRTime] = useState<string>();
  const [totalPHPTime, setTotalPHPTime] = useState<string>();
  const [totalSGDTime, setTotalSGDTime] = useState<string>();
  const [totalUSDTime, setTotalUSDTime] = useState<string>();
  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList);
  };

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  const changePage = (pageNo: number) => {};

  useEffect(() => {
    if (progressCheckerAcc) {
      setData(progressCheckerAcc?.progress_checker_account_list?.data);
      setItemCount(progressCheckerAcc?.progress_checker_account_list?.total);
      setTotalTime(progressCheckerAcc?.total_time);
      setTotalAudTime(progressCheckerAcc?.total_cost_spent_aud);
      setTotalCnyTime(progressCheckerAcc?.total_cost_spent_cny);
      setTotalHKDTime(progressCheckerAcc?.total_cost_spent_hkd);
      setTotalMMKTime(progressCheckerAcc?.total_cost_spent_mmk);
      setTotalMYRTime(progressCheckerAcc?.total_cost_spent_myr);
      setTotalPHPTime(progressCheckerAcc?.total_cost_spent_php);
      setTotalUSDTime(progressCheckerAcc?.total_cost_spent_usd);
      setTotalSGDTime(progressCheckerAcc?.total_cost_spent_sgd);
      setLocalModuleId(progressCheckerAcc?.module_id);
    }
  }, [progressCheckerAcc]);
  useEffect(() => {
    if (progressCheckerAccFilter) {
      setStageData(progressCheckerAccFilter?.task_categories);
    }
  }, [progressCheckerAccFilter]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() == currentView.toString()
    );

    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
  }, [templateList, currentView]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields([...viewCols?.view_columns]);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitStatus, pagination.pageSize, currentView, globalSearch]);

  useEffect(() => {
    if (alerts[0]?.type) {
      toast(`${alerts[0]?.message}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }
  }, [alerts[0]?.type]);

  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] hidden">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {(setState) => (
                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px] sm:w-[260px] w-[225px]">
                  <input
                    id="message"
                    autoComplete="off"
                    autoCorrect="false"
                    autoFocus={false}
                    name="search"
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-[0.5rem] px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    // py-[0.15rem]
                    onChange={(e) => setState(e.target.value)}
                  />
                  <SearchIcon className={classNames("mr-2")} />
                </div>
              )}
            </DebounceSearchInput>
          </div>
          {/* <div className="flex w-full sm:w-auto items-center justify-between md:justify-end lg:justify-end">
            {true ? (
              <Button
                disabled={disabledData?.disabled}
                label={<span className="flex items-center">+ Add New</span>}
                onClick={() => {
                  setIsSuccess(false);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                  // handleAddNew();
                  setData((prev) => [addNewChecker(), ...prev]);
                }}
                type="button"
                variant="primary"
                customClass={
                  disabledData?.disabledClass +
                  " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                }
                size="base"
              />
            ) : null}
          </div> */}
        </div>
        <ProjectCheckerAcc2Component
          globalSearch={globalSearch}
          setGlobalSearch={setGlobalSearch}
          filterOneName="Stage"
          filterTwoName="TimeSpent"
          setPagination={setPagination}
          pagination={pagination}
          setIsTemplate={setIsTemplate}
          isTemplate={isTemplate}
          currentViewData={currentViewData}
          currentView={currentView}
          setCurrentView={setCurrentView}
          moduleList={moduleList}
          deleteTemplateData={deleteTemplateData}
          setErrorPopup={setErrorPopup}
          setSuccessPopup={setSuccessPopup}
          setMessage={setMessage}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          statusListData={
            stageData
              ? stageData.map((stage: TaskCategory) => {
                  return {
                    id: stage.id,
                    name: stage.name,
                  };
                })
              : []
          }
          limitStatus={limitStatus}
          setLimitStatus={setLimitStatus}
        />

        <animated.div style={props}>
          <ProjectCheckerAccTable
            data={data}
            setData={setData}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            pagination={pagination}
            setPagination={setPagination}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields ? hiddenFields : defaultFields}
            templateList={tempList}
            setCurrentView={setCurrentView}
            setIsEdit={setIsEdit}
            isEditing={isEditing}
            localModuleId={localModuleId}
            total={itemCount}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsTemplate={setIsTemplate}
            setCols={setCols}
            visArray={visArray}
            setSkip={setSkip}
            sorting={sorting}
            setSorting={setSorting}
            totalTime={totalTime}
            cost_spent_usd={totalUSDTime}
            cost_spent_sgd={totalSGDTime}
            cost_spent_hkd={totalHKDTime}
            cost_spent_cny={totalCnyTime}
            cost_spent_mmk={totalMMKTime}
            cost_spent_myr={totalMYRTime}
            cost_spent_php={totalPHPTime}
            cost_spent_aud={totalAudTime}
          />
        </animated.div>
      </div>
    </div>
  );
};
export default ProjectCheckerAcc;
