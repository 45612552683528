import { useCallback, useEffect, useState, useMemo } from "react";
import Button from "../../components/common/Button";
import {
  AddIcon,
  CloseIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import {
  NumberDropdownList,
  // StringDropdownList,
} from "../../components/common/DropdownList";
// import {
//   tagTypes,
//   tagTypeArr
// } from "../../utils/announcementDatas";
import { DBusers } from "../../utils/DBusers";
import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
// import CustomDatePicker from "../../components/common/BaseDatePicker";
import Styles from "./NoticeBoard.module.scss";
import ModalAnnounEditor, {
  typeList,
} from "../../components/ModalAnnounEditor/ModalAnnounEditor";
import {
  AnnounSheet,
  useCreateTagMutation,
  useGetTagsDataCustomQuery,
} from "../../app/services/annoucement-sheet";
import { baseObj } from "../../utils/constants";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import AnnouncementsTable from "../../components/Announcements/AnouncementsTable";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { getAuthUser } from "../../app/services/dashboard"; //docUserData,
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import DatePopupFoFilter from "../../components/EmployeeTable/DatePopupFoFilter";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import { renderDateFormat } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
import { IFilterDate } from "../../documents/views/EmployeeDocumentList";
import { useSpring, animated } from "react-spring";
import { ToastContainer, toast } from "react-toastify";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import { useGetAllUsersQuery } from "../../app/services/userlist";
import {
  useCreateNoticeBoardMutation,
  useGetNoticeBoardDataWithPaginationQuery,
} from "../../app/services/notice-board";
import NoticeBoardModalEditor from "../../components/NoticeBoardModalEditor/NoticeBoardModalEditor";
import { useGetDepartmentAllListQuery } from "../../app/services/departments";
import { useGetCategoryAllListQuery } from "../../app/services/category";
import {
  useDeleteTemplateDataMutation,
  useGetEmployeeAllListQuery,
  useGetEmployeeDataQuery,
  useGetEmployeeListQuery,
} from "../../app/services/employee";
import NoticeBoardTable from "../../components/Announcements/NoticeBoardTable";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import { useGetByModuleTemplateQuery } from "../../app/services/template";

interface IFilterStates {
  tag: string;
  type: string;
}

const NoticeBoard = () => {
  const navigate = useNavigate();
  const { data: categories } = useGetCategoryAllListQuery();
  const [tagList, setTagList] = useState<any>([]);
  const [addNewNoticeBoard] = useCreateNoticeBoardMutation();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [userList, setUserList] = useState<any>([]);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [commonMessage, setCommonMessage] = useState<any>([]);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [typeDataList, setTypeDataList] = useState<string[]>([
    "All",
    ...typeList,
  ]);

  const [isNewAnModalOpen, setIsNewAnModalOpen] = useState<boolean>(false);
  const [filterStates, setFilterStates] = useState<IFilterStates>({
    tag: "",
    type: "",
  });
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const { data: userListData } = useGetAllUsersQuery({
    search: "",
    role: "",
    page: 1,
    limit: 1000,
    sort: "",
  });
  const defaultFields = useMemo(
    () => [
      "id",
      "name_en",
      "name_tc",
      "name_sc",
      "categories",
      "published_at",
      "expired_at",
      "created_by",
      "updated_at",
      "",
    ],
    []
  );

  // const [errors, setErrors] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [currentView, setCurrentView] = useState<any>(0);
  const [filterDate, setFilterDate] = useState<IFilterDate>();
  // const [startDate, setStartDate] = useState<Date>();
  // const [endDate, setEndDate] = useState<Date>();
  const [announData, setAnnounData] = useState<AnnounSheet[]>([]);
  const [totalLength, setTotalLength] = useState(0);
  const [loggedUser, setLoggedUser] = useState("");
  const [data, setData] = useState<baseObj[]>([]);
  const [tempList, setTempList] = useState<any>(templateList);
  // const { data: templateList } = useGetAnnoTemplateListQuery();
  const [deleteTemplate] = useDeleteTemplateDataMutation();
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [moduleList, setModuleList] = useState<any>([]);
  //for getuser permissions
  const [addNew, setAddNew] = useState<boolean>(false);
  const [checkExport, setCheckExport] = useState<boolean>(false);
  const [checkImport, setCheckImport] = useState<boolean>(false);
  const [checkManagement, setCheckManagement] = useState<boolean>(false);
  // const [fetch, setFetch] = useState<boolean>(false)
  const [createTag] = useCreateTagMutation();
  const [sorting, setSorting] = useState<SortingState>([]);
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  const [saving, setSaving] = useState<boolean>(false);
  const { data: empDepartmentData } = useGetDepartmentAllListQuery();
  const { data: employees } = useGetEmployeeAllListQuery();
  const [departmentData, setDepartmentData] = useState<any>([]);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [editRowDataCus, setEditRowDataCus] = useState<any>();
  const {
    data: annoucement,
    error: announcErrors,
    isLoading,
  } = useGetNoticeBoardDataWithPaginationQuery({
    limit: pagination?.pageSize,
    page: currentPage,
    search: globalSearch,
    tag: filterStates.tag === "All" ? "" : filterStates.tag,
    startDate: filterDate?.startDate
      ? renderDateFormat(filterDate?.startDate, "/")
      : "",
    endDate: filterDate?.endDate
      ? renderDateFormat(filterDate?.endDate, "/")
      : "",
    type: filterStates.type === "All" ? "" : filterStates.type,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${
            s.id == "postedDate"
              ? "posted_at"
              : s.id == "expiryDate"
              ? "expired_at"
              : s.id == "postedBy"
              ? "posted_user_name"
              : s.id == "updatedBy"
              ? "updated_user_name"
              : s.id
          }`
      )
      .join(","),
  });
  const [itemCount, setItemCount] = useState<number>(0);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    if (userListData) {
      setUserList(userListData?.usersList?.data);
    }
  }, [userListData]);

  const createTagData = (label: string) => {
    var data = {
      name: label,
      flag: "anno",
    };

    // return createTag(data)
  };
  useEffect(() => {
    if (empDepartmentData) {
      setDepartmentData(
        empDepartmentData ? empDepartmentData.departmentList : []
      );
    }
  }, [empDepartmentData]);
  useEffect(() => {
    if (employees) {
      setEmployeeList([{id:'all',name:'All'},...employees?.employeeList]);      
    }
  }, [employees]);
  useEffect(() => {
    const filterCreate = getAuthPermis.filter((f) => f === "Create_NotiBoard");
    const filterExport = getAuthPermis.filter(
      (f) => f === "Export_Announcement"
    );
    const filterImport = getAuthPermis.filter(
      (f) => f === "Import_Announcement"
    );
    const filterManagement = getAuthPermis.filter(
      (f) => f === "Manage_NotiBoard"
    );
    if (filterManagement.length > 0) {
      setCheckManagement(true);
    }

    if (filterImport.length > 0) {
      setCheckImport(true);
    }
    if (filterExport.length > 0) {
      setCheckExport(true);
    }
    if (filterCreate.length > 0) {
      setAddNew(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (annoucement) {
      var annoucements = annoucement;
      var annoucemenstNewData = annoucements?.noticeBoardTableData?.data?.map(
        (x: any) => {
          return { ...x, annoucement: x.title };
        }
      );
      setData(annoucemenstNewData);
      // setAnnoucementsTempList(annoucemenstNewData);
    }

    if (annoucement) {
      setAnnounData(annoucement?.noticeBoardTableData?.data);
      setItemCount(annoucement?.noticeBoardTableData?.total);
      if (annoucement?.noticeBoardTableData?.data) {
        setData(annoucement?.noticeBoardTableData?.data);
      } else {
        setData([]);
      }
      setLocalModuleId(annoucement?.module_id);
    }

    // if (startDate && endDate) {

    //   var filterData = annoucement?.announsheets
    //     .map((x: any) => {
    //       // console.log("out x", x)
    //       // console.log("npd", new Date('2023:04:03 04:48:23'))

    //       // if (startDate < new Date(x.postedDate) && endDate > new Date(x.postedDate)) {
    //       if (startDate <= new Date(x.postedDate) && endDate >= new Date(x.postedDate)) {
    //         // console.log("x is", x)
    //         return x;
    //       }
    //     })
    //     .filter((x: any) => x != undefined);
    //   setAnnounData(filterData ? filterData : []);
    // }

    // if (filterStates?.tag != "All") {
    //   if (annoucement?.announsheets !== undefined) {
    //     var dataAnnoun = annoucement?.announsheets ? annoucement?.announsheets : [];

    //     const filterDatas = dataAnnoun.map((data: any) => {
    //       const datas = data?.tags.find((tag: any) => tag.name.toLowerCase() === filterStates.tag.toLowerCase())
    //       if (datas) {
    //         return data;
    //       }
    //     }).filter((x: any) => x != undefined);
    //     setAnnounData(filterDatas ? filterDatas : []);
    //   }
    // }

    const dataStorage: any = localStorage.getItem("userData");
    var parsedObject = JSON.parse(dataStorage);

    // ACCESS DATA
    const loggedUser: any = DBusers.find(
      (user) => user.email === parsedObject.value
    );
    setLoggedUser(loggedUser.username);
    // setMyLocalStorageData(JSON.parse(dataStorage));
    // console.log(myLocalStorageData);
  }, [filterStates, filterDate, annoucement]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i: any) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };

      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x: any) => x.id.toString() === currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    document.querySelectorAll("th,td").forEach((x) => {
      if (x.id.includes("none")) {
        x.classList.add("hidden");
      }
      if (x.id.includes("action")) {
        x.classList.add("absolute");
        x.classList.add("right-0");
      }
      x.classList.remove("absolute");
      x.classList.add("left-0");
      x.classList.add("relative");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
    });
    // setTemplatePlaceholder(currentView);
  }, [currentView, defaultFields]); //templateList,

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]); //isTemplate &&

  const updateFilterState = useCallback(
    (value: string, key: string) => {
      setFilterStates((prev) => ({ ...prev, [key]: value }));
    },
    [setFilterStates]
  );

  useEffect(() => {
    var tagsDataList = categories?.categoryList
      // ?.filter((x: any) => x.flag === "anno" || x.flag === null)
      ?.map(function (item: any) {
        return {
          value: item?.name_en,
          label: item?.name_en,
        };
      });
    var allItems = { value: "All", label: "All" };
    if (tagsDataList) {
      setTagList([allItems, ...tagsDataList]);
    }
  }, [categories]);

  const handleInput = async (value: any,id:string) => {
    const url=id?endpointUrl + "noticeboards/"+id:endpointUrl + "noticeboards"
    await axios(url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
      data: value,
    })
      .then((res: any) => {
        if (res.data.status == true) {
          if(id){
            setData([...data.map((x) => {
              if (x.id == id) {
                return res.data.noticeBoard;
              }
              return x;
            })]);
          }else{
            setData([res.data.noticeBoard,...data]);
          }
          setSaving(false);
          setIsNewAnModalOpen(false);
          toast("Successfully Created.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        setErrorPopup(true);
        setSaving(false);
        setCommonMessage(error?.response?.data?.message);
      });

    // addNewNoticeBoard(formData)
    //   .unwrap()
    //   .then((payload: any) => {
    //     // console.log("p", payload)
    //     if (payload.status) {
    //       setIsNewAnModalOpen(false);
    //       setSuccessPopup(true);
    //       setMessage([payload?.message]);
    //       toast("Successfully Created.", {
    //         position: "top-right",
    //         autoClose: 2000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         type: "success",
    //         theme: "light",
    //       });
    //     }
    //   })
    //   .catch((error: any) => {
    //     setIsNewAnModalOpen(true);
    //     setErrorPopup(true);
    //     setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
    //   });
  };

  const addNewAnnounHandler = () => {
    setSaving(false);
    setEditRowDataCus({});
    setIsNewAnModalOpen(true);
    let totalId = announData?.length + 1;
    setTotalLength(totalId);
  };

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList?.filter(
      (x) => Number(x.id) !== parseInt(id)
    );
    setTempList(filterTempList ? filterTempList : []);
    deleteTemplate(parseInt(id));
  };

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadAnnouncementsSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sampleAnnouncement.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };

  const importAnnounc = async (formSaveData: any) => {
    await axios(endpointUrl + "importAnnouncements", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formSaveData,
    })
      .then((res) => {
        axios.get(`${endpointUrl}announcements`, {}).then((result) => {
          var UpdatedData = result?.data?.announsheets?.data;
          setData(UpdatedData);
          // setFetch(false);
          setIsImporting(true);
          setIsImportModalOpen(false);
          toast("Successfully Import.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
        });
      })
      .catch((error: any) => {
        // console.log("e", error)
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
    // setFetch(false)
  };

  const exportData = (format: string, isChecked: string) => {
    axios({
      url:
        endpointUrl +
        `announcements?limit=${pagination?.pageSize}&tag=${
          filterStates.tag === "All" ? "" : filterStates.tag
        }&startDate=${
          filterDate?.startDate
            ? renderDateFormat(filterDate?.startDate, "/")
            : ""
        }&endDate=${
          filterDate?.endDate ? renderDateFormat(filterDate?.endDate, "/") : ""
        }&page=${currentPage}&search=${globalSearch}&type=${
          filterStates.type === "All" ? "" : filterStates.type
        }&format=${format}&export=true&only_this_page=${isChecked}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "announcements." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, filterDate, pagination.pageSize]);

  useEffect(() => {
    if (successPopup) {
      toast("Done Successfully.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1100,
  });

  if (announcErrors) {
    if (FetchBaseError(announcErrors)) {
      if (announcErrors?.status === 400) {
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        setErrorPopup(true);
        setMessage(
          announcErrors?.data?.message
            ? [announcErrors?.data?.message]
            : ["You are something wrong. Pls login Again!"]
        );
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton />
      ) : (
        <>
          <ImportUsersModal
            isOpen={isImportModalOpen}
            setIsOpen={setIsImportModalOpen}
            sampleCSV={downloadSampleFile}
            importEmployee={importAnnounc}
            isImporting={isImporting}
            setIsImporting={setIsImporting}
            setIsDownloading={setIsDownloading}
            isDownloading={isDownloading}
            isChecked={true}
          />
          <ExportUsersModal
            isOpen={isExportModalOpen}
            setIsOpen={setIsExportModalOpen}
            exportToCSV={exportData}
            exportToExcel={exportData}
            exportToPDF={exportData}
            exportToZip={exportData}
            isExporting={isExporting}
            setIsExporting={setIsExporting}
            isShowOnlyCheckbox={true}
          />

          <ToastContainer className={"text-12 font-poppins font-normal"} />
          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />

          <div className="bg-white rounded-xl pt-4 pb-4 sm:pt-4 sm:pb-9 px-4 w-full h-full font-primary">
            <div className="flex flex-wrap justify-between items-center mb-1">
              <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
                <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                  {(setState) => (
                    <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                      <input
                        type="text"
                        placeholder="Search"
                        className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                        onChange={(e) => setState(e.target.value)}
                      />
                      <SearchIcon className="mr-2" />
                    </div>
                  )}
                </DebounceSearchInput>
              </div>

              <div className="flex items-center xs:mt-1 sm:mt-1">
                {checkImport ? (
                  <Button
                    label={
                      <span className="flex items-center">
                        <ImportIcon className="mr-[5px]" />
                        Import
                      </span>
                    }
                    onClick={() => {
                      setIsImportModalOpen(true);
                      setIsImporting(false);
                    }}
                    type="button"
                    variant="no-outline"
                    customClass="bg-transparent border-transparent sm:flex hover:border-vorpblue hover:text-vorpblue transition-all duration-300 text-black2 xs:text-[12px] leading-[18px] font-medium py-[7px] px-[6px] mr-[10px]"
                    size="base"
                  />
                ) : null}
                {checkExport ? (
                  <Button
                    label={
                      <span className="flex items-center">
                        <ExportIcon className="mr-[5px]" />
                        Export
                      </span>
                    }
                    onClick={() => {
                      setIsExportModalOpen(true);
                      setIsExporting(false);
                    }}
                    type="button"
                    variant="no-outline"
                    customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                    size="base"
                  />
                ) : null}
                {addNew ? (
                  <Button
                    label="+ Add New"
                    onClick={addNewAnnounHandler}
                    type="button"
                    variant="primary"
                    customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                    size="base"
                  />
                ) : null}
              </div>
            </div>

            <div className="flex sm:flex-row flex-col justify-between mb-1">
              <div className="flex w-full sm:mt-0 mt-1 sm:mb-0 mb-1 sm:mr-5 custom-scrollbar">
                <CustomScrollbar isVertical={false} isHor={true}>
                  <div className="flex w-full items-center">
                    {/* <div
                      className={`flex items-center w-auto mr-3 ${Styles.dropdownFillAvailable}`}
                    >
                      <p className="hidden base-label xl:text-sm text-12 2xs:w-auto w-1/2 text-left">
                        TAG
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={filterStates.tag}
                        setState={(value) =>
                          updateFilterState(value as string, "tag")
                        }
                        dropdownItems={tagList}
                        classNameCus="max-w-[78px] xs:max-w-[250px] 2xl:min-w-[240px] min-w-[200px]"
                        className="min-w-[200px] w-full max-w-full xl:min-w-[128px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                        placeholder="Tag"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div> */}
                    {/* <div className="flex items-center">
                      <p className="hidden 2xs:w-auto w-1/2 text-left uppercase mb-3 xs:mb-0 xl:text-sm text-12 leading-5 font-medium text-black2 mr-3">
                        Date
                      </p>
                      {filterDate?.startDate && filterDate?.endDate && (
                        <button
                          type="button"
                          onClick={() =>
                            setFilterDate({
                              startDate: undefined,
                              endDate: undefined,
                            })
                          }
                          className="text-12 mr-2"
                        >
                          <CloseIcon color="#EE436D" width={10} height={10} />
                        </button>
                      )}
                      <div className="flex">
                        <div className={`mr-3`}>
                          <DatePopupFoFilter
                            placeholder="Start"
                            date={filterDate?.startDate}
                            setDate={(value) =>
                              setFilterDate((prev) => ({
                                ...prev,
                                startDate: value as Date,
                              }))
                            }
                          />
                        </div>
                        <div className={`mr-3`}>
                          <DatePopupFoFilter
                            placeholder="End"
                            date={filterDate?.endDate}
                            setDate={(value) =>
                              setFilterDate((prev) => ({
                                ...prev,
                                endDate: value as Date,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div
                      className={`flex items-center w-auto mr-3 ${Styles.dropdownFillAvailable}`}
                    >
                      <p className="hidden base-label xl:text-sm text-12 2xs:w-auto w-1/2 text-left">
                        Type
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={filterStates.type}
                        setState={(value) =>
                          updateFilterState(value as string, "type")
                        }
                        dropdownItems={typeDataList?.map((t: any) => {
                          return {
                            label: t,
                            value: t,
                          };
                        })}
                        classNameCus="max-w-[78px] xs:max-w-[250px] 2xl:min-w-[240px] min-w-[200px]"
                        className="min-w-[200px] w-full max-w-full xl:min-w-[128px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                        placeholder="Type"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div> */}
                  </div>
                </CustomScrollbar>
              </div>
              <div className="flex items-center sm:justify-start justify-end">
                <div className="flex items-center 3xl:ml-3 mr-[10px]">
                  <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                    DISPLAY
                  </p>
                  <NumberDropdownList
                    state={pagination.pageSize}
                    setState={(value) =>
                      setPagination((prev) => ({
                        ...prev,
                        pageSize: value as number,
                      }))
                    }
                    dropdownItems={new Array(5)
                      .fill(1)
                      .map((_, index: number) => {
                        const count = (index + 1) * 20;
                        return {
                          value: count,
                          label: String(count),
                        };
                      })}
                    className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                    textAlign="text-left pl-3"
                    paddingClass="py-[2px]"
                    placeholder="All"
                  />
                </div>
                <div className="">
                  <StringDropdownListWithDeleteBtn
                    haveDeleteBtn={true}
                    setIsTemplate={setIsTemplate}
                    state={isTemplate ? currentViewData : currentView}
                    setState={setCurrentView}
                    deleteTemplate={deleteTemplateData}
                    dropdownItems={moduleList.map((x: any, index: number) => {
                      return {
                        label: x.id === -1 ? x.name : "View #" + x.index,
                        value: x.id,
                      };
                    })}
                    className="w-full min-w-[100px] 2xl:min-w-[128px] 6xs:text-12"
                    placeholder="View#1"
                    textAlign="text-left py-2 pl-2 pr-3"
                    custPadding="py-[2px]"
                    setErrorPopup={setErrorPopup}
                    setSuccessPopup={setSuccessPopup}
                    setMessage={setMessage}
                  />
                </div>

                <button
                  type="button"
                  onClick={() => setIsLayoutControlOpen(true)}
                  className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                >
                  <CustomizeLayoutIcon />
                  <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                    <AddIcon width={8} height={8} color="white" />
                  </div>
                </button>
              </div>
            </div>

            <animated.div style={props}>
              <div className="text-left">
                <NoticeBoardTable
                  // columnsData={columnsData}
                  isLayoutControlOpen={isLayoutControlOpen}
                  setIsLayoutControlOpen={setIsLayoutControlOpen}
                  globalSearch={globalSearch}
                  setGlobalSearch={setGlobalSearch}
                  columnFilters={columnFilters}
                  setColumnFilters={setColumnFilters}
                  dummyAnnounData={announData}
                  loggedUser={loggedUser}
                  pagination={pagination}
                  data={data}
                  setData={setData}
                  setPagination={setPagination}
                  hiddenFields={hiddenFields ? hiddenFields : []}
                  setHiddenFields={setHiddenFields}
                  total={itemCount}
                  localModuleId={localModuleId}
                  // visArray={visArray}
                  templateList={tempList}
                  setCurrentView={setCurrentView}
                  setCols={setCols}
                  checkManagement={checkManagement}
                  setIsTemplate={setIsTemplate}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  // changePage={changePage}
                  createTagData={createTagData}
                  userList={userList}
                  sorting={sorting}
                  setSorting={setSorting}
                  setEditRowDataCus={setEditRowDataCus}
                  editRowDataCus={editRowDataCus}
                  setIsNewAnModalOpen={setIsNewAnModalOpen}
                />
              </div>
            </animated.div>
          </div>

          <NoticeBoardModalEditor
            createTagData={createTagData}
            isOpen={isNewAnModalOpen}
            setIsOpen={setIsNewAnModalOpen}
            valueChangeCallback={handleInput}
            totalLength={totalLength}
            loggedUser={loggedUser}
            saving={saving}
            setSaving={setSaving}
            departments={departmentData}
            employeeList={employeeList}
            categories={categories}
            editRowData={editRowDataCus}
          />
          <CommonErrorPopup
            show={errorPopup}
            setShow={setErrorPopup}
            message={commonMessage}
          />
        </>
      )}
    </>
  );
};
//

export default NoticeBoard;
