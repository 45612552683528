// import { Popper } from "@material-ui/core";
// import { Popper } from "@mui/material"
// hello

import {
    Cell,
    ColumnDef,
    ColumnFiltersState,
    createColumnHelper,
    PaginationState,
    // RowData,
    RowSelectionState,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
    FC,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    ViewIcon,
} from "../common/Icons";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import styles from "./EquipmentTable.module.scss";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { toast, ToastContainer } from "react-toastify";
import { IEquipmentLog } from "../../equipments/views/Equipments";
import EquipmentReturnNotePopup from "../../equipments/views/EquipmentReturnNotePopup";

interface IEquipmentLogTable {
    data: any;
    setLogsData: React.Dispatch<React.SetStateAction<IEquipmentLog | undefined>>;
    logsData: IEquipmentLog | undefined;
    setLogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isLogOpen: boolean;
}

const EquipmentLogTable: FC<IEquipmentLogTable> = ({
    data,
    setLogsData,
    logsData,
    setLogIsOpen,
    setIsOpen
}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });
    
    
    const viewLogsData = (cell: any) => {
        setLogsData(cell.row.original);
        setLogIsOpen(true);
        setIsOpen(false)
    }
    const columns = useMemo(
        () => [
            columnHelper.accessor("assign_date", {
                header: "Assigned Date",
                id: "assign_date",
                cell: ({ getValue, cell }) => {
                    const assignedDate = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{assignedDate}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("expected_return_date", {
                header: "Return Date",
                id: "expected_return_date",
                cell: ({ getValue, cell }) => {
                    const returnDate = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{returnDate?.date}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("employee", {
                header: "Assigned to",
                id: "employee",
                cell: ({ getValue, cell }) => {
                    const assign_to = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{assign_to?.name}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("responsiblePerson", {
                header: "Responsible Person",
                id: "responsible_person",
                cell: ({ getValue, cell }) => {
                    const responsible_person = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{responsible_person?.name}</p>
                        </div>
                    );
                },
            }),
            columnHelper.display({
                header: "Return Notes",
                id: `actions`,
                // id: `${checkManage ? 'actions' : 'none-actions'}`,
                cell: ({ cell }) => {
                    return (
                        <div
                            className={classNames(
                                styles.actionedBtn,
                                "px-2 flex justify-center items-center "
                                // max-w-[98px] min-w-max
                            )}
                        >
                            <button
                                onClick={() => {
                                    viewLogsData(cell)
                                }}
                                className={classNames(
                                    styles.customViewdBtn,
                                    "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-darkpurple hover:bg-[#EAE0FF] transition-all duration-300"
                                )}
                            >
                                <ViewIcon />
                            </button>
                        </div>
                    );
                },
            }),
        ],
        [
            columnHelper,
            data,
        ]
    );

    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );
    const visibleColumns: { [key: string]: boolean } = {};
    return (
        <div
            id="employeeTable"
            className={classNames(
                styles.employeeTable,
                "relative max-w-full"
            )}
        >
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            
            <ErrorPopup
                descText="Error Message!"
                confirmBtnText="Yes, Close."
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
            />

            <LayoutAdjustableTable
                hideColumn={visibleColumns}
                columns={columns}
                data={data}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                unDraggableFields={["actions", "selection", "id"]}
                theaderColor="text-graydark"
                label="Equipment List"
                pagination={pagination}
                setPagination={setPagination}
            />


        </div>
    );
};
export default EquipmentLogTable;
