import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
  id: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
};
type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  data: DepartmentInfo[];
  total: number;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
type departmentInput = {
  // sort: string;
  // search: string;
};
export type TaskDepartmentList = {
  departmentList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const departmentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDepartmentList: build.query<
    DepartmentsResponse,
      departmentInput
    >({
      query: () => ({
        url: `departments`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getDepartmentAllList: build.query<
    TaskDepartmentList,
      void
    >({
      query: () => ({
        url: `getDepartmentList`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createDepartment: build.mutation<TaskDepartmentList, DropdownDataWithLanguage>({
      query(body) {
        return {
          headers: authHeader(),
          url: `departments`,
          body,
          method: "POST",
        };
      }
    }),
    updateDepartment: build.mutation<TaskDepartmentList, DropdownDataWithLanguage>({
      query(body) {
        return {
          headers: authHeader(),
          url: `departments/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteDepartment: build.mutation<TaskDepartmentList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/departments/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "Departments", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useGetDepartmentListQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useGetDepartmentAllListQuery
} = departmentApi;

export const {
  endpoints: {
    getDepartmentList,
    createDepartment,
    updateDepartment,
    deleteDepartment,
    getDepartmentAllList
  },
} = departmentApi;
