// import { Modal } from "@material-ui/core";
import { CircularProgress, Modal } from "@mui/material"

import classNames from "classnames";
import React, {
    createRef,
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { CloseIcon, SaveIcon, SendIcon } from "../../components/common/Icons";
import { twMerge } from "tailwind-merge";
import { dummyData, IEquipment, IEquipmentLog, IViewEquipment } from "./Equipments";
import EquipmentLogTable from "../../components/EquipmentTable/EquipmentLogTable";
import Button from "../../components/common/Button";
interface ICreateData {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    returnEquipment: any;
    setIsReturn: React.Dispatch<React.SetStateAction<boolean>>;
    isReturn: boolean;
}
const EquipmentReturnPopup: FC<ICreateData> = ({ isOpen, setIsOpen, returnEquipment, setIsReturn, isReturn }) => {
    const [uploadFile, setUploadFile] = useState<string>('');
    const [remark, setRemark] = useState<string>('');
    const equipmentFile = useRef<any>(null);
    const [imgError,setImgError]=useState<string>('');
    const [isLoading,setIsLoading]=useState<boolean>(false);
    const uploadEquipmentFile = () => {
        const file = equipmentFile?.current.files[0];
        if(file){
            if(!file.type.startsWith('image/')){
                setImgError('Only allow image file.')
            }else{
                setImgError('')
            }
        }
        setUploadFile(file?.name)
    }
    const returnEquipmentData = () => {
        setIsReturn(true);
        setIsLoading(true);
        var bodyFormData = new FormData();
        bodyFormData.append('return_proof_photo', equipmentFile.current.files[0]);
        bodyFormData.append('return_remark', remark);
        if(uploadFile && remark){
            returnEquipment(bodyFormData);
        }else{
            setIsLoading(false);
        }
        
    }
    const clearData=()=>{
        setIsLoading(false);
        setRemark('');
        setUploadFile('')
    }
    useEffect(()=>{
        clearData();
    },[isOpen])
    return (
        <Modal
            open={isOpen}
            style={{
                background: "rgb(0 0 0 / 0.3)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            onClose={(event, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                    setIsOpen(false);
                    clearData();
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalContent rounded-xl lg:w-[530px] max-w-[90%] annoucement-modal overflow-y-auto max-h-[90vh] w-full focus-visible:outline-none">
                <div
                    id="modal-modal-title"
                    className="bg-vorpblue relative flex justify-between py-6 px-5 rounded-t-xl"
                >
                    <h3 className="text-18 font-primary font-semibold text-white">
                        Return Equipment
                    </h3>
                    <button
                        className="flex items-center justify-center"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        <CloseIcon />
                    </button>
                </div>

                <div
                    id="modal-modal-description"
                    className="py-9 pl-5 pr-2 bg-white rounded-b-xl"
                >
                    <div className="w-full">
                        <div className="overflow-y-auto max-h-[50vh] custom_scrollbar_popup">
                            <p className="font-semibold lg:text-lg text-sm mb-5 text-black2">Proof of return</p>
                            <div className="pr-3">
                                <div>
                                    <div className="flex items-center">
                                        <div className="cursor-pointer relative">
                                            <input accept="image/*" onChange={uploadEquipmentFile} ref={equipmentFile} type="file" className="absolute opacity-0 cursor-pointer left-0 w-full" />
                                            <button className="px-2 py-2 text-xs text-graydark text-center mr-3 bg-voeee border border-voc2 rounded-4">Choose File</button>
                                        </div>
                                        <span className="font-normal text-xs text-black2">{uploadFile ? uploadFile : "No file chosen"}</span>
                                    </div>
                                    {(isReturn == true && (uploadFile == undefined || uploadFile == '')) ? <span className="text-redPink font-normal text-xs my-1">Proof of photo is required.</span> : (imgError==''?'':<span className="text-redPink font-normal text-xs my-1">{imgError}</span>)}
                                </div>
                                <div className="mt-5">
                                    <label className="text-black2 font-normal body-15" htmlFor="">Notes<span className="text-redPink">*</span></label>
                                    <textarea onChange={(e) => setRemark(e.target.value)}
                                        name="remark"
                                        rows={5} placeholder="Remark" className="md:text-sm text-xs placeholder:text-black2 px-3 py-11 w-full bg-white rounded mt-1 border border-goalinputborder focus-within:outline-none focus:outline-none focus-visible:outline-none resize-none">
                                    </textarea>
                                    {(isReturn == true && (!remark)) ? <span className="text-redPink font-normal text-xs my-1">Remark is required.</span> : ''}
                                </div>
                            </div>
                            <div className="mt-5 last:mb-0 flex items-center">
                                <button onClick={() => returnEquipmentData()}
                                    className={classNames(
                                        isLoading == true ? 'opacity-50 pointer-events-none' : '',
                                        "flex items-center saveButton bg-vorpblue mr-2 px-5 py-[10px] font-poppins font-medium text-center lg:text-sm text-xs text-[#fff] rounded-[6px]"                                        
                                    )}
                                >
                                    <div className="mr-2">
                                        <SendIcon width={16} height={16} />
                                    </div>
                                    {isLoading == true ? <div className="flex items-center justify-center w-full">
                                        <CircularProgress sx={{ color: 'white' }} className="w-5 h-5" style={{ width: '20px', height: '20px' }} /><span className="ml-2">Submit</span>
                                    </div> : "Submit"}

                                </button>
                                <Button
                                    type="button"
                                    label={
                                        <span className="flex items-center text-12 leading-[18px] justify-center">
                                            <div className="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                    <g clip-path="url(#clip0_17372_20468)">
                                                        <path d="M4.23821 9.38673C4.08389 9.38702 3.93575 9.32618 3.82618 9.21751L0.173911 5.60579C0.118815 5.5513 0.0750749 5.48643 0.0452229 5.41492C0.0153709 5.34341 0 5.26668 0 5.18919C0 5.1117 0.0153709 5.03498 0.0452229 4.96347C0.0750749 4.89196 0.118815 4.82708 0.173911 4.77259L3.82618 1.16111C3.93667 1.05183 4.08604 0.99092 4.24144 0.991777C4.39684 0.992634 4.54553 1.05519 4.65481 1.16568C4.76409 1.27617 4.825 1.42554 4.82414 1.58094C4.82328 1.73634 4.76073 1.88503 4.65024 1.99431L1.41915 5.18931L4.65024 8.38431C4.73279 8.466 4.7892 8.57038 4.81231 8.6842C4.83542 8.79802 4.82419 8.91613 4.78004 9.02355C4.73588 9.13097 4.6608 9.22285 4.56433 9.28751C4.46786 9.35217 4.35435 9.38671 4.23821 9.38673Z" fill="#464E5F" />
                                                        <path d="M8.77664 11.0088H7.89844C7.74304 11.0088 7.594 10.9471 7.48412 10.8372C7.37423 10.7273 7.3125 10.5782 7.3125 10.4228C7.3125 10.2674 7.37423 10.1184 7.48412 10.0085C7.594 9.89864 7.74304 9.83691 7.89844 9.83691H8.77664C9.90773 9.83691 10.8281 8.9259 10.8281 7.80605C10.8281 6.68621 9.90773 5.77519 8.77664 5.77519H0.773438C0.618037 5.77519 0.469002 5.71346 0.359117 5.60358C0.249233 5.49369 0.1875 5.34466 0.1875 5.18925C0.1875 5.03385 0.249233 4.88482 0.359117 4.77493C0.469002 4.66505 0.618037 4.60332 0.773438 4.60332H8.77664C10.5539 4.60332 12 6.04004 12 7.80605C12 9.57207 10.5539 11.0088 8.77664 11.0088Z" fill="#464E5F" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_17372_20468">
                                                            <rect width="12" height="12" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            Back
                                        </span>
                                    }
                                    onClick={() => {
                                        setIsOpen(false);
                                        clearData();
                                    }}
                                    variant="no-outline"
                                    size="lg"
                                    customClass="min-w-[112px] min-h-[43.6px] bg-offwhite text-graydark border-none backButton"
                                />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </Modal>
    );
};
export default EquipmentReturnPopup;
