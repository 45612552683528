// import axios from "axios";
// import { useSelector, useDispatch } from "react-redux";
import {
  ColumnDef,
  createColumnHelper,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  // getRoles,
  // useGetRolesQuery,
  useUpdateRoleMutation,
  useAddRoleMutation,
  // addRole,
  useDeleteRoleMutation,
  useMultiDeleteRoleMutation,
  // deleteRole,
  // multiDeleteRole,
} from "../../app/services/roles";
// import {
//   useAddTemplateMutation,
//   useDestoryTemplateMutation,
//   useMultiDeleteTemplateMutation,
//   addTemplate,
//   destoryTemplate,
//   multiDeleteTemplate,
//   getSpecificTemplate,
//   getByModuleTemplate,
//   useGetSpecificTemplateQuery,
//   useGetByModuleTemplateQuery,
// } from "../../app/services/template";
import { templateData } from "../../app/services/template";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { baseObj } from "../../utils/constants";
import {
  CancelIcon,
  EditIcon,
  TableSaveIcon,
  TrashIcon,
} from "../common/Icons";
import TablePaginator from "../Tables/TablePaginator";
import EditableCell from "../Tables/EditableCell";
// import { makeStyles, Tooltip } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

// import { Data } from "../../utils/Data";
// import { current } from "@reduxjs/toolkit";
import DeletePopup from "../../components/DeletePopup/DeletePopup";
// import { getAuthUser } from "../../app/services/dashboard";
// import SuccessPopup from "../SuccessPopup/SuccessPopup";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import classNames from "classnames";
import styles from "../EmployeeTable/EmployeeTable.module.scss";
import DOMPurify from "dompurify";
import { toast, ToastContainer } from "react-toastify";

interface IRolesTable {
  pagination: PaginationState;
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  data: baseObj;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  localModuleId: number;
  templateList: templateData[];
  setHiddenFields: any;
  hiddenFields: string[];
  setCurrentView: any;
  isEditing?: boolean;
  setCols?: any;
  setIsTemplate?: Dispatch<React.SetStateAction<boolean>>;
  total: number;
  changePage: any;
  currentPage: number;
  setCurrentPage: any;
  checkManage: boolean;
  checkUpdate: boolean;
  checkDelete: boolean;
  permissionsList: any;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isCreated?: boolean;
  setIsCreated: Dispatch<SetStateAction<boolean>>;
  addNew: boolean;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

const useStyles: any = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const RolesTable: FC<IRolesTable> = ({
  pagination,
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  globalSearch,
  setGlobalSearch,
  setPagination,
  data,
  setData,
  localModuleId,
  templateList,
  setHiddenFields,
  hiddenFields,
  setCurrentView,
  isEditing,
  setIsEdit,
  setCols,
  total,
  changePage,
  currentPage,
  setCurrentPage,
  setIsTemplate,
  checkManage,
  checkDelete,
  checkUpdate,
  permissionsList,
  setIsCreated,
  addNew,
  sorting,
  setSorting,
}) => {
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [multipleDelete, setMultipleDelete] = useState<boolean>(false);
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definitions when api binding

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectRowsCount = Object.keys(rowSelection).length;
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  // const [itemCount, setItemCount] = useState<number>(total);
  const classes = useStyles();
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  const [addRole] = useAddRoleMutation();
  const [updateRole] = useUpdateRoleMutation();
  const [deleteRoleFun] = useDeleteRoleMutation();
  const [multipleDeleteRoleFun] = useMultiDeleteRoleMutation();
  let savePermissionArr: any = [];

  const comfirmDeleteHandler = useCallback(() => {
    const selectedRows = Object.keys(rowSelection);

    var selectionIds = data
      .filter((x: any, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x : 0;
      })
      .map((x: any) => x.id);
    var $data: any = {
      ids: selectionIds,
    };
    multipleDeleteRoleFun($data)
      .unwrap()
      .then((payload: any) => {
        if (payload.status) {
          setSuccessPopup(true);
          setMessage([payload?.message]);
          setOpenDeleteopup(false);
          setData((prev: baseObj[]) =>
            prev.filter((_, index) => {
              return !selectedRows.includes(String(index));
            })
          );
          toast("Successfully Deleted.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
          setRowSelection({});
        }
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
      });

    // .then((payload) => {
    //   const selectedRows = Object.keys(rowSelection);
    //   setData((prev: baseObj[]) =>
    //     prev.filter((_, index) => {
    //       return !selectedRows.includes(String(index));
    //     })
    //   );
    //   setRowSelection({});
    // })
    // .catch((error) => console.error("rejected", error));
  }, [setData, rowSelection]);

  const deleteUsers = useCallback(
    (id?: number, dataId?: number) => {
      try {
        if (dataId != undefined) {
          deleteRoleFun(dataId)
            .unwrap()
            .then((payload: any) => {
              if (payload.status) {
                setSuccessPopup(true);
                setMessage([payload?.message]);
                setOpenDeleteopup(false);
                setIsSaving(false);

                toast("Successfully Deleted.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  type: "success",
                  theme: "light",
                });
              } else {
                setOpenDeleteopup(false);
                setErrorPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Something went wrong!"]
                );
              }
            })
            .catch((error: any) => {
              setOpenDeleteopup(false);
              setErrorPopup(true);
              setMessage(
                error ? [error?.data?.message] : ["Something went wrong!"]
              );
            });
        }
      } catch (error) {}
    },
    [setData, rowSelection]
  );
  const checkIsValidate = (editedData: any) => {
    var message: any = [];
    if (editedData.name == "" || editedData.name == undefined) {
      message.push("The role field is required");
    }
    // if (editedData.label == "") {
    //   message.push("The label field is required");
    // }
    return message;
  };

  // const saveUser = useCallback(
  //   (id: number, roleId: number) => {
  //     if (roleId == 0) {
  //       setData((prev: baseObj[]) => {
  //         return prev.map((data, index: number) => {
  //           if (index === id) {
  //             var message = checkIsValidate(data);
  //             if (message.length > 0) {
  //               setErrorPopup(true)
  //               setMessage(message);
  //               return { ...data, isNew: true };
  //             }
  //             data.permissions.forEach((role: any) => {
  //               savePermissionArr.push(role.id);
  //             });
  //             const role = {
  //               name: data.name,
  //               permissions: savePermissionArr,
  //               label: data.label,
  //             };
  //             return { ...data, isNew: false };

  //             //   addRole(role)
  //             //     .unwrap()
  //             //     .then((payload: any) => {
  //             //       console.log("p", payload)
  //             //       if (payload.status) {
  //             //         setSuccessPopup(true)
  //             //         setMessage([payload?.message]);
  //             //         return {
  //             //           ...data,
  //             //           isNew: false,
  //             //         };
  //             //       }
  //             //     })
  //             //     .catch((error: any) => {
  //             //       console.log("e", error)
  //             //       setErrorPopup(true)
  //             //       setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
  //             //       return { ...data, isNew: true };
  //             //     })
  //           }
  //           return { ...data, isNew: false };
  //         });
  //       });
  //     } else {
  //       // console.log("updated")
  //       setData((prev: baseObj[]) => {
  //         return prev.map((data, index: number) => {
  //           if (index === id) {
  //             var message = checkIsValidate(data);
  //             if (message.length > 0) {
  //               setErrorPopup(true)
  //               setMessage(message);
  //               return { ...data, isNew: true };
  //             }
  //             data.permissions.forEach((role: any) => {
  //               savePermissionArr.push(role.id);
  //             });
  //             const role = {
  //               id: roleId,
  //               name: data.name,
  //               permissions: savePermissionArr,
  //               label: data.label,
  //               isNew: true,
  //             };
  //             updateRole(role)
  //               .unwrap()
  //               .then((payload: any) => {
  //                 console.log("p", payload)
  //                 if (payload.status) {
  //                   setSuccessPopup(true);
  //                   setMessage([payload?.message]);
  //                 }
  //                 return { ...data, isNew: false };
  //               })
  //               .catch((error: any) => {
  //                 console.log("e", error)
  //                 setErrorPopup(true)
  //                 setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
  //                 return { ...data, isNew: true };

  //               })
  //           }
  //           return data;
  //         });
  //       });

  //       // if (roleId == 0) {
  //       //   console.log("id", id)
  //       //   // create new role
  //       //   setData((prev: baseObj[]) => {
  //       //     return prev.map((data, index: number) => {
  //       //       if (index === id) {
  //       //         // try {
  //       //           console.log("data", data)
  //       //           data.permissions.forEach((role: any) => {
  //       //             savePermissionArr.push(role.id);
  //       //           });
  //       //           const role = {
  //       //             name: data.name,
  //       //             permissions: savePermissionArr,
  //       //             label: data.label,
  //       //           };
  //       //           var message = checkIsValidate(role);
  //       //           if (message.length > 0) {
  //       //             setErrorPopup(true)
  //       //             setMessage(message);
  //       //             return data;
  //       //           }
  //       //           console.log("role ", role);
  //       //           addRole(role)
  //       //             .unwrap()
  //       //             .then((payload: any) => {
  //       //               console.log("p", payload)
  //       //               if (payload.status) {
  //       //                 setSuccessPopup(true)
  //       //                 setMessage([payload?.message]);
  //       //                 return { ...data, isNew: false };
  //       //               }
  //       //             })
  //       //             .catch((error: any) => {
  //       //               console.log("e", error)
  //       //               setErrorPopup(true)
  //       //               setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
  //       //               return { ...data, isNew: true };
  //       //             })
  //       //           // return { ...data, isNew: false };

  //       //           // .unwrap()
  //       //           // .then((payload) => {
  //       //           //   console.log("fulfilled", payload);
  //       //           // })
  //       //           // .catch((error) => console.error("rejected", error));

  //       //         // } catch (error) {
  //       //         //   console.log(error);
  //       //         // }
  //       //       }
  //       //       return data;
  //       //     });
  //       //   });
  //       // } else {
  //       //   // update role
  //       //   setData((prev: baseObj[]) => {
  //       //     return prev.map((data, index: number) => {
  //       //       if (index === id) {
  //       //         var message = checkIsValidate(data);
  //       //         if (message) {
  //       //           alert(message);
  //       //           return data;
  //       //         }
  //       //         try {
  //       //           data.permissions.forEach((role: any) => {
  //       //             savePermissionArr.push(role.id);
  //       //           });
  //       //           const role = {
  //       //             id: roleId,
  //       //             name: data.name,
  //       //             permissions: savePermissionArr,
  //       //             label: data.label,
  //       //             isNew: true,
  //       //           };
  //       //           updateRole(role)
  //       //             .unwrap()
  //       //             .then((payload: any) => {
  //       //               if (payload.status) {
  //       //                 setSuccessPopup(true)
  //       //                 setMessage(payload?.message);
  //       //                 // isNew = false;
  //       //                 // console.log("d", data)
  //       //                 return { ...data, isNew: false };
  //       //               }
  //       //             })
  //       //             .catch((error: any) => {
  //       //               console.log("e", error)
  //       //               setErrorPopup(true)
  //       //               setMessage(error ? error?.data?.message : "Something went wrong!")
  //       //               // isNew = true;
  //       //               return { ...data, isNew: true };
  //       //             })
  //       //           // return { ...data, isNew: false };

  //       //           // .unwrap()
  //       //           // .then((payload) => {
  //       //           //   console.log("fulfilled", payload);
  //       //           // })
  //       //           // .catch((error) => console.error("rejected", error));

  //       //         } catch (error) {
  //       //           console.log(error);
  //       //         }
  //       //       }
  //       //       return data;
  //       //     });
  //       //   });
  //       // }
  //     }
  //   },
  //   [setData]
  // );

  const saveUser = (id: number) => {
    var editedData = data?.find((x: any) => x.id === id);
    if (editedData) {
      if (editedData.id === id) {
        var message: any = checkIsValidate(editedData);
        if (message.length > 0) {
          setIsSaving(false);
          setErrorPopup(true);
          setMessage(message);
          return;
        } else {
          var isNew = true;
          permissionsList.map((p: any) => {
            if (editedData.permissions.find((x: any) => x == p.name)) {
              savePermissionArr.push(p.id);
            }
          });
          // editedData.permissions.forEach((role: any) => {
          // });

          const name = DOMPurify.sanitize(editedData.name);
          const label = DOMPurify.sanitize(editedData.label);

          const role = {
            name,
            label,
            permissions: savePermissionArr,
            id: editedData.id,
          };
          if (editedData.id === 0) {
            setIsSaving(true);
            addRole(role)
              .unwrap()
              .then((payload: any) => {
                // console.log("p", payload)
                if (payload.status) {
                  setSuccessPopup(true);
                  setIsCreated(false);
                  setIsSaving(false);
                  setIsEdit(false);
                  setMessage([payload?.message]);
                  setData((prev: baseObj[]) => {
                    return prev.map((data, index: number) => {
                      if (data.id === id) {
                        return { ...data, isNew: false };
                      }
                      return data;
                    });
                  });
                  toast("Successfully Created.", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: "success",
                    theme: "light",
                  });
                }
              })
              .catch((error: any) => {
                setIsSaving(false);
                setErrorPopup(true);
                setMessage(
                  error ? [error?.data?.message] : ["Something went wrong!"]
                );
              });
          } else {
            setIsSaving(true);
            updateRole(role)
              .unwrap()
              .then((payload: any) => {
                setIsSaving(false);
                if (payload.status) {
                  setSuccessPopup(true);
                  setIsCreated(false);
                  setIsEdit(false);
                  setMessage([payload?.message]);
                  toast("Successfully Updated.", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: "success",
                    theme: "light",
                  });
                  setData((prev: baseObj[]) => {
                    return prev.map((data, index: number) => {
                      if (data.id === id) {
                        return { ...data, isNew: false };
                      }
                      return data;
                    });
                  });
                }
              })
              .catch((error: any) => {
                setIsSaving(false);
                setErrorPopup(true);
                setMessage(
                  error ? [error?.data?.message] : ["Something went wrong!"]
                );
              });
          }
        }
      }
    }
  };

  const editUser = useCallback(
    (id: number, dataId: number) =>
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (index === id) {
            return { ...data, isNew: true };
          }
          return data;
        });
      }),

    [setData]
  );

  const confirmDeleteData = () => {
    var id = deleteRowID;
    var realId = deleteID;
    const selectedRows = Object.keys(rowSelection);

    if (multipleDelete) {
      comfirmDeleteHandler();
    } else {
      deleteUsers(id, realId);
      setDeleteID(-1);
    }
  };

  const destoryRow = useCallback(
    (id?: number, dataId?: number) => {
      setDeleteRowID(id);
      setDeleteID(dataId);
      setMultipleDelete(false);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );

  const MultideleteHandler = () => {
    setOpenDeleteopup(true);
    setMultipleDelete(true);
  };

  const canCeelRow = (id: number) => {
    if (isEditing) {
      if (setIsEdit) {
        setIsEdit(false);
      }
      if (setIsCreated) {
        setIsCreated(false);
      }
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id === id) {
            return { ...data, isEdit: false, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.id !== id);
      setData(filterData);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: `${checkManage ? "selection" : "none-selection"}`,
      }),
      columnHelper.accessor("name", {
        header: "Role",
        id: "name",
        cell: ({ getValue, cell }) => {
          const role = getValue();
          return (
            <div className="min-w-full">
              <EditableCell
                maxLength={25}
                setData={setData}
                value={role}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      // columnHelper.accessor("label", {
      //   header: "Label",
      //   id: "label",
      //   cell: ({ getValue, cell }) => {
      //     const label = getValue();
      //     return (
      //       <div className="min-w-[200px] whitespace-pre-wrap">
      //         <EditableCell
      //           maxLength={40}
      //           setData={setData}
      //           value={label}
      //           type="text"
      //           cell={cell}
      //           // customDesign="tooltip"
      //         />
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.accessor("permissions", {
        header: "Permissions",
        id: "permissions",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const data = getValue();
          const isNew: boolean = cell.row.original?.isNew;
          // var tLength = [...data]
          //   .splice(0, 1)
          //   .join(", ")
          //   .substring(0, 18).length;

          return (
            <div className="min-w-full overflow-hidden text-left flex text-14 leading-[21px] bg-white px-2 text-black2">
              {data?.length == 0 ? (
                <span className="text-black2  flex justify-center items-center">
                  ----
                </span>
              ) : (
                <span className="text-black2 flex items-center">
                  {data.length>=5?[...data].slice(0,5).join(", "):data.join(', ')}
                </span>
              )}
              {data.length > 1 && (
                <>
                  <Tooltip
                    arrow
                    className="customTooltip"
                    classes={{ tooltip: classes.tooltip }}
                    enterTouchDelay={1000}
                    leaveTouchDelay={5000}
                    placement="left"
                    title={
                      <>
                        <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                          {[...data]
                            .splice(0, data.length)
                            .map((role, index: number) => (
                              <span className="block" key={index}>
                                {role}
                              </span>
                            ))}
                        </div>
                      </>
                    }
                  >
                    <span className="font-semibold flex items-center ml1">
                      + &nbsp;{data.length - 1}
                    </span>
                  </Tooltip>
                  <div className="relative hover:cursor-pointer pl-1"></div>
                </>
              )}
            </div>
          );
        },
      }),
      // columnHelper.display({
      //   header: "Action",
      //   id: `${(data?.find((x:any) => x.isNew == true) && addNew) || checkUpdate || checkDelete? "actions1": "none-actions"}`,
      // })
      columnHelper.display({
        header: "Action",
        id: `${(data?.find((x:any) => x.isNew == true) && addNew) || checkUpdate || checkDelete
          ? "actions1"
          : "none-actions"
          }`,
        cell: ({ cell }) => {
          var isAdminAcc = cell.row.original?.actionDisable;
          console.log("checkUpdate ",checkUpdate,checkDelete)
          const isNew: boolean = cell.row.original?.isNew
            ? cell.row.original?.isNew
            : false;
          const dataId = cell.row.original?.id;
          var isHaveNew = data?.find((x: any) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          return (
            <div
              className={classNames(
                isAdminAcc ? "pointer-events-none opacity-50 bg-[#F5F8FA]" : "",
                "flex justify-center items-center "
              )}
            >
              {isNew ? (
                <>
                  <button
                    disabled={isSaving}
                    type="button"
                    onClick={() => {
                      setIsSaving(true);
                      saveUser(Number(cell?.row?.original?.id));
                    }}
                    className={classNames(
                      isSaving ? "opacity-50 pointer-events-none" : "",
                      "w-6 h-6 flex justify-center items-center bg-vorpgreen rounded-md"
                    )}
                  >
                    <TableSaveIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      canCeelRow(Number(cell?.row?.original?.id));
                      if (setIsEdit) {
                        setIsEdit(true);
                      }
                      setIsCreated(false);
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={18} height={18} />
                  </button>
                </>
              ) : (
                <>
                  {checkUpdate ? (
                    <button
                      type="button"
                      onClick={() => {
                        editUser(Number(cell.row.id), dataId);
                        if (setIsEdit) {
                          setIsEdit(true);
                        }
                        if (setIsCreated) {
                          setIsCreated(false);
                        }
                      }}
                      className={classNames(
                        disabledClass,
                        "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                      )}
                    >
                      <EditIcon />
                    </button>
                  ) : (
                    ""
                  )}
                  {checkDelete ? (
                    <button
                      type="button"
                      onClick={() => {
                        destoryRow(cell.row.index, dataId);
                      }}
                      className={classNames(
                        disabledClass,
                        "w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
                      )}
                    >
                      <TrashIcon />
                    </button>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, isSaving]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, unknown>) => column.id as string)
  );
  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  if (checkManage) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);

  return (
    <div
      className={classNames(
        styles.roleTable,
        "text-primary text-left relative"
      )}
    >
      {/* <SuccessPopup
        descText="Done Successfully!"
        confirmBtnText="Yes, Close."
        show={successPopup}
        setShow={setSuccessPopup}
        message={message}
        setMessage={setMessage}
      /> */}
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <DeletePopup
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
        descText="Are you sure to delete?"
      />

      <div className="relative max-w-full w-full">
        <LayoutAdjustableTable
          columns={columns}
          data={data}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["actions", "selection"]}
          theaderColor="text-graydark"
          isLayoutControlOpen={isLayoutControlOpen}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          globalFilter={globalSearch}
          setGlobalFilter={setGlobalSearch}
          label="Roles"
          pagination={pagination}
          setPagination={setPagination}
          setItemCount={setItemCount}
          localModuleId={localModuleId}
          hideColumn={visibleColumns}
          noPaddingCells={["roles"]}
          setCols={setCols}
          setIsTemplate={setIsTemplate}
        />
      </div>
      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-8 mb-10 lg:mt-10 lg:mb-12 ">
          <p>{selectRowsCount} selected.</p>
          {checkDelete ? (
            <button
              type="button"
              onClick={() => MultideleteHandler()}
              className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
            >
              <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-redPinkLight transition-all duration-300 ">
                <TrashIcon />
              </div>
              Delete
            </button>
          ) : null}
        </div>
      )}
      <div className=" pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          changePage={changePage}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
        />
      </div>
    </div>
  );
};

export default RolesTable;
