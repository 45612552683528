import { useState } from "react";
import { StringDropdownList } from "../common/DropdownList";
const languages=[
    {
        id:1,
        name:'English',
        code:'en'
    },
    {
        id:2,
        name:'Traditonal Chinese',
        code:'tc'
    },
    {
        id:3,
        name:'Simplified Chinese',
        code:'sc'
    }
]
const Language = () => {
  const [language, setLanguage] = useState<string>("");
  return (
    <div className="min-h-[87vh] px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <p className="text-left lg:text-24 text-lg text-[#464E5F]">
          System Language
        </p>
        <div className="mt-10 lg:w-1/2 w-full text-left">
          <label className="text-black2 font-normal body-15 text-left" htmlFor="">
          Language Selection
          </label>
          <div className="mt-1">
            <StringDropdownList
              state={language}
              setState={(value)=>{
                setLanguage(value);
                window.localStorage.setItem('language',value.toString())
              }}
              placeholder="Language"
              className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
              dropdownItems={languages?.map((language: any) => ({
                value: language.code,
                label: language.name,
              }))}
              textAlign={"text-left"}
              fontSize="md:text-sm text-xs"
              border="border-goalinputborder"
              color="text-black2"
              paddingLi="py-2"
            />
          </div>
        </div>
      </div>
  );
};
export default Language;
