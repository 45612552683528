import React, {
  // Component,
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useRef,
  useEffect,
  // useCallback,
} from "react";
import { Modal, Fade, CircularProgress } from "@mui/material";

import "tinymce/skins/ui/oxide/skin.min.css";
import { Editor } from "@tinymce/tinymce-react";
import CustomDatePicker from "../../components/common/FullWidthDatePicker";

import { StringDropdownList } from "../../components/common/DropdownList";
// import { tagTypeArr, tagTypes } from "../../utils/announcementDatas";
// import { StringMultipleSelectBox } from "../common/MultipleSelectBox";
import whiteCancel from "../common/img/white-cancel.svg";
import blackCancel from "../common/img/black-cancel.svg";
import saveIcon from "./img/submit-icon.svg";
import { useGetTagsDataCustomQuery } from "../../app/services/annoucement-sheet";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import DOMPurify from "dompurify";
import classNames from "classnames";
import { renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
import { TaskCategoriesList } from "../../app/services/category";
import CommonLanguage from "../common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import {
  ClassicEditor,
  Context,
  InlineEditor,
  ContextWatchdog,
  Autoformat,
  Bold,
  Italic,
  BlockQuote,
  Base64UploadAdapter,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  EditorConfig,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  Link,
  List,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableToolbar,
  TextTransformation,
} from "ckeditor5";
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

import 'ckeditor5/ckeditor5.css';

export const typeList = ["Submission", "Upcoming", "Assigned","New"];

interface IModalAnnounEditor {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  valueChangeCallback: (value: any, id: string) => void
  totalLength: number;
  loggedUser: string;
  createTagData?: (label: string) => void;
  saving?: boolean;
  setSaving?: Dispatch<SetStateAction<boolean>>;
  departments: any;
  categories: any;
  employeeList: any;
  editRowData:any;
}

// interface IFilterStates {
//   tag: string;
// }
const ModalAnnounEditor: FC<IModalAnnounEditor> = ({
  isOpen,
  setIsOpen,
  valueChangeCallback,
  totalLength,
  loggedUser,
  createTagData,
  saving,
  setSaving,
  departments,
  categories,
  employeeList,
  editRowData
}) => {
  const tags = categories?.map((tag: any, idx: any) => ({
    label: tag.label,
    value: tag.value,
  }));
  // .filter((tag: any) => tag.label.toLowerCase() !== "all");

  const editorRef_en: any = useRef(null);
  const editorRef_tc: any = useRef(null);
  const editorRef_sc: any = useRef(null);
  const [goalTitle_en, setGoalTitle_en] = useState("");
  const [goalTitle_tc, setGoalTitle_tc] = useState("");
  const [goalTitle_sc, setGoalTitle_sc] = useState("");
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [postedDate, setPostedDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState<Date>();
  const [myGoal_en, setMyGoal_en] = useState("");
  const [myGoal_tc, setMyGoal_tc] = useState("");
  const [myGoal_sc, setMyGoal_sc] = useState("");
  // const [loading, setLoading] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [contentError, setContentError] = useState("");
  const [expireError, setExpireError] = useState("");
  const [tagError, setTagError] = useState("");
  const [typeError,setTypeError]=useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [employeeError, setEmployeeError] = useState("");
  const [typeData, setTypeData] = useState(typeList[0]);
  const [id, setID] = useState<string>('');
  const [selectedCategoryData, setSelectedCategoryData] = useState<string[]>(
    []
  );
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedDepartmentsData, setSelectedDepartmentsData] = useState<
    string[]
  >([]);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedEmployeesData, setSelectedEmployeesData] = useState<string[]>(
    []
  );
  const [activeLang, setActiveLang] = useState<string>("en");
  const [employees, setEmployees] = useState<any>(employeeList);
  // const [typeError, setTypeError] = useState("");
  const goalTitleHandler = (event: any, language: string) => {
    if (language == "en") setGoalTitle_en(event.target.value);
    if (language == "tc") setGoalTitle_tc(event.target.value);
    if (language == "sc") setGoalTitle_sc(event.target.value);
  };
  useEffect(() => {    
    if (editRowData) {
      setID(editRowData.id);
      setGoalTitle_en(editRowData.title_en);
      setGoalTitle_tc(editRowData.title_tc);
      setGoalTitle_sc(editRowData.title_sc);
      setMyGoal_en(editRowData.content_en);
      setMyGoal_tc(editRowData.content_tc);
      setMyGoal_sc(editRowData.content_sc);
      setTypeData(editRowData.type);;
      setChosenTags(editRowData?.categories?.map((cat: any) => cat.name_en));
      setSelectedCategoryData(
        editRowData?.categories?.map((cat: any) => {
          return {
            id: cat.id,
            name: cat.name_en,
          };
        })
      );
      setSelectedDepartments(
        editRowData?.departments?.map((cat: any) => cat.name_en)
      );
      setSelectedDepartmentsData(
        editRowData?.departments?.map((cat: any) => {
          return {
            id: cat.id,
            name: cat.name_en,
          };
        })
      );
      const filterEmp = [
        ...employeeList?.filter((emp: any) => {
          if (
            editRowData?.departments?.find((x: any) => x.id == emp.department_id)
          ) {
            return emp;
          }
        }),
      ];
      if(editRowData?.employees){
        if (editRowData?.employees.length == filterEmp.length) {
          setSelectedEmployees(["All"]);
          setSelectedEmployeesData(employeeList?.filter((x:any)=>x.id=='all'));
          
        } else {
          setSelectedEmployees(
            editRowData?.employees?.map((cat: any) => cat.name)
          );
          setSelectedEmployeesData(
            editRowData?.employees?.map((cat: any) => {
              return {
                id: cat.id,
                name: cat.name_en,
              };
            })
          );
        }
      }
      setEmployees([{ id: "all", name: "All" }, ...filterEmp]);
    }
  }, [editRowData]);
  const handleEditorChange = (event: any,editor:any) => {
    if (activeLang == "en") setMyGoal_en(editor.getData());
    if (activeLang == "tc") setMyGoal_tc(editor.getData());
    if (activeLang == "sc") setMyGoal_sc(editor.getData());
  };
  useEffect(() => {
    if (employeeList) {
      setEmployees(employeeList);
    }
    if (selectedDepartmentsData) {
      setEmployees([{id:'all',name:'All'},
        ...employeeList?.filter((emp: any) => {
          if (
            selectedDepartmentsData.find((x: any) => x.id == emp.department_id)
          ) {
            return emp;
          }
        })]
      );
    }
  }, [employeeList, selectedDepartmentsData]);


  const testingHandler = (event: any) => {
    if (setSaving) {
      setSaving(true);
    }
    if (goalTitle_en === "" || goalTitle_tc === "" || goalTitle_sc === "") {
      setTitleError("Title field is required");
      if (setSaving) {
        setSaving(false);
      }
      return;
    } else {
      setTitleError("");
    }
    if (expiryDate === null) {
      setExpiryDate(new Date());
      //setExpireError("ExpireData field is required");
      return;
    }
    if (myGoal_en === "" || myGoal_en === null || myGoal_tc === "" || myGoal_tc === null || myGoal_sc === "" || myGoal_sc === null) {
      if (setSaving) {
        setSaving(false);
      }
      setContentError("Content field is required");
      return;
    } else {
      setContentError("");
    }
    if(typeData=='' || typeData==null){
      if (setSaving) {
        setSaving(false);
      }
      setTypeError('Type is required');
      return;
    }
    if(selectedDepartments.length<=0){
      if (setSaving) {
        setSaving(false);
      }
      setDepartmentError('Department is required');
      return;
    }
    else {
      setDepartmentError("");
    }
    if(selectedEmployees.length<=0){
      if (setSaving) {
        setSaving(false);
      }
      setEmployeeError('Employee is required');
      return;
    }
    else {
      setEmployeeError("");
    }
    setTitleError("");
    setExpireError("");
    setContentError("");
    setTagError("");
    const expiry = DOMPurify.sanitize(expiryDate?.toString() ?? "");
    const posted = postedDate ? renderDateFormatYMD(postedDate, "-") : "";
    const log = DOMPurify.sanitize(loggedUser);

    var submitData = {
      title_en:goalTitle_en,
      title_tc:goalTitle_tc,
      title_sc:goalTitle_sc,
      tag: chosenTags,
      expiryDate: expiry,
      postedDate: posted,
      content_en: myGoal_en,
      content_tc: myGoal_tc,
      content_sc: myGoal_sc,
      postedBy: log,
      updatedBy: log,
      typeData: typeData,
      categories: selectedCategoryData?.map((x: any) => x.id).join(","),
      employees: selectedEmployeesData.find((x:any)=>x.name=='All')?'all':selectedEmployeesData?.map((x: any) => x.id).join(","),
      departments: selectedDepartmentsData?.map((x: any) => x.id).join(","),
    };

    valueChangeCallback(submitData,id);
  };
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  // useEffect(() => {
  //   if (editorRef_en.current) {
  //     setMyGoal_en(editorRef_en.current.getContent());
  //   }
  //   if (editorRef_tc.current) {
  //     setMyGoal_tc(editorRef_tc.current.getContent());
  //   }
  //   if (editorRef_sc.current) {
  //     setMyGoal_sc(editorRef_sc.current.getContent());
  //   }
  // }, [editorRef_en,editorRef_tc,editorRef_sc]);

  useEffect(() => {
    if (isOpen === false) {
      setGoalTitle_en("");
      setGoalTitle_tc("");
      setGoalTitle_sc("");
      setChosenTags([]);
      setPostedDate(new Date());
      setExpiryDate(new Date());
      setMyGoal_en("");
      setMyGoal_tc("");
      setMyGoal_sc("");      
      setSelectedEmployees([]);
      setSelectedEmployeesData([]);
      setSelectedDepartments([]);
      setSelectedDepartmentsData([]);
      setTypeData('');
      setActiveLang('en')
      if(setSaving)
      setSaving(false);
    }
  }, [isOpen, saving]);

  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          // Set 'open' to false, however you would do that with your particular code.
          setIsOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
      <Fade in={isOpen} timeout={500}>
        <div className="modalContent lg:w-[1000px] max-w-[90%] annoucement-modal overflow-y-auto max-h-[100%] w-full">
          <div
            id="modal-modal-title"
            className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
          >
            <h3 className="text-18 font-primary font-semibold text-white">
              Announcement
            </h3>
            <button
              className="flex items-center justify-center"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src={whiteCancel} alt="close modal icon" />
            </button>
          </div>

          <div
            id="modal-modal-description"
            className="pb-9 px-5 bg-white rounded-b-xl"
          >
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
            <div className="md:flex pt-3">
              <div className="md:w-1/2 w-full sm:pl-5">
                <div className={twJoin(activeLang == "en" ? "" : "hidden")}>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Title
                    </label>
                    <input
                      type="text"
                      value={goalTitle_en}
                      onChange={(event)=>goalTitleHandler(event,'en')}
                      placeholder="Title"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        titleError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {titleError ? titleError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0 h-full">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Content
                    </label>
                    <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                      >
                        <CKEditor
                        ref={editorRef_sc}
                          editor={ClassicEditor}
                          config={{
                            plugins: [
                              Essentials,
                              Autoformat,
                              Bold,
                              Italic,
                              BlockQuote,
                              CKFinder,
                              CKFinderUploadAdapter,
                              CloudServices,
                              Heading,
                              Image,
                              ImageCaption,
                              ImageStyle,
                              ImageToolbar,
                              ImageUpload,
                              Base64UploadAdapter,
                              Indent,
                              Link,
                              List,
                              Mention,
                              Paragraph,
                              PasteFromOffice,
                              PictureEditing,
                              Table,
                              TableToolbar,
                              TextTransformation,
                            ],
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "uploadImage",
                              "insertTable",
                              "blockQuote",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "outdent",
                              "indent",
                            ],
                          }}
                          data={myGoal_en ? myGoal_en : "<p></p>"}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor 1 is ready to use!", editor);
                          }}
                          onBlur={handleEditorChange}
                        />
                      </CKEditorContext>
                    <p
                      className={classNames(
                        contentError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {contentError ? contentError : ""}
                    </p>
                  </div>
                </div>
                <div className={twJoin(activeLang == "tc" ? "" : "hidden")}>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Title
                    </label>
                    <input
                      type="text"
                      value={goalTitle_tc}
                      onChange={(event)=>goalTitleHandler(event,'tc')}
                      placeholder="Title"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        titleError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {titleError ? titleError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0 h-full">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Content
                    </label>
                    <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                      >
                        <CKEditor
                        ref={editorRef_sc}
                          editor={ClassicEditor}
                          config={{
                            plugins: [
                              Essentials,
                              Autoformat,
                              Bold,
                              Italic,
                              BlockQuote,
                              CKFinder,
                              CKFinderUploadAdapter,
                              CloudServices,
                              Heading,
                              Image,
                              ImageCaption,
                              ImageStyle,
                              ImageToolbar,
                              ImageUpload,
                              Base64UploadAdapter,
                              Indent,
                              Link,
                              List,
                              Mention,
                              Paragraph,
                              PasteFromOffice,
                              PictureEditing,
                              Table,
                              TableToolbar,
                              TextTransformation,
                            ],
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "uploadImage",
                              "insertTable",
                              "blockQuote",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "outdent",
                              "indent",
                            ],
                          }}
                          data={myGoal_tc ? myGoal_tc : "<p></p>"}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor 1 is ready to use!", editor);
                          }}
                          onBlur={handleEditorChange}
                        />
                      </CKEditorContext>
                    <p
                      className={classNames(
                        contentError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {contentError ? contentError : ""}
                    </p>
                  </div>
                </div>
                <div className={twJoin(activeLang == "sc" ? "" : "hidden")}>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Title
                    </label>
                    <input
                      type="text"
                      value={goalTitle_sc}
                      onChange={(event)=>goalTitleHandler(event,'sc')}
                      placeholder="Title"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                    <p
                      className={classNames(
                        titleError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {titleError ? titleError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0 h-full">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Content
                    </label>
                    <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                      >
                        <CKEditor
                        ref={editorRef_sc}
                          editor={ClassicEditor}
                          config={{
                            plugins: [
                              Essentials,
                              Autoformat,
                              Bold,
                              Italic,
                              BlockQuote,
                              CKFinder,
                              CKFinderUploadAdapter,
                              CloudServices,
                              Heading,
                              Image,
                              ImageCaption,
                              ImageStyle,
                              ImageToolbar,
                              ImageUpload,
                              Base64UploadAdapter,
                              Indent,
                              Link,
                              List,
                              Mention,
                              Paragraph,
                              PasteFromOffice,
                              PictureEditing,
                              Table,
                              TableToolbar,
                              TextTransformation,
                            ],
                            toolbar: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "uploadImage",
                              "insertTable",
                              "blockQuote",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "outdent",
                              "indent",
                            ],
                          }}
                          data={myGoal_sc ? myGoal_sc : "<p></p>"}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor 1 is ready to use!", editor);
                          }}
                          onBlur={handleEditorChange}
                        />
                      </CKEditorContext>
                    <p
                      className={classNames(
                        contentError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {contentError ? contentError : ""}
                    </p>
                  </div>
                </div>
                
              </div>
              <div className="md:w-1/2 w-full sm:px-5">
              <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Type
                  </label>
                  <StringDropdownList
                    state={typeData}
                    setState={setTypeData}
                    dropdownItems={typeList?.map((x: string) => {
                      return {
                        label: x,
                        value: x,
                      };
                    })}
                    className="w-full text-14 py-[13px] border-goalinputborder"
                    placeholder="All"
                    textAlign="text-left  text-14 py-2 pl-2 pr-3"
                  />
                  <p className={classNames(typeError != "" ? '' : 'hidden', "mt-2 font-poppins text-12 text-redPink")}>
                    {typeError ? typeError : ''}
                  </p> 
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Expiry Date
                  </label>
                  <div className="w-full">
                    <CustomDatePicker
                      placeholder={new Date().toDateString()}
                      date={expiryDate}
                      minData={new Date()}
                      setDate={(value) => setExpiryDate(value)}
                    />
                  </div>
                  <p
                    className={classNames(
                      expireError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {expireError ? expireError : ""}
                  </p>
                </div>
                {/* <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Category
                  </label>

                  <CommonTagDropdown
                    placeholder="Category"
                    createData={createTagData}
                    showAll={true}
                    isCreate={false}
                    setSelected={setSelectedCategoryData}
                    state={chosenTags}
                    setState={setChosenTags}
                    options={tags ? tags : []}
                    className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                  />
                  <p
                    className={classNames(
                      tagError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {tagError ? tagError : ""}
                  </p>
                </div> */}
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Department
                  </label>

                  <CommonTagDropdown
                    placeholder="Select Department"
                    createData={createTagData}
                    showAll={true}
                    isObject={true}
                    isCreate={false}
                    state={selectedDepartments}
                    setState={setSelectedDepartments}
                    setSelected={setSelectedDepartmentsData}
                    options={
                      departments
                        ? departments?.map((dept: any) => {
                            return {
                              value: dept.id,
                              label: dept.name_en,
                            };
                          })
                        : []
                    }
                    className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                  />
                  <p
                    className={classNames(
                      departmentError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {departmentError ? departmentError : ""}
                  </p>
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Employee
                  </label>

                  <CommonTagDropdown
                    placeholder="Select Employee"
                    createData={createTagData}
                    isCreate={false}
                    showAll={true}
                    isObject={true}
                    state={selectedEmployees}
                    setState={setSelectedEmployees}
                    setSelected={setSelectedEmployeesData}
                    options={
                      employees
                        ? employees?.map((emp: any) => {
                            return {
                              value: emp.id,
                              label: emp.name,
                            };
                          })
                        : []
                    }
                    className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                  />
                  <p
                    className={classNames(
                      employeeError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {employeeError ? employeeError : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 last:mb-0 flex items-center sm:px-5">
              <button
                className={classNames(
                  saving ? "opacity-50 pointer-events-none" : "",
                  "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={testingHandler}
              >
                {saving == true ? (
                  <div className="flex items-center justify-center w-full">
                    <CircularProgress
                      sx={{ color: "white" }}
                      className="w-5 h-5"
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span className="ml-2">Saving</span>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <img src={saveIcon} alt="save icon" className="mr-3"></img>
                    {editRowData?.id?'Update':'Save'}
                  </div>
                )}
              </button>
              <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <img src={blackCancel} alt="black icon" className="mr-3"></img>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalAnnounEditor;
