import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type FaqData = {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  description_en: string;
  description_tc: string;
  description_sc: string;
  type: string;
};
type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  categoryTableData: TData;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
export type PaginationData = {
  page: number;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export type TaskCategoriesList = {
  categoryList: TaskDepartmentListData[];
};
export type FAQData = {
  faqTableData: TData;
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const faqApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFAQData: build.query<FAQData, PaginationData>({
      query: ({ limit, page, search }) => ({
        url: `faqs?limit=${limit}&page=${page}&search=${search}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createFAQ: build.mutation<FAQData, FaqData>({
      query(body) {
        return {
          headers: authHeader(),
          url: `faqs`,
          body,
          method: "POST",
        };
      },
    }),
    updateFAQ: build.mutation<TaskCategoriesList, FaqData>({
      query(body) {
        return {
          headers: authHeader(),
          url: `faqs/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteFAQ: build.mutation<TaskCategoriesList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/faqs/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "FAQ", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useGetFAQDataQuery,
  useCreateFAQMutation,
  useUpdateFAQMutation,
  useDeleteFAQMutation,
} = faqApi;

export const {
  endpoints: { getFAQData, createFAQ, updateFAQ, deleteFAQ },
} = faqApi;
