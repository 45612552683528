import { FC, useEffect, useMemo, useState, useCallback } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import { checkedIsNew } from "../../app/services/employee";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useSpring, animated } from "react-spring";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import CreatePopupOnlyName from "../../components/ProjectStatusPopup/CreatePopupOnlyName";
import { getLanguageCode } from "../../app/services/api";
import CategoryTable from "../../components/CategoryTable/CategoryTable";
import CreatePopupNameDescription from "../../components/ProjectStatusPopup/CreatePopupNameDescription";
import { useCreateFAQMutation, useDeleteFAQMutation, useGetFAQDataQuery, useUpdateFAQMutation } from "../../app/services/faq";
import { useGetAllFAQTypeDataQuery } from "../../app/services/faqType";
import FQATable from "../../components/FQATable/FQATable";

interface IOT { }

export interface IDepartment {
  id: number;
  name: string;
  label: string;
}

// const defaultFields = ["name", "order", "actions"];

export type TStatusListData = {
  id: number | string;
  name: string;
  label?: string;
};

const FAQ: FC<IOT> = () => {
  const languageCode=getLanguageCode();
  const [editedData, setEditData] = useState<any>("");
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [message, setMessage] = useState<any>([]);
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [data, setData] = useState<any[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [faq_types,setFaqTypes]=useState<any>([]);
  const [error,setError]=useState<string>('');
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(false);
  var disabledData = checkedIsNew(data ? data : []);
  const {data:faqTypes}=useGetAllFAQTypeDataQuery();
  const { data: faqs } = useGetFAQDataQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    // sort: sorting.map((s) => `${s.desc ? "-" : ""}${s.id}`).join(","),
  });
  const [deleteFaqData] = useDeleteFAQMutation();
  const [saveFAQData] = useCreateFAQMutation();
  const [updateFAQData] = useUpdateFAQMutation();

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  const changePage = (pageNo: number) => { };
  useEffect(()=>{
    if(faqTypes){
      setFaqTypes(faqTypes?.typeList);
    }
  },[faqTypes])
  useEffect(() => {
    if (faqs) {      
      setData(faqs?.faqTableData?.data);
      setItemCount(faqs?.faqTableData?.total)
    }
  }, [faqs]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pagination.pageSize, globalSearch]);

  const createFAQ = (data: any) => {
    const saveData = {
      title_en: data?.title_en,
      title_tc: data?.title_tc,
      title_sc: data?.title_sc,
      description_en:data?.description_en,
      description_tc:data?.description_tc,
      description_sc:data?.description_sc,
      type:data?.type,
      id:0
    };
    console.log("saveData ",saveData)
    saveFAQData(saveData)
      .unwrap()
      .then((payload: any) => {
        setEditData("");
        toast("Successfully Saved.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setIsSubmit(false);
        setUploading(false);
        setData((prev: any) => [payload.faq,...prev])
        setIsOpenPopup(false);

      })
      .catch((error: any) => {
        setError(error?.data?.message);
        setIsSubmit(false);
        setUploading(false);
        setIsOpenPopup(false);
      });
  }
  const updateFAQ = (data: any) => {
    const saveData = {
      title_en: data?.title_en,
      title_tc: data?.title_tc,
      title_sc: data?.title_sc,
      description_en:data?.description_en,
      description_tc:data?.description_tc,
      description_sc:data?.description_sc,
      type:data?.type,
      id: editedData?.id
    };
    updateFAQData(saveData)
      .unwrap()
      .then((payload: any) => {
        setEditData("");
        toast("Successfully Saved.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setIsSubmit(false);
        setUploading(false);
        setData(prev => prev.map(item => {
          if (item.id === editedData.id) {
            return { ...item, 
              name_en: payload?.faq?.name_en,
              name_tc: payload?.faq?.name_tc,
              name_sc: payload?.faq?.name_sc,
              description_en:payload?.faq?.description_en,
              description_tc:payload?.faq?.description_tc,
              description_sc:payload?.faq?.description_sc,
              type_id:payload?.faq?.type_id,
              types:payload?.faq?.types
             };
          } else {
            return item;
          }
        }));
        setIsOpenPopup(false);
      })
      .catch((error: any) => {
        setError(error?.data?.message);
        setIsSubmit(false);
        setUploading(false);
        setIsOpenPopup(false);
      });
  }
  useEffect(() => {
    setItemCount(data.length);
  }, [data])
  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
      />
      <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {(setState) => (
                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    id="message"
                    autoComplete="off"
                    name="search-off"
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={(e) => setState(e.target.value)}
                  />
                  <SearchIcon className={classNames("mr-2")} />
                </div>
              )}
            </DebounceSearchInput>
          </div>
          <div className="flex w-full sm:w-auto items-center justify-between md:justify-end lg:justify-end">
            <div className="">
              <Button
                disabled={disabledData?.disabled}
                label={<span className="flex items-center">+ Add New</span>}
                onClick={() => {
                  setIsSuccess(false);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                  setEditData(null);
                  setIsOpenPopup(true);                  
                }}
                type="button"
                variant="primary"
                customClass={
                  disabledData?.disabledClass +
                  " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                }
                size="base"
              />
            </div>
          </div>
        </div>

        <animated.div style={props}>
          <FQATable
            data={data}
            setData={setData}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            pagination={pagination}
            setPagination={setPagination}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setCurrentView={setCurrentView}
            setIsEdit={setIsEdit}
            isEditing={isEditing}
            localModuleId={localModuleId}
            total={itemCount}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsTemplate={setIsTemplate}
            setCols={setCols}
            visArray={visArray}
            setSkip={setSkip}
            sorting={sorting}
            setSorting={setSorting}
            deleteCategory={deleteFaqData}
            setEditData={setEditData}
            setIsOpenPopup={setIsOpenPopup}
          />
        </animated.div>
        <ToastContainer className={"text-12 font-poppins font-normal"} />
      </div>
      <CreatePopupNameDescription
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        editedData={editedData}
        setEditData={setEditData}
        savePopupData={createFAQ}
        updatePopupData={updateFAQ}
        title="FAQ"
        isSubmit={isSubmit}
        setIsSubmit={setIsSubmit}
        uploading={uploading}
        setUploading={setUploading}
        listData={faq_types}
      />
      <CommonErrorPopup
        show={error != "" ? true : false}
        setShow={() => setError("")}
        message={error}
      />
    </div>
  );
};
export default FAQ;
