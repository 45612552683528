import { FC, useEffect, useState, useMemo } from "react";
import BackendUsersTable from "../../components/BackendUsers/BackendUsersTable";
import Button from "../../components/common/Button";
import {
  AddIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { addNewUser, baseObj } from "../../utils/constants";
import { useGetAllUsersQuery } from "../../app/services/userlist";
import { useGetRoleListQuery } from "../../app/services/roles";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import Styles from "./UserList.module.scss";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { getAuthUser } from "../../app/services/dashboard";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import { checkedIsNew } from "../../app/services/employee";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";

interface IUserList {}


export interface IRole {
  value: number;
  label: string;
  id?: number;

  name?: string;
  department_id?: number;
  department_name?: string;
}

const UserList: FC<IUserList> = () => {
  const navigate = useNavigate();
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [role, setRole] = useState<string>("All");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [itemCount, setItemCount] = useState<number>(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const defaultFields = useMemo(
    () => [
      "name",
      "roles",
      "email",
      "enable",
      "createdBy",
      "activated",
      "actions",
    ],
    []
  );

  const {
    data: User,
    isLoading: userLoading,
    error,
    isFetching,
  } = useGetAllUsersQuery({
    search: globalSearch,
    role: role,
    page: currentPage,
    limit: limit,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${
            s.id == "roles"
              ? "role"
              : s.id == "createdBy"
              ? "created_user"
              : s.id
          }`
      )
      .join(","),
  });

  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [data, setData] = useState<baseObj[]>([]);
  const [currentView, setCurrentView] = useState<any>(0);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [tempList, setTempList] = useState<any>(templateList);
  const { data: rTData } = useGetRoleListQuery();
  let [localModuleId, setLocalModuleId] = useState<number>(0);

  const { data: templateData, isLoading: tempLoading } =
    useGetByModuleTemplateQuery(localModuleId);
  const [viewCols, setCols] = useState<any>([]);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);

  const [moduleList, setModuleList] = useState<any>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  // const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("---");
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  const [addNew, setAddNew] = useState<boolean>(false);
  const [updatePermission, setUpdatePermission] = useState<boolean>(false);
  const [deletePermission, setDeletePermission] = useState<boolean>(false);
  const [checkExport, setCheckExport] = useState<boolean>(false);
  const [checkImport, setCheckImport] = useState<boolean>(false);
  const [checkManage, setManage] = useState<boolean>(false);

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    const filterCreate = getAuthPermis.filter((f) => f === "Create_User");
    const editRole = getAuthPermis.filter((f) => f === "Manage_User");
    const deleteRole = getAuthPermis.filter((f) => f === "Manage_User");
    const filterExport = getAuthPermis.filter((f) => f === "Export_User");
    const filterImport = getAuthPermis.filter((f) => f === "Import_User");
    const filterManage = getAuthPermis.filter((f) => f === "Manage_User");

    if (filterManage.length > 0) {
      setManage(true);
    }
    if (editRole.length > 0) {
      setUpdatePermission(true);
    }
    if (deleteRole.length > 0) {
      setDeletePermission(true);
    }
    if (filterImport.length > 0) {
      setCheckImport(true);
    }
    if (filterExport.length > 0) {
      setCheckExport(true);
    }
    if (filterCreate.length > 0) {
      setAddNew(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (User) {
      if (isFetching) {
        setData([]);
      } else {
        setData(User?.usersList?.data);
      }
      setLocalModuleId(User?.module_id);
      setItemCount(User?.usersList?.total);
    }
  }, [User]); //isFetching

  useEffect(() => {
    if (rTData) {
      let rolesArry: any = [];
      rTData?.rolesList?.forEach((target) => {
        // const [key, value] = Object.entries( target )[0];
        // if(target.label != null && target.label !== undefined && target.label !== '') {
        rolesArry.push({
          id: target.id,
          value: target.id,
          label: target.name,
        });
        // }
      });
      setRoles([{ id: 0, value: "all", label: "All" }, ...rolesArry]);
    }
  }, [rTData]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() === currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);

    document.querySelectorAll("th,td").forEach((x) => {
      if (x.id.includes("none")) {
        x.classList.add("hidden");
      }
      if (x.id.includes("action")) {
        x.classList.add("absolute");
        x.classList.add("right-0");
      }
      x.classList.remove("absolute");
      x.classList.add("left-0");
      x.classList.add("relative");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
    });
    // setTemplatePlaceholder(currentView);
  }, [currentView, defaultFields]); //templateList

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]); //isTemplate &&

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter(
      (x) => Number(x.id) !== parseInt(id)
    );
    setTempList(filterTempList);
  };

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadUserSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sampleUser.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };

  const importUser = async (formSaveData: any) => {
    await axios(endpointUrl + "importUsers", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formSaveData,
    })
      .then((res) => {
        axios
          .get(
            `${endpointUrl}users?search=${globalSearch}&role=${role}&page=${currentPage}&limit=${limit}`,
            {}
          )
          .then((result) => {
            var UpdatedData = result?.data?.usersList?.data;
            if (result.data.status) {
              setSuccessPopup(true);
              setMessage(["Successfully import."]);
            }
            setData(UpdatedData);
            setIsImporting(true);
            setIsImportModalOpen(false);
            toast("Successfully Import.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          });
      })
      .catch((error: any) => {
        // console.log("e", error)
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  const exportUserData = (format: string, isChecked: string) => {
    axios({
      url:
        endpointUrl +
        `users?search=${globalSearch}&role=${role}&page=${currentPage}&limit=${limit}&&format=${format}&export=true&only_this_page=${isChecked}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "users." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  useEffect(() => {
    if (successPopup) {
      toast("Done Successfully.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, role, limit]);

  var disabledData = checkedIsNew(data);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1100,
  });

  if (error) {
    if (FetchBaseError(error)) {
      if (error?.status === 400) {
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        setErrorPopup(true);
        setMessage(
          error?.data?.message
            ? [error?.data?.message]
            : ["You are something wrong. Pls login Again!"]
        );
      }
    }
  }

  return (
    <>
      {userLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <div>
          <ImportUsersModal
            isOpen={isImportModalOpen}
            setIsOpen={setIsImportModalOpen}
            importEmployee={importUser}
            sampleCSV={downloadSampleFile}
            isImporting={isImporting}
            setIsImporting={setIsImporting}
            setIsDownloading={setIsDownloading}
            isDownloading={isDownloading}
          />
          <ExportUsersModal
            isOpen={isExportModalOpen}
            setIsOpen={setIsExportModalOpen}
            exportToCSV={exportUserData}
            exportToExcel={exportUserData}
            exportToPDF={exportUserData}
            exportToZip={exportUserData}
            isExporting={isExporting}
            setIsExporting={setIsExporting}
            isShowOnlyCheckbox={true}
          />
          <ToastContainer className={"text-12 font-poppins font-normal"} />
          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />

          <animated.div style={props}>
            <div className="p-4 bg-white sm:mt-[25px] mt-0 font-poppins  tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 px-4 ">
              <div className="flex flex-wrap justify-between items-center">
                <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
                  <DebounceSearchInput
                    setState={setGlobalSearch}
                    debounce={800}
                  >
                    {(setState) => (
                      <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px] sm:w-[260px] w-[225px]">
                        <input
                          type="text"
                          placeholder="Search"
                          className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                          onChange={(e) => setState(e.target.value)}
                        />
                        <SearchIcon className="mr-2" />
                      </div>
                    )}
                  </DebounceSearchInput>
                </div>
                <div className="flex flex-wrap items-center">
                  {checkImport ? (
                    <Button
                      label={
                        <span className="flex items-center">
                          <ImportIcon className="mr-[5px]" />
                          Import
                        </span>
                      }
                      onClick={() => {
                        setIsImportModalOpen(true);
                        setIsImporting(false);
                      }}
                      type="button"
                      variant="no-outline"
                      customClass="my-1 bg-transparent border-transparent hover:border-vorpblue hover:text-vorpblue transition-all duration-300 text-black2 xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                      size="base"
                    />
                  ) : null}

                  {checkExport ? (
                    <Button
                      label={
                        <span className="flex items-center">
                          <ExportIcon className="mr-[5px]" />
                          Export
                        </span>
                      }
                      onClick={() => {
                        setIsExportModalOpen(true);
                        setIsExporting(false);
                      }}
                      type="button"
                      variant="no-outline"
                      customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                      size="base"
                    />
                  ) : null}
                  {addNew ? (
                    <Button
                      disabled={disabledData?.disabled}
                      label="+ Add New"
                      onClick={() => {
                        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                        setData((prev) => [addNewUser(), ...prev]);
                      }}
                      type="button"
                      variant="primary"
                      customClass={
                        disabledData?.disabledClass +
                        " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                      }
                      size="base"
                    />
                  ) : null}
                </div>
              </div>

              <div className="flex sm:flex-row flex-col justify-between md:items-center mb-1">
                <div className="flex flex-wrap xs:items-center 2xs:w-auto w-full sm:mt-0 mt-1 sm:mb-0 mb-1">
                  <div
                    className={`flex items-center w-auto  ${Styles.fullCustomDropdown}`}
                  >
                    <p className="hidden base-label 2xs:w-auto w-1/2 text-left 2xl:text-sm text-12">
                      ROLE
                    </p>
                    <CustomDropdownListForFilter
                      isFilter={true}
                      state={role}
                      setState={setRole}
                      dropdownItems={roles.map((role) => ({
                        value: role.label,
                        label: role.label,
                      }))}
                      classNameCus="max-w-[78px] xs:max-w-[250px] 2xl:min-w-[240px] min-w-[200px]"
                      className="w-full min-w-full 5xs:min-w-[240px] max-w-full xs:min-w-[180px] sm:min-w-[160px] 2xl:min-w-[180px] 6xs:text-12"
                      placeholder="Role"
                      textAlign="text-left py-2 pl-2 pr-3"
                    />
                  </div>
                </div>
                <div className="flex flex-col htzxs:flex-row justify-end  htzxs:items-center">
                  <div className="flex items-center justify-end md:justify-start">
                    <div className="flex items-center mb-auto ml-3 mr-[10px]">
                      <p className="base-label xl:text-sm text-12">DISPLAY</p>
                      <NumberDropdownList
                        state={pagination.pageSize}
                        setState={(value) => {
                          setPagination((prev) => ({
                            ...prev,
                            pageSize: value as number,
                          }));
                          setLimit(value);
                        }}
                        dropdownItems={new Array(5)
                          .fill(1)
                          .map((_, index: number) => {
                            let display = (index + 1) * 20;
                            return {
                              value: display,
                              label: String(display),
                            };
                          })}
                        className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                        textAlign="text-left pl-3"
                        paddingClass="py-[2px]"
                        placeholder="20"
                      />
                    </div>
                    <div>
                      <StringDropdownListWithDeleteBtn
                        haveDeleteBtn={true}
                        setIsTemplate={setIsTemplate}
                        state={isTemplate ? currentViewData : currentView}
                        setState={setCurrentView}
                        deleteTemplate={deleteTemplateData}
                        dropdownItems={moduleList.map(
                          (x: any, index: number) => {
                            return {
                              label:
                                Number(x.id) === -1
                                  ? x.name
                                  : "View #" + x.index,
                              value: x.id,
                            };
                          }
                        )}
                        className="w-full min-w-[100px] 2xl:min-w-[128px] 6xs:text-12"
                        placeholder="View #1"
                        textAlign="text-left py-2 pl-2 pr-3"
                        custPadding="py-[2px]"
                        setErrorPopup={setErrorPopup}
                        setSuccessPopup={setSuccessPopup}
                        setMessage={setMessage}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => setIsLayoutControlOpen(true)}
                      className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                    >
                      <CustomizeLayoutIcon />
                      <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                        <AddIcon width={8} height={8} color="white" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <BackendUsersTable
                data={data}
                setData={setData}
                pagination={pagination}
                setPagination={setPagination}
                isLayoutControlOpen={isLayoutControlOpen}
                setIsLayoutControlOpen={setIsLayoutControlOpen}
                globalSearch={globalSearch}
                setGlobalSearch={setGlobalSearch}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                roles={roles}
                setRoles={setRoles}
                localModuleId={localModuleId}
                // setHiddenFields={setHiddenFields}
                hiddenFields={hiddenFields}
                // templateList={tempList}
                // setCurrentView={setCurrentView}
                setIsEdit={setIsEdit}
                isEditing={isEditing}
                total={itemCount}
                // changePage={changePage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setCols={setCols}
                setIsTemplate={setIsTemplate}
                checkManage={checkManage}
                addNew={addNew}
                sorting={sorting}
                setSorting={setSorting}
                checkUpdate={updatePermission}
                checkDelete={deletePermission}
              />
            </div>
          </animated.div>
        </div>
      )}
    </>
  );
};
export default UserList;
