import {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useUpdateUsersMutation,
  useCreateUserMutation,
  useDeleteUserListMutation,
  useMultiDeleteUserListMutation,
  useChangeUserStatusMutation,
  useSendActivateMailMutation,
} from "../../app/services/userlist";
import styles from "./PermissionTable.module.scss";
// import { templateData } from "../../app/services/template";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import {
  CancelIcon,
  EditIcon,
  SendIconUpdated,
  TableSaveIcon,
  TrashIcon,
} from "../common/Icons";
import TablePaginator from "../Tables/TablePaginator";
import ToggleSwitch from "../common/ToggleSwitch";
import { baseObj } from "../../utils/constants";
import EditableCell from "../Tables/EditableCell";
import RolesDropdownList from "./RolesDropdownList";
import { IRole } from "../../backendUsers/views/UserList";
import DeletePopup from "../../components/DeletePopup/DeletePopup";
import classNames from "classnames";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import styles1 from "../Projects/Projects.module.scss";
import DOMPurify from "dompurify";
import { toast, ToastContainer } from "react-toastify";
import CommonErrorPopup from "../common/CommonErrorPopup";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles: any = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
interface IEnableSwitch {
  initialState: boolean;
  id: string;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  cell: Cell<any, any>;
  changeUserStatusData?: any;
}

interface IBackendUsersTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  roles: IRole[];
  setRoles: Dispatch<SetStateAction<IRole[]>>;
  localModuleId: number;
  // templateList: templateData[];
  // setHiddenFields: any;
  hiddenFields: string[];
  // setCurrentView: any;
  isEditing?: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  setCurrentPage: any;
  total: number;
  changePage?: any;
  currentPage: number;
  setCols?: Dispatch<any>;
  setIsTemplate?: Dispatch<React.SetStateAction<boolean>>;
  checkManage: boolean;
  addNew: boolean;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  checkUpdate: boolean;
  checkDelete: boolean;
}

export const EnableSwitch: FC<IEnableSwitch> = ({
  initialState,
  id,
  setData,
  cell,
  changeUserStatusData,
}) => {
  const [isEnable, setIsEnable] = useState<boolean>(initialState);
  const editCell = useCallback(
    (value: boolean) => {
      setData((prev: baseObj[]) => {
        return prev.map((data: baseObj, index: number) => {
          if (index === Number(cell.row.id)) {
            changeUserStatusData(data.id, value);
            return { ...data, [cell.column.id]: value };
          }
          return data;
        });
      });
    },
    [setData, cell, changeUserStatusData]
  );

  return (
    <div className="max-w-[120px] flex">
      <ToggleSwitch
        id={id}
        state={isEnable}
        setState={setIsEnable}
        updateData={editCell}
      />
    </div>
  );
};



const BackendUsersTable: FC<IBackendUsersTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  roles,
  setRoles,
  localModuleId,
  // templateList,
  // setHiddenFields,
  hiddenFields,
  // setCurrentView,
  isEditing,
  setIsEdit,
  setCurrentPage,
  changePage,
  currentPage,
  total,
  setCols,
  setIsTemplate,
  checkManage,
  addNew,
  sorting,
  setSorting,
  checkDelete,
  checkUpdate,
}) => {
  const classes = useStyles();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [selectedID, setSelectedID] = useState<number>(0);
  const [multipleDelete, setMultipleDelete] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definitions when api binding
  const [isLoadingClick, setIsLoadingClick] = useState<boolean>(false);
  const [openSendPopup, setOpenSendopup] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [clickLoading, setClickLoading] = useState<boolean>(false);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
  const [placement, setPlacement] = useState<any>("top-end");
  const [updateUsers] = useUpdateUsersMutation();

  const [addUser] = useCreateUserMutation();
  const [deleteUser] = useDeleteUserListMutation();
  const [multipleDeleteUser] = useMultiDeleteUserListMutation();
  const [changeUserStatus] = useChangeUserStatusMutation();
  const [selectedRole, setSelectedRole] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [sendEmail] = useSendActivateMailMutation();
  const deleteUsers = useCallback(
    (id?: number, dataId?: number) => {
      const selectedRows = Object.keys(rowSelection);
      setClickLoading(true);
      setData((prev: baseObj[]) => {
        if (dataId !== undefined) {
          deleteUser(dataId)
            .unwrap()
            .then((payload: any) => {
              if (payload.status) {
                setSuccessPopup(true);
                setClickLoading(false);
                setMessage(
                  payload ? [payload?.message] : ["Successfully Delete!"]
                );
                setOpenDeleteopup(false);
                toast("Successfully Deleted.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  type: "success",
                  theme: "light",
                });
              }
            })
            .catch((error: any) => {
              setClickLoading(false);
              setErrorPopup(true);
              setMessage(
                error ? [error?.data?.message] : ["Something went wrong!"]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id === undefined && setRowSelection({});
    },

    [setData, rowSelection, deleteUser]
  );

  const validateEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const checkIsValidate = (editedData: any) => {
    var message: any = [];
    if (editedData.name === "") {
      message.push("The name field is required");
    }

    if (editedData.email === "" || !validateEmail(editedData.email)) {
      message.push("The email not validate");
    }
    return message;
  };

  const saveUser = useCallback(
    (ids: number, dataId: number) => {
      setUploading(true);

      var roles = localStorage.getItem("selectedObj");
      var nonSelect = localStorage.getItem("nonSelect");
      var editedData = data.find((x) => x.id === dataId);

      var roleData: any = [];
      if (roles) {
        roleData = JSON.parse(roles).filter((x: any) => x !== undefined);
      }

      if (Number(dataId) === 0) {
        setData((prev: baseObj[]) => {
          return prev.map((data, index: number) => {
            var rData = roleData
              ?.map((x: any) => x.id)
              .filter((x: any) => x !== undefined);
            if (index === ids) {
              var message = checkIsValidate(data);
              if (message.length > 0) {
                setErrorPopup(true);
                setMessage(message);
                return { ...data, isNew: true };
              }
              // data.roles.forEach((role: any) => {
              // saveRoleArr.push(role.id);
              // });
              const name = DOMPurify.sanitize(data.name);
              const user = {
                name,
                roles:
                  rData?.length > 0 ? rData : data.roles?.map((x: any) => x.id),
                email: data.email,
                enable: data.enable,
              };
              addUser(user)
                .unwrap()
                .then((payload: any) => {
                  setUploading(false);
                  localStorage.setItem("selectedObj", "");
                  if (payload.status) {
                    setSuccessPopup(true);
                    setMessage([payload?.message]);
                    toast("Successfully Created.", {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      type: "success",
                      theme: "light",
                    });
                  }
                  return { ...data, isNew: false };
                })
                .catch((error: any) => {
                  setUploading(false);
                  setErrorPopup(true);
                  setMessage(
                    error ? [error?.data?.message] : ["Something went wrong!"]
                  );
                  return { ...data, isNew: true };
                });
              // return { ...data, isNew: false };
            }
            return data;
          });
        });
      } else {
        // setData((prev: baseObj[]) => {
        //   return prev.map((data, index: number) => {
        //     var rData = roleData
        //       ?.map((x: any) => x.id)
        //       .filter((x: any) => x !== undefined);
        //     if (index === ids) {
        //       var message = checkIsValidate(data);
        //       if (message.length > 0) {
        //         setErrorPopup(true);
        //         setMessage(message);
        //         return { ...data, isNew: true };
        //       }
        //       data.roles.forEach((role: any) => {
        //         // saveRoleArr.push(role.id);
        //       });
        //       const name = DOMPurify.sanitize(data.name);
        //       const user = {
        //         id: dataId,
        //         name,
        //         //roles: roleData?.map((x: any) => x.id),//data.roles?.map((x: any) => x.id),
        //         // roles: rData?.length > 0 ? rData : data.roles?.map((x: any) => x.id),
        //         // roles: Number(nonSelect) === 0 ? [] :  (rData?.length > 0 && rData) ,
        //         roles:
        //           Number(nonSelect) === 1 && rData?.length === 0
        //             ? data.roles?.map((x: any) => x.id)
        //             : Number(nonSelect) === 0
        //             ? []
        //             : rData,
        //         email: data.email,
        //         enable: data.enable,
        //       };
        //       // updateUsers(user)
        //       //   .unwrap()
        //       //   .then((payload: any) => {
        //       //     localStorage.setItem("selectedObj", "");
        //       //     if (payload.status) {
        //       //       setSuccessPopup(true);
        //       //       setMessage([payload?.message]);
        //       //       toast("Successfully Updated.", {
        //       //         position: "top-right",
        //       //         autoClose: 2000,
        //       //         hideProgressBar: false,
        //       //         closeOnClick: true,
        //       //         pauseOnHover: true,
        //       //         draggable: true,
        //       //         progress: undefined,
        //       //         type: "success",
        //       //         theme: "light",
        //       //       });
        //       //     }
        //       //     return { ...data, isNew: false };
        //       //   })
        //       //   .catch((error: any) => {
        //       //     setErrorPopup(true);
        //       //     setMessage(
        //       //       error ? [error?.data?.message] : ["Something went wrong!"]
        //       //     );
        //       //     return { ...data, isNew: true };
        //       //   });
        //     }
        //     return data;
        //   });
        // });
        // var editedData = data.find((x) => x.id === dataId);

        const name = DOMPurify.sanitize(editedData?.name);
        var rData = roleData
          ?.map((x: any) => x.id)
          .filter((x: any) => x !== undefined);
        const user = {
          id: dataId,
          name,
          roles:
            Number(nonSelect) === 1 && rData?.length === 0
              ? editedData?.roles?.map((x: any) => x.id)
              : Number(nonSelect) === 0
                ? []
                : rData,
          email: editedData?.email,
          enable: editedData?.enable,
        };

        updateUsers(user)
          .unwrap()
          .then((payload: any) => {
            setUploading(false);

            localStorage.setItem("selectedObj", "");
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              toast("Successfully Updated.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: "success",
                theme: "light",
              });
            }
            return { ...data, isNew: false };
          })
          .catch((error: any) => {
            setUploading(false);

            setErrorPopup(true);
            setMessage(
              error ? [error?.data?.message] : ["Something went wrong!"]
            );
            return { ...data, isNew: true };
          });
      }
    },
    [setData, data] //setSelectedRole,saveRoleArr, setData,addUser, updateUsers, data
  );

  const editUser = useCallback(
    (id: number) => {
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (index === id) {
            return { ...data, isNew: true };
          }
          return data;
        });
      });
      const role = roles.filter((f: any) => f.id === id);
      setSelectedRole(role);
      setEdit(true);
    },
    [setData, roles]
  );

  const deleteUsersHandler = useCallback(
    (ids: number[]) => {
      // const selectedRows = Object.keys(rowSelection);
      // setClickLoading(true);

      // var selectionIds = data
      //   .filter((x: any, index: number) => {
      //     return selectedRows.find((s) => s === index.toString()) ? x : 0;
      //   })
      //   .map((x: any) => x.id);
      // var $data: any = {
      //   ids: selectionIds,
      // };

      setClickLoading(true);
      multipleDeleteUser(ids)
        .then((payload: any) => {
          setClickLoading(false);
          const selectedRows = Object.keys(rowSelection);
          setData((prev: baseObj[]) =>
            prev.filter((_, index) => {
              return !selectedRows.includes(String(index));
            })
          );
          if (payload.data.status) {
            setOpenDeleteopup(false);
            toast("Successfully Deleted.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              type: "success",
              theme: "light",
            });
          }
          setRowSelection({});
        })
        .catch((error: any) => {
          console.error("rejected", error);
          setClickLoading(false);
        });
    },
    [setData, rowSelection, data, multipleDeleteUser]
  );

  const confirmDeleteData = () => {
    var id = deleteRowID;
    var realId = deleteID;
    // // const selectedRows = Object.keys(rowSelection);
    // if (multipleDelete) {
    //   deleteUsersHandler();
    // } else {
    //   deleteUsers(id, realId);
    //   setDeleteID(-1);
    // }

    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.id : 0;
      })
      .map((x) => x.id);
    if (selectionIds.length > 0) {
      deleteUsersHandler(selectionIds);
    } else {
      deleteUsers(id, realId);
      setDeleteID(-1);
    }
  };

  const destoryRow = useCallback(
    (id?: number, dataId?: number) => {
      setDeleteRowID(id);
      setDeleteID(dataId);
      setMultipleDelete(false);
      setOpenDeleteopup(true);
    },
    [] //setData, rowSelection
  );

  const MultideleteHandler = () => {
    setOpenDeleteopup(true);
    setMultipleDelete(true);
  };

  const changeUserStatusData = useCallback(
    (id: string, status: boolean) => {
      changeUserStatus({ id: parseInt(id), status: status === true ? 1 : 0 });
    },
    [changeUserStatus]
  );

  const canCeelRow = useCallback(
    (id: number) => {
      setIsEdit(false);
      if (isEditing) {
        setData((prev: baseObj[]) => {
          return prev.map((data, index: number) => {
            if (data.id === id) {
              return { ...data, isNew: false };
            }
            return data;
          });
        });
      } else {
        const filterData = data?.filter((d: any) => d?.id !== id);
        setData(filterData);
      }
      localStorage.setItem("selectedObj", "");
    },
    [isEditing, setIsEdit, setData, data]
  );

  // TODO add type definitions when api binding
  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: `${(checkDelete||checkUpdate) ? "selection" : "none-selection"}`,
      }),
      columnHelper.accessor("name", {
        header: "Name",
        id: "name",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className="min-w-[170px] ">
              <EditableCell
                maxLength={25}
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor(
        (props) => props?.roles?.map((role: baseObj) => role.name),
        {
          header: "Role",
          id: "roles",
          filterFn: "arrIncludes",
          enableSorting: false,
          cell: ({ getValue, cell }) => {
            const role = getValue();

            const originalValues = cell.row.original?.roles?.map(
              (role: baseObj) => role.id
            );
            const isNew = cell.row.original?.isNew;

            return (
              <div className="min-w-full sm:min-w-full usertable">
                {roles.length && data ? (
                  <EditableCell
                    maxLength={33}
                    setData={setData}
                    hasPadding={true}
                    value={role?.join(", ")}
                    type="custom"
                    cell={cell}
                    customDesign="tooltip"
                    CustomComponent={

                      <RolesDropdownList
                        initialValues={
                          originalValues ? originalValues : "Select"
                        }
                        cell={cell}
                        roles={
                          roles.length ? roles?.filter((x) => x.id !== 0) : []
                        }
                        setRoles={setRoles}
                        isOverflow={true}
                        isShowAddBtn={false}
                        isOpen={open}
                        isShow={show}
                        setOpen={setOpen}
                        setShow={setShow}
                        setData={setData}
                        setAnchorEl={setAnchorEl}
                        anchorE1={anchorEl}
                        setPlacement={setPlacement}
                        placement={placement}
                        setSelectedRole={setSelectedRole}
                        isEditable={isNew}
                        isEditing={isEditing}
                      />
                    }
                    className="text-[#999999] whitespace-nowrap overflow-hidden text-ecllipse py-0"
                  />
                ) : null}
              </div>
            );
          },
        }
      ),
      columnHelper.accessor("email", {
        header: "Email Address",
        id: "email",
        cell: ({ getValue, cell }) => {
          const email = getValue();
          return (
            <div className="min-w-full">
              <EditableCell
                maxLength={28}
                setData={setData}
                value={email}
                type="email"
                cell={cell}
                customDesign=""
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("activated", {
        header: "Activated",
        id: "activated",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const activated = getValue();
          const isNew: boolean = cell.row.original?.isNew;

          let activatedData = null;
          if (activated) {
            activatedData = "Yes";
          } else {
            activatedData = "No";
          }
          return (
            <div
              className={classNames(
                isNew ? " pointer-events-none opacity-60" : "",
                "min-w-full "
              )}
            >
              <EditableCell
                maxLength={28}
                setData={setData}
                value={activatedData}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("enable", {
        header: "Enable",
        id: "enable",
        enableSorting: false,
        cell: ({ getValue, cell }) => (
          <div
            className={classNames(
              (checkUpdate||addNew) ? "" : "pointer-events-none",
              " px-3 min-w-[100px] "
            )}
          >
            <EnableSwitch
              setData={setData}
              cell={cell}
              initialState={getValue()}
              id={cell.id}
              changeUserStatusData={changeUserStatusData}
            />
          </div>
        ),
      }),
      columnHelper.accessor("createdBy", {
        header: "Created By",
        id: "createdBy",
        cell: (props) => {
          const data = props.getValue() != null ? props.getValue() : "";
          const date = data.createdAt != null ? data.createdAt : "";
          return (
            <div className="min-w-full  text-left flex flex-col items-center justify-center bg-white text-black2 px-3">
              <span
                className={classNames(
                  "text-black2 leading-[normal]",
                  data.name ? "" : "hidden"
                )}
              >
                {data.name}
              </span>
              <span className="text-[10.1px] leading-[normal] text-graydark">
                {date}
              </span>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        id: `${(data?.find((x) => x.isNew == true) && addNew) || checkUpdate || checkDelete
          ? "actions"
          : "none-actions"
          }`,
        cell: ({ cell }) => {
          const isNew: boolean = cell.row.original?.isNew;
          const dataId: number = cell.row.original?.id
            ? cell.row.original?.id
            : 0;
          var isHaveNew = data?.find((x) => x?.isNew == true);
          const activated = cell.row.original?.activated;
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          return (
            <div className="flex justify-center items-center">
              {isNew ? (
                <>
                  <button
                    type="button"
                    onClick={() => saveUser(Number(cell.row.id), dataId)}
                    className={classNames(
                      uploading ? " pointer-events-none opacity-30" : "",
                      "w-6 h-6 flex justify-center items-center bg-vorpgreen rounded-md "
                    )}
                  >
                    <TableSaveIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      canCeelRow(Number(cell?.row?.original?.id));
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={18} height={18} />
                  </button>
                </>
              ) : (
                <>

                  {checkManage ? (
                    <>

                      <Tooltip
                        arrow
                        className="customTooltip"
                        classes={{ tooltip: classes.tooltip }}
                        enterTouchDelay={1000}
                        leaveTouchDelay={5000}
                        placement="left"
                        title={
                          <>
                            <span className="px-2 py-2 bg-darkblack text-14 font-normal">Send reset password email</span>
                          </>
                        }
                      >
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedID(cell.row.original.id)
                            setOpenSendopup(true)
                          }}
                          className={classNames(
                            disabledClass,
                            styles.sendIcon,
                            //  isLoading ? " pointer-events-none opacity-50" : "",
                            "relative w-8 h-[35px] mr-2 flex justify-center items-center group rounded-md bg-turquiseIcon hover:bg-turqouiseLight transition-all duration-300"
                          )}
                        >
                          <SendIconUpdated/>
                        </button>
                      </Tooltip>
                      
                      
                    </>
                  ) : null}
                  {checkUpdate?<button
                        disabled={isHaveNew ? true : false}
                        type="button"
                        onClick={() => {
                          localStorage.setItem("selectedObj", "");
                          editUser(Number(cell.row.id));
                          if (setIsEdit) {
                            setIsEdit(true);
                          }
                        }}
                        className={classNames(
                          disabledClass,
                          "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                        )}
                      >
                        <EditIcon />
                      </button>:''}
                      {checkDelete?<button
                        disabled={isHaveNew ? true : false}
                        type="button"
                        onClick={() => {
                          destoryRow(Number(cell.row.id), dataId);
                          // deleteUsers(Number(cell.row.id), dataId);
                        }}
                        className={classNames(
                          disabledClass,
                          "w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
                        )}
                      >
                        <TrashIcon />
                      </button>:''}
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, setData, uploading, isLoadingClick] //hiddenFields,deleteUsers
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, unknown>) => column.id as string)
  );
  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.forEach((x) => {
    if (checkManage) {
      visibleColumns["actions"] = true;
      visibleColumns["enable"] = true;
      visibleColumns[x] = true;
    } else {
      visibleColumns["actions"] = false;
      visibleColumns["enable"] = false;
      visibleColumns[x] = true;
    }
  });

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }
    var div = document.getElementById(x.id);
  });

  if (hiddenFields?.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields?.find((h: any) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  useEffect(() => {
    if (selectedRole.length === 0 && isEdit) {
      localStorage.setItem("nonSelect", JSON.stringify(0));
    } else {
      localStorage.setItem("nonSelect", JSON.stringify(1));
    }
  }, [selectedRole, isEdit]);

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);

  // var disabledData = checkedIsNew(data);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);
  const confirmSendData = () => {
    setIsLoadingClick(true);
    sendEmail(selectedID).then((res: any) => {
      console.log(res)
      if (res?.error) {
        setErrorPopup(true);
        setMessage(res?.error?.data?.message)
        setOpenSendopup(false);
        setIsLoadingClick(false);
      }
      else {
        setErrorPopup(false);
        setOpenSendopup(false);
        setIsLoadingClick(false);
        toast(res?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
      }
    })
  };
  return (
    <div
      className={classNames(
        "text-primary text-left relative",
        styles.userTables
      )}
    >
      {/* <SuccessPopup
        descText="Done Successfully!"
        confirmBtnText="Yes, Close."
        show={successPopup}
        setShow={setSuccessPopup}
        message={message}
        setMessage={setMessage}
      /> */}
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup setShow={setErrorPopup} show={errorPopup} message={message} />
      {/* <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      /> */}
      <DeletePopup
        loading={isLoadingClick}
        descText="Are you sure to send?"
        confirmBtnText="Yes, send."
        show={openSendPopup}
        setShow={setOpenSendopup}
        confirmDeleteData={confirmSendData}
        confirmBtnColor="bg-turquiseIcon hover:bg-turqouiseLight hover:text-black2"
      />
      <DeletePopup
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
        descText="Are you sure to delete?"
        loading={clickLoading}
      />
      <div className={classNames(styles1.userTable, "relative w-full max-w-full")}>
        <LayoutAdjustableTable
          columns={columns}
          data={data}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["actions", "selection"]}
          theaderColor="text-graydark"
          isLayoutControlOpen={isLayoutControlOpen}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          label="User List"
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          globalFilter={globalSearch}
          setGlobalFilter={setGlobalSearch}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          noPaddingCells={["role"]}
          localModuleId={localModuleId}
          hideColumn={visibleColumns}
          setCols={setCols}
          setIsTemplate={setIsTemplate}
        />
      </div>
      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-3 lg:mt-5 lg:mb-5 ">
          <p>{selectRowsCount} selected.</p>
          {checkManage ? (
            <button
              type="button"
              onClick={() => MultideleteHandler()}
              className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
            >
              <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-redPinkLight transition-all duration-300 ">
                <TrashIcon />
              </div>
              Delete
            </button>
          ) : null}
        </div>
      )}
      <div className=" pt-2">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          changePage={changePage}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
        />
      </div>
    </div>
  );
};

export default BackendUsersTable;
