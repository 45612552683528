import { FC } from "react";
import { twJoin } from "tailwind-merge";

export interface ICommonLanguage{
    changeLanguage: (code: string) => void;
    activeLang:string;
}
const CommonLanguage:FC<ICommonLanguage>=({
    changeLanguage,
    activeLang
})=>{
    return <div className="pt-3 px-2">
    <button onClick={()=>changeLanguage("en")} className={twJoin(activeLang=="en"?"activeLanguage":'',"rounded-tl-xl rounded-tr-xl px-5 py-2 text-body-md")}>English</button>
    <button onClick={()=>changeLanguage("tc")} className={twJoin(activeLang=="tc"?"activeLanguage":'',"rounded-tl-xl rounded-tr-xl px-5 py-2 text-body-md")}>繁體中文</button>
    <button onClick={()=>changeLanguage("sc")} className={twJoin(activeLang=="sc"?"activeLanguage":'',"rounded-tl-xl rounded-tr-xl px-5 py-2 text-body-md")}>简体中文 </button>
</div>
}
export default CommonLanguage;