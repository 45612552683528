import { initializeApp } from 'firebase/app';
import { api, endpointUrl } from './api';
// const endpointUrl = "http://localhost:3004";
import authHeader from './auth-header';
// import { apiKey, appId, authDomain, firebaseConfig, measurementId, messagingSenderId, projectId, storageBucket } from './firebase';
import { deleteToken, getMessaging } from 'firebase/messaging';

export type AnnounSheetDataResponse = {
    data: AnnounSheet[]
    current_page: number;
    from: number;
    to: number;
    total: number;
    per_page: number;
    first_page_url: string;
    last_page_url: string;
    next_page_url: string;
};


export type AnnounSheetDataResponseCus = {
    announsheets: AnnounSheetDataResponse,
    module_id: number,
};
export type AnnounSheet = {
    id: number,
    title: string,
    tag: string[],
    postedDate: string,
    expiryDate: string,
    postedBy: string,
    updatedBy: string,
    type: string,
    viewBy?: [],
    content: string,
    data?: any,
}

export type TagsResponse = {
    tagsData: TagsData[]
}
export type TagsResponseCustom = {
    tagList: TagsData[]
}
export type TagsData = {
    id: number,
    name: string,
    flag: string
}

export type CreateeAnnounSheet = {
    id?: number,
    title_en: string,
    title_tc: string,
    title_sc: string,
    content_en: string,
    content_tc: string,
    content_sc: string,
    tags: number[],
    expired_at: string,
    postedDate?: string,
    postedBy?: string,
    updatedBy?: string,
    typeData: string
}


export type TemplateData = {
    id: number;
    name: string;
    viewColumns: string[];
};

export type ErrorState = {
    status?: number | string,
    data?: any | undefined,
    error?: string
}
export type TagInput = {
    name: string;
    flag: string;
}
export type PaginationData = {
    page: number;
    limit: number | string | undefined,
    search: string | number,
    tag: string | number | undefined,
    type: string | number | undefined,
    startDate?: string;
    endDate?: string;
    sort?:string;
}
export const deleteNotiToken = () => {
    // const app = initializeApp(firebaseConfig);
    // const messaging = getMessaging(app);
    // deleteToken(messaging).then((res) => {
    // }).catch(function (error) {
    //     console.error('Error deleting FCM token:', error);
    //   });;
}
export const redirectToLogin = () => {
    localStorage.clear();
    window.location.href = '/';
    deleteNotiToken();
}
export const announApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAnnounsData: build.query<AnnounSheetDataResponse, void>({
            query: () => ({
                url: `${endpointUrl}announcements`,
                headers: authHeader()
            }),
            keepUnusedDataFor:0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: ['CreateeAnnounSheet'],
        }),
        getAnnounsDataWithPagination: build.query<AnnounSheetDataResponseCus, PaginationData>({
            query: ({ limit, page, search, tag, startDate, endDate, type,sort }) => ({
                url: `${endpointUrl}announcements?limit=${limit}&page=${page}&search=${search}&tag=${tag}&startDate=${startDate}&endDate=${endDate}&type=${type}&sort=${sort}`,
                headers: authHeader()
            }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            keepUnusedDataFor:0,
            providesTags: ['CreateeAnnounSheet'],
        }),
        getTagsData: build.query<TagsResponse, void>({
            query: () => ({
                url: `${endpointUrl}tags`,
                headers: authHeader()
            }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: ['Tags'],
        }),
        getTagsDataCustom: build.query<TagsResponseCustom, void>({
            query: () => ({
                url: `${endpointUrl}getCategoryList`,
                headers: authHeader()
            }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: ['TagsCustom'],
        }),
        createTag: build.mutation<TagsResponse, Partial<TagInput>>({
            query: (data) => ({
                url: `${endpointUrl}tags`,
                method: "POST",
                headers: authHeader(),
                body: data,
            }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            invalidatesTags: () => [{ type: 'Tags' }],
        }),
        createTagCustom: build.mutation<TagsResponse, Partial<TagInput>>({
            query: (data) => ({
                url: `${endpointUrl}tags`,
                method: "POST",
                headers: authHeader(),
                body: data,
            }),
            invalidatesTags: () => [{ type: 'TagsCustom' }],
        }),
        getAnnoTemplateList: build.query<TemplateData[], void>({
            query: () => ({ url: `http://localhost:3004/announcementTemplates` }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
        }),
        deleteAnnoTemplateData: build.mutation<{ success: boolean; id: number }, number>({
            query(id) {
                return {
                    url: `/employee/deleteTemplate/${id}`,
                    method: "DELETE",
                };
            },
        }),
        createAnnounc: build.mutation<CreateeAnnounSheet, Partial<CreateeAnnounSheet>>({
            query: (data) => ({
                url: `${endpointUrl}announcements`,
                method: "POST",
                headers: authHeader(),
                body: data,
            }),
            invalidatesTags: () => [{ type: 'CreateeAnnounSheet' }],
        }),
        updateAnnounc: build.mutation<CreateeAnnounSheet, Partial<CreateeAnnounSheet>>({
            query: ({ id, ...rest }) => {
                return {
                    url: `${endpointUrl}announcements/${id}`,
                    method: "PUT",
                    headers: authHeader(),
                    body: rest,
                }

            },
            invalidatesTags: () => [{ type: 'CreateeAnnounSheet' }],
        }),
        deleteAnnounc: build.mutation<{ id: number }, number>({
            query: (id) => ({
                url: `${endpointUrl}announcements/${id}`,
                method: 'DELETE',
                headers: authHeader(),
            }),
            invalidatesTags: ['CreateeAnnounSheet'],
        }),
        multiDeleteAnnouncList: build.mutation<CreateeAnnounSheet, Partial<CreateeAnnounSheet>>({
            query: ({ id, ...rest }) => ({
                url: `${endpointUrl}deleteAnnouncements`,
                headers: authHeader(),
                method: 'DELETE',
                body: rest
            }),
            // invalidatesTags: (UsersList) => [{ type: 'UsersList', id: UsersList?.id }],
            invalidatesTags: ['CreateeAnnounSheet'],
        }),
    })
});


export const {
    useGetAnnounsDataQuery,
    useGetTagsDataQuery,
    useGetAnnoTemplateListQuery,
    useDeleteAnnoTemplateDataMutation,
    useCreateAnnouncMutation,
    useDeleteAnnouncMutation,
    useUpdateAnnouncMutation,
    useMultiDeleteAnnouncListMutation,
    useCreateTagMutation,
    useGetTagsDataCustomQuery,
    useCreateTagCustomMutation,
    useGetAnnounsDataWithPaginationQuery
} = announApi;

export const {
    endpoints: { getAnnounsData, getAnnoTemplateList,
        deleteAnnoTemplateData, createAnnounc,
        deleteAnnounc, getTagsData,
        updateAnnounc,
        multiDeleteAnnouncList,
        createTag,
        getTagsDataCustom, createTagCustom, getAnnounsDataWithPagination
    },
} = announApi;