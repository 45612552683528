import { FC, useEffect, useState } from "react";
import Button from "../../components/common/Button";
import {
  AddIcon,
  CustomizeLayoutIcon,
  SearchIcon,
} from "../../components/common/Icons";
import {
  NumberDropdownList,
} from "../../components/common/DropdownList";
import RolesTable from "../../components/BackendUsers/RolesTable";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { PaginationState, SortingState } from "@tanstack/react-table";
import {
  addNewRole,
  baseObj,
  rolesTableDummyData,
} from "../../utils/constants";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import {
  useGetRolesQuery,
} from "../../app/services/roles";
import {
  useGetPermissionListDataQuery,
} from "../../app/services/permissions";
import {
  useGetByModuleTemplateQuery,
} from "../../app/services/template";
import { getAuthUser } from "../../app/services/dashboard";
import { checkedIsNew } from "../../app/services/employee";
import SuccessPopup from "../../components/SuccessPopup/SuccessPopup";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";

const RolesList: FC = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [data, setData] = useState<baseObj[]>(rolesTableDummyData);
  const [currentView, setCurrentView] = useState<any>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const defaultFields = ["name", "label", "permissions"];
  const [sorting, setSorting] = useState<SortingState>([]);
  const {
    data: rTData,
    error,
    isFetching,
    isLoading: roleLoading,
  } = useGetRolesQuery({
    search: globalSearch,
    page: currentPage,
    limit: limit,
    sort: sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','),
  });
  const { data: perList } = useGetPermissionListDataQuery();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  // const { data: templateData, isLoading } =
  //   useGetByModuleTemplateQuery(localModuleId);
  const [moduleList, setModuleList] = useState<any>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("---");
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>(false);

  const [viewCols, setCols] = useState<any>([]);
  const [checkManage, setManage] = useState<boolean>(false);
  const [checkCreate, setCreate] = useState<boolean>(false);
  const [checkUpdate, setUpdate] = useState<boolean>(false);
  const [checkDelete, setDelete] = useState<boolean>(false);
  const [createNewRole, setCreateNewRole] = useState<boolean>(false);
  const [permissionsList, setPermissionsList] = useState<any>([]);
  var getUser = getAuthUser();
  const getAuthPermis: string[] = [];

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    const filterManage = getAuthPermis.filter((f) => f === "Manage_Role");
    const createNewRole = getAuthPermis.filter((f) => f === "Create_Role");
    const editRole = getAuthPermis.filter((f) => f === "Manage_Role");
    const deleteRole = getAuthPermis.filter((f) => f === "Manage_Role");
    if (filterManage.length > 0) {
      setManage(true);
    }
    if (editRole.length > 0) {
      setUpdate(true);
    }
    if (deleteRole.length > 0) {
      setDelete(true);
    }
    if (createNewRole.length > 0) {
      setCreateNewRole(true);
    }
  }, [getUser]);

  useEffect(() => {
    setPermissionsList(perList?.permissionLists);
  }, [perList]);

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter((x) => x.id != parseInt(id));
    setTempList(filterTempList);
  };

  useEffect(() => {
    if (rTData) {
      if (isFetching) {
       // setData([]);
      } else {
        setData(rTData?.rolesTableData?.data);
      }
      setLocalModuleId(rTData?.module_id);
      setItemCount(rTData?.rolesTableData?.total);
    }
  }, [rTData, isFetching]);

  // useEffect(() => {
  //   if (templateData) {
  //     var updatedTemplate = templateData?.templates.map((x: any, i) => {
  //       return { ...x, index: i + 1 };
  //     });
  //     var defaultTemplate = {
  //       id: -1,
  //       index: 0,
  //       module: 0,
  //       name: "All",
  //     };
  //     setModuleList([defaultTemplate, ...updatedTemplate]);
  //   }
  // }, [templateData]);

  // useEffect(() => {
  //   if (templateList) {
  //     setTempList(templateList);
  //   }
  //   var viewData = templateData?.templates.find(
  //     (x) => x.id.toString() == currentView
  //   );
  //   setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);

  //   document.querySelectorAll("th,td").forEach((x) => {
  //     if (x.id.includes("none")) {
  //       x.classList.add("hidden");
  //     }
  //     if (x.id.includes("action")) {
  //       x.classList.add("absolute");
  //       x.classList.add("right-0");
  //     }
  //     x.classList.remove("absolute");
  //     x.classList.add("left-0");
  //     x.classList.add("relative");
  //     var div = document.getElementById(x.id);
  //     if (div) {
  //       div.style.setProperty("left", "");
  //     }
  //   });

  //   setTemplatePlaceholder(currentView);
  // }, [templateList, currentView]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [isTemplate && moduleList]);

  const changePage = (pageNo: number) => {
    var pageData = {
      limit: pagination.pageSize,
      currentPage: pagination.pageIndex + 1,
    };
    // getPermissions(pageData)
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, limit]);

  var disabledData = checkedIsNew(data);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1100,
  });

  if (error) {
    if (FetchBaseError(error)) {
      if (error?.status === 400) {
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        setErrorPopup(true);
        setMessage(
          error?.data?.message
            ? [error?.data?.message]
            : ["You are something wrong. Pls login Again!"]
        );
      }
    }
  }

  return (
    <>
      {roleLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <>
          <SuccessPopup
            descText="Done Successfully!"
            confirmBtnText="Yes, Close."
            show={successPopup}
            setShow={setSuccessPopup}
            message={message}
            setMessage={setMessage}
          />

          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />
          <animated.div style={props}>
            <div className="bg-white rounded-xl pt-4 pb-4 sm:pt-4 px-4 w-full h-full font-primary">
              <div className=" md:mb-3 lg:mb-1 mb-1 flex flex-col md:flex-row justify-between md:items-center">
                <div className="flex flex-col xs:flex-row xs:items-center">
                  <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
                    <DebounceSearchInput
                      setState={setGlobalSearch}
                      debounce={800}
                    >
                      {(setState) => (
                        <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px] sm:w-[260px] w-[225px]">
                          <input
                            type="text"
                            placeholder="Search"
                            className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                            onChange={(e) => setState(e.target.value)}
                          />
                          <SearchIcon className="mr-2" />
                        </div>
                      )}
                    </DebounceSearchInput>
                  </div>
                </div>
                <div className="flex justify-end items-center">
                  <div className="flex flex-wrap items-center justify-end md:justify-start">
                    <div className="flex items-center mr-[10px]">
                      {createNewRole ? (
                        <Button
                          label="+ Add New"
                          disabled={disabledData?.disabled}
                          onClick={() => {
                            setPagination((prev) => ({
                              ...prev,
                              pageIndex: 0,
                            }));
                            setData((prev) => [addNewRole(), ...prev]);
                            setIsCreated(true);
                            setIsEdit(false);
                          }}
                          type="button"
                          variant="primary"
                          customClass={
                            disabledData?.disabledClass +
                            " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                          }
                          size="base"
                        />
                      ) : null}
                    </div>
                    <div className="flex items-center ml-3 mr-[10px]">
                      <p className="base-label 2xl:text-sm text-12">DISPLAY</p>
                      <NumberDropdownList
                        state={pagination.pageSize}
                        setState={(value) => {
                          setPagination((prev) => ({
                            ...prev,
                            pageSize: value as number,
                          }));
                          setLimit(value);
                        }}
                        dropdownItems={new Array(5)
                          .fill(1)
                          .map((_, index: number) => {
                            let display = (index + 1) * 20;
                            return {
                              value: display,
                              label: String(display),
                            };
                          })}
                        className="max-w-[60px] lg:max-w-[72px]"
                        textAlign="text-left pl-4"
                        placeholder="20"
                        paddingClass="py-[2px]"
                      />
                    </div>
                    {/* <div>
                      <StringDropdownListWithDeleteBtn
                        haveDeleteBtn={true}
                        setIsTemplate={setIsTemplate}
                        state={isTemplate ? currentViewData : currentView}
                        setState={setCurrentView}
                        deleteTemplate={deleteTemplateData}
                        dropdownItems={moduleList.map(
                          (x: any, index: number) => {
                            return {
                              label: x.id == -1 ? x.name : "View #" + x.index,
                              value: x.id,
                            };
                          }
                        )}
                        className="w-full min-w-[100px] 2xl:min-w-[128px] 6xs:text-12"
                        placeholder="View #1"
                        textAlign="text-left py-2 pl-2 pr-3"
                        custPadding="py-[2px]"
                        setErrorPopup={setErrorPopup}
                        setSuccessPopup={setSuccessPopup}
                        setMessage={setMessage}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => setIsLayoutControlOpen(true)}
                      className="flex relative justify-center items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                    >
                      <CustomizeLayoutIcon />
                      <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                        <AddIcon width={8} height={8} color="white" />
                      </div>
                    </button> */}
                  </div>
                </div>
              </div>

              <RolesTable
                data={data}
                setData={setData}
                isLayoutControlOpen={isLayoutControlOpen}
                setIsLayoutControlOpen={setIsLayoutControlOpen}
                globalSearch={globalSearch}
                setGlobalSearch={setGlobalSearch}
                pagination={pagination}
                setPagination={setPagination}
                localModuleId={localModuleId}
                setHiddenFields={setHiddenFields}
                hiddenFields={hiddenFields}
                templateList={tempList}
                setCurrentView={setCurrentView}
                isEditing={isEditing}
                setIsEdit={setIsEdit}
                setIsCreated={setIsCreated}
                isCreated={isCreated}
                setCols={setCols}
                total={itemCount}
                changePage={changePage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setIsTemplate={setIsTemplate}
                checkManage={checkManage}
                checkUpdate={checkUpdate}
                checkDelete={checkDelete}
                permissionsList={permissionsList}
                addNew={createNewRole}
                sorting={sorting}
                setSorting={setSorting}
              />
            </div>
          </animated.div>
        </>
      )}
    </>
  );
};
export default RolesList;
