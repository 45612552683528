import { FC, useCallback, useEffect, useState } from "react";
import CommonTableSkeleton from "../../TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import styles from './MyEquipment.module.scss'
import { NumberDropdownList } from "../../common/DropdownList";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import CustomDropdownListForFilter from "../../EmployeeTable/CustomDropdownListForFilter";
import { twMerge } from "tailwind-merge";
import MyEquipmentTable from "./MyEquipmentTable";
import { baseObj } from "../../../utils/constants";
import { EquipmentObjectKeys, useGetProfileEquipmentsQuery, useGetProfileFilterEquipmentsQuery } from "../../../app/services/equipments";
import { IEquipmentDateDuration, IEquipmentLog } from "../../../equipments/views/Equipments";
import { endpointUrl } from "../../../app/services/api";
import authHeader from "../../../app/services/auth-header";
import axios from "../../../axios";
const dummyCategories = [
    {
        id: 1,
        name: "Computer"
    },
    {
        id: 2,
        name: "Category2"
    }
];
const dummyStatus = [
    {
        id: 'all',
        name: "All"
    },
    {
        id: 0,
        name: "Pending"
    },
    {
        id: 1,
        name: "Approved"
    },
    {
        id: -1,
        name: "Rejected"
    },
];
const dummyApplicationType = [
    {
        id: 'all',
        name: "All"
    },
    {
        id: 1,
        name: "Borrow"
    },
    {
        id: 2,
        name: "Assign"
    }
]
interface IMyEquipment {
    id: number,
    equipment_code: string,
    serialno: string,
    category: EquipmentObjectKeys,
    equipment_name: string,
    employee: EquipmentObjectKeys,
    "tags": EquipmentObjectKeys[],
    "location": EquipmentObjectKeys,
    "condition": string,
    "version": string,
    "service_provider": string,
    "pricing_detail": string,
    "warranty": IEquipmentDateDuration,
    "license": string,
    "license_date": IEquipmentDateDuration,
    "remark": string,
    equipmentLogs: IEquipmentLog[];
}
const dummyData = [
    {
        id: 1,
        "image": '../img/equipment.png',
        equipment_code: 'EQ0001',
        serialno: '12380928412',
        category: { id: 1, name: 'Computer' },
        equipment_name: 'Designer Laptop 1',
        employee: { id: '1', name: 'Ivan' },
        "tags": [{ id: 1, name: 'Tag1' }, { id: 2, name: 'Tag2' }],
        "location": { id: 1, name: 'Malaysia VO KL Branch' },
        "condition": 'Used',
        "version": 'Version1',
        "service_provider": '-',
        "pricing_detail": 'MYR 8230',
        "warranty": { fromDate: '2023-12-31', toDate: '2025-12-31' },
        "license": 'Lifetime',
        "license_date": { fromDate: '2023-12-31', toDate: '2025-12-31' },
        "remark": 'OS of this laptop occurs bug sometime.',
        "logs": [
            {
                id: 1,
                assign_date: "31-12-2023",
                return_date: "31-12-2023",
                assign_to: "Ivan",
                responsible_person: "Tina",
                image: "../img/equipment-1.png",
                note: "Everything is fine.",
            },
            {
                id: 2,
                assign_date: "31-12-2023",
                return_date: "31-12-2023",
                assign_to: "Ivan",
                responsible_person: "Tina",
                image: "../img/equipment-1.png",
                note: "Everything is fine.",
            }
        ]
    }
]
const MyEquipment: FC = () => {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });
    const [sorting, setSorting] = useState<SortingState>([]);
    const [status, setStatus] = useState<string>('All');
    const [category, setCategory] = useState<string>('All');
    const [categories, setCategories] = useState<EquipmentObjectKeys[]>(dummyCategories);
    const [application, setApplication] = useState<string>('All');
    const [data, setData] = useState<baseObj[]>(dummyData);
    const [itemCount, setItemCount] = useState<number>(data?.length);
    const [equipmentLogs, setEquipmentLogs] = useState<IEquipmentLog[]>([]);
    const [appLoading, setAppLoading] = useState<boolean>(false);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { data: profileEquipment } = useGetProfileFilterEquipmentsQuery();
    const { data: equipments } = useGetProfileEquipmentsQuery({
        category: category,
        status: status,
        application_type: application,
        limit: pagination.pageSize,
        page: currentPage,
        search: '',
        sort: ''
    })
    useEffect(() => {
        if (profileEquipment) {
            setCategories(profileEquipment?.equipment_categories)
        }
    }, [profileEquipment]);
    useEffect(() => {
        if (equipments) {
            setData(equipments?.equipment_applications?.data);
            setItemCount(equipments.equipment_applications.total);
        }
    }, [equipments]);
    const getEquipmentsLogs = async (id: number) => {
        setAppLoading(true);
        await axios(`${endpointUrl}equipments/${id}/logs`,
            {
                method: "GET",
                headers: authHeader(),
                data: {},
            }).then((res) => {
                setEquipmentLogs(res?.data?.equipment_applications);
            })
    }
    useEffect(()=>{
        setCurrentPage(1);
    },[application,category,status])
    return (
        <>
            {false ? (
                <CommonTableSkeleton rows={12} isProfile={true} />
            ) : (
                <>

                    <ToastContainer className={"text-12 font-poppins font-normal"} />
                    <div className="w-full bg-white rounded-xl text-left">
                        <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
                            <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                                My Equipment
                            </h3>
                        </div>

                        <div className="px-2 sm:px-4 md:px-5 pt-3 pb-[10px] border-b border-offwhite">
                            <div className="mb-1 lg:mb-1 xl:mb-1">
                                <div className="flex justify-between flex-row items-center">
                                    <div className="flex items-center">
                                        <div className="flex w-full mr-3">
                                            <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                                                Category
                                            </p>
                                            <CustomDropdownListForFilter
                                                isFilter={true}
                                                placeholder="Category"
                                                state={category}
                                                setState={setCategory}
                                                dropdownItems={categories?.map((status) => ({
                                                    value: status.id,
                                                    label: status.name,
                                                }))}
                                                className="max-h-[38px] max-w-[135px]"
                                            />
                                        </div>
                                        <div className="flex w-full mr-3">
                                            <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                                                Application type
                                            </p>
                                            <CustomDropdownListForFilter
                                                isFilter={true}
                                                placeholder="Application Type"
                                                state={application}
                                                setState={setApplication}
                                                dropdownItems={dummyApplicationType?.map((status) => ({
                                                    value: status.name,
                                                    label: status.name,
                                                }))}
                                                className="max-h-[38px] max-w-[135px]"
                                            />
                                        </div>
                                        <div className="flex w-full mr-3">
                                            <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                                                STATUS
                                            </p>
                                            <CustomDropdownListForFilter
                                                isFilter={true}
                                                placeholder="Status"
                                                state={status}
                                                setState={setStatus}
                                                dropdownItems={dummyStatus?.map((status: any) => ({
                                                    value: status.id,
                                                    label: status.name,
                                                }))}
                                                className="max-h-[38px] max-w-[135px]"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-end md:justify-start sm:w-auto w-full">
                                        <div className="flex items-center mb-auto mr-3">
                                            <p className="base-label xl:text-sm text-12 hidden 3xs:block">
                                                DISPLAY
                                            </p>
                                            <NumberDropdownList
                                                state={pagination.pageSize}
                                                setState={(value) => {
                                                    setPagination((prev) => ({
                                                        ...prev,
                                                        pageSize: value as number,
                                                    }));
                                                }}
                                                dropdownItems={new Array(5)
                                                    .fill(1)
                                                    .map((_, index: number) => {
                                                        let display = (index + 1) * 10;
                                                        return {
                                                            value: display,
                                                            label: String(display),
                                                        };
                                                    })}
                                                className="max-w-[72px]"
                                                textAlign="text-left pl-2 pr-3"
                                                paddingClass="py-[2px]"
                                                placeholder="20"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={twMerge(
                                    styles.myEquipmentTable,
                                    "py-3  md:pb-[48px] border-b border-offwhite relative"
                                )}
                            >
                                <MyEquipmentTable
                                hiddenFields={[]}
                                columnFilters={columnFilters}
                                setColumnFilters={setColumnFilters}
                                data={data ? data : []}
                                setData={setData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                total={itemCount}
                                equipmentLogs={equipmentLogs}
                                pagination={pagination}
                                setPagination={setPagination}
                                sorting={sorting}
                                setSorting={setSorting}
                                getEquipmentsLogs={getEquipmentsLogs} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default MyEquipment;
