import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api, endpointUrl } from './api';
import authHeader from './auth-header';
import { TemplateData } from './employee';
import { redirectToLogin } from './annoucement-sheet';
export type PermissionResponse = { data: Permission[] };
export type Permissions = {
  module_id: number,
  permissions: PermissionDataWithPagination
}

export type PermissionDataWithPagination = {
  current_page: number;
  from: number;
  to: number;
  total: number;
  per_page: number;
  data: Permission[];
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
}

export type Permission = {
  id: number,
  name: string,
  data: PermissionData[]
}
export type PermissionData = {
  access: boolean,
  isDisabled: boolean,
  name: string,
  id: number
}
export type PermissionListData = {
  lists: PermissionLists[]
}

export type PermissionListAllData = {
  permissionLists: PermissionLists[]
}
export type PermissionLists = {
  id: number,
  name: string
}
export type PermissionStatus = {
  id: number,
  roleID: number,
  status: number
}
export type TemplateList = {
  templates: TemplateData[]
}
export type PaginationData = {
  currentPage: number;
  limit: number;
  search: string;
  permission: string;
  sort?:string;
}

interface ListResponse<T> {
  permissions: T;
  module_id: number;
}

export const permissionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPermissions: build.query<ListResponse<PermissionDataWithPagination>, PaginationData>({
      query: ({ currentPage, limit, search, permission,sort }) => ({
        url: `/permissions?limit=${limit}&page=${currentPage}&search=${search ? search : (permission == "All" ? '' : permission)}&permission=${permission}&sort=${sort}`,
        headers: authHeader()
      }),
      transformErrorResponse:(err)=>{
        if(err?.status===401){
         redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: (result: any = []) => {
        if(result.length>0){
          return [
            ...result?.permissions?.data?.map((id: any) => ({ type: 'Permissions', id } as const)),
            { type: 'Permissions' as const, id: 'LIST' },
          ]
        }else{
          return [{ type: 'Permissions' as const, id: 'LIST' },]
        }
      },
    }),



    getPermissionListData: build.query<PermissionListAllData, void>({
      query: () => ({ url: `/getpermissionList`, headers: authHeader() }),
      transformErrorResponse:(err)=>{
        if(err?.status===401){
         redirectToLogin();
        }
      },
    }),
    getPermissionTemplateList: build.query<TemplateData[], void>({
      query: () => ({ url: `/getTemplateList?module=1`, headers: authHeader() }),
      transformErrorResponse:(err)=>{
        if(err?.status===401){
         redirectToLogin();
        }
      },
      transformResponse: (res: TemplateList) => {
        return res.templates;
      },
    }),
    changePermission: build.mutation<Permission, Partial<PermissionStatus>>({
      query: (body) => ({
        url: `${endpointUrl}permissions/${body.id}/changedStatus`,
        method: 'POST',
        body,
        headers: authHeader()
      }),
      //invalidatesTags: (permissions) => [{ type: 'Permissions', id:"LIST" }],
    }),
    getPermissionsList: build.mutation<Permissions, any>({
      query: (body) => ({
        url: `/permissions?limit=${body.pageSize}&page=${body.pageIndex}`,
        method: 'GET',
        headers: authHeader()
      }),
      transformErrorResponse:(err)=>{
        if(err?.status===401){
         redirectToLogin();
        }
      },
    }),
  }),
});


export const {
  useGetPermissionsQuery,
  useGetPermissionListDataQuery,
  useGetPermissionTemplateListQuery,
  useChangePermissionMutation,
  useGetPermissionsListMutation
} = permissionApi;

export const {
  endpoints: {
    getPermissions,
    getPermissionListData,
    getPermissionTemplateList,
    changePermission,
    getPermissionsList
  },
} = permissionApi;