import React, { useEffect, useState } from "react";
import styles from "./LogInComponent.module.scss";
import {
  useProtectedMutation,
} from "../../app/services/authentication";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../img/companyLogo.svg";
import mobileLogo from "../img/login-mobile-bg.png";
import bglogin from "../img/loginbg.svg";
import eyehide from "../img/eye-open.svg";
import eyeshow from "../img/eye-show.svg";
import axios from "axios";
import { checkToken } from "../../MainRouter";
import { endpointUrl } from "../../app/services/api";
// import { CircularProgress } from "@material-ui/core";
import { CircularProgress } from '@mui/material';
import { checkAllPersmissions } from "../../auth/components/SectionRoute";
//import { requestPermission } from "../../app/services/firebase";
import { useLoginWithSSOMutation } from "../../app/services/dashboard";
var classNames = require("classnames");

const login = ({ username = "", password = "" }) => {
  return axios
    .post("http://localhost:3004/login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};
const LogInComponent = () => {
  //const location = useLocation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const {
  //   data: users,
  //   isFetching: isFetchingUserLists,
  // } = useGetUsersQuery();
  // const [deleteUser] = useDeleteUserMutation();
  // const [addUser] = useAddUserMutation();
  //const [updateUser] = useUpdateUserMutation();
  const [isChecked, setIsChecked] = React.useState(false);
  // 15.2.2023
  const [errorMessages, setErrorMessages] = useState({ name: "", message: "" });
  const [loginError, setLoginError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  //const [attemptAccess, { data, error, isLoading }] = useProtectedMutation();
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const [ssoLoading, setSSOLoading] = useState<boolean>(false);
  const [loginWithSSO] = useLoginWithSSOMutation();

  const loginSSO = () => {
    setSSOLoading(true);
    loginWithSSO().then((res: any) => {
      if (res.error) {
        setLoginError(res?.error?.data?.message)
      } else {
        //  setSSOLoading(false);
      }
      window.open(res?.data?.url, '_self');
      setSSOLoading(true);
    })
  }


  useEffect(() => {
    // console.log("window.location.search ",window.location.search)
    var pathData = new URLSearchParams(window.location.search);
    var code = pathData.get('code');
    if (code) {
      setSSOLoading(true);
    }
  }, [window.location.search])
  const handleSubmit = (event: any) => {
    setCustomLoading(true);
    // Prevent page reload
    event.preventDefault();
    var { email, password, remember } = document.forms[0];

    var values = {
      email: email.value,
      password: password.value,
      remember: isChecked,
    };
    axios
      .post(endpointUrl + "login", {
        email: email.value,
        password: password.value,
        remember_me: isChecked == true ? 1 : 0
      })
      .then(function (response) {
        localStorage.clear();
        var data = response.data;
        var userData = JSON.stringify(data);

        localStorage.setItem("userData", userData);

        if (response.data.privateKey) {

          const data = { privateKey: response.data.privateKey };
          const request = indexedDB.open('myDatabase', 1);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction(['myStore'], 'readwrite');
            const store = transaction.objectStore('myStore');
            store.clear();
            const addRequest = store.add(data);

            addRequest.onsuccess = () => {
              //console.log('Data added successfully');
            };

            addRequest.onerror = (event) => {
              console.error('Error adding data', (event.target as IDBOpenDBRequest).error);
            };
          };
        };
       // requestPermission();
        if (response?.data?.status == true) {
          if (data?.permissions?.find((x: any) => x.name == "View_Admin_Dashboard")) {
            // console.log("dashboard", data?.permissions.find((x: any) => x.name == "View_Admin_Dashboard"))
            navigate("/");
          } else if (data?.permissions?.find((x: any) => x.name == "View_My_Profile")) {
            navigate("/profile");
          }
          else {
            checkAllPersmissions(navigate, userData);
          }
        } else {
          setLoginError(response?.data?.message);
          setCustomLoading(false);
        }


        setCustomLoading(false)

      }).catch((reason: any) => {
        setCustomLoading(false)
        if (reason.response) {
          var error = reason.response.data.message
          // var error=reason.response.data.output.error.ErrorMessage;          
          setLoginError(error);
          setCustomLoading(false);
        } else if (reason.message) {

          setCustomLoading(false);
          setLoginError(reason.message);
        }
      });
  };
  const handleSubmitMobile = (event: any) => {
    setCustomLoading(true);
    // Prevent page reload
    event.preventDefault();
    var { email, password, remember } = document.forms[1];

    var values = {
      email: email.value,
      password: password.value,
      remember: isChecked,
    };
    axios
      .post(endpointUrl + "login", {
        email: email.value,
        password: password.value,
        remember_me: isChecked == true ? 1 : 0
      })
      .then(function (response) {
        localStorage.clear();
        var data = response.data;
        var userData = JSON.stringify(data);

        localStorage.setItem("userData", userData);

        if (response.data.privateKey) {

          const data = { privateKey: response.data.privateKey };
          const request = indexedDB.open('myDatabase', 1);

          request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            const transaction = db.transaction(['myStore'], 'readwrite');
            const store = transaction.objectStore('myStore');
            store.clear();
            const addRequest = store.add(data);

            addRequest.onsuccess = () => {
              //console.log('Data added successfully');
            };

            addRequest.onerror = (event) => {
              console.error('Error adding data', (event.target as IDBOpenDBRequest).error);
            };
          };
        };
       // requestPermission();
        if (response?.data?.status == true) {
          if (data?.permissions?.find((x: any) => x.name == "View_Admin_Dashboard")) {
            // console.log("dashboard", data?.permissions.find((x: any) => x.name == "View_Admin_Dashboard"))
            navigate("/");
          } else if (data?.permissions?.find((x: any) => x.name == "View_My_Profile")) {
            navigate("/profile");
          }
          else {
            checkAllPersmissions(navigate, userData);
          }
        } else {
          setLoginError(response?.data?.message);
          setCustomLoading(false);
        }


        setCustomLoading(false)

      }).catch((reason: any) => {
        setCustomLoading(false)
        if (reason.response) {
          var error = reason.response.data.message
          // var error=reason.response.data.output.error.ErrorMessage;          
          setLoginError(error);
          setCustomLoading(false);
        } else if (reason.message) {

          setCustomLoading(false);
          setLoginError(reason.message);
        }
      });
  };

  useEffect(() => {
    const request = indexedDB.open('myDatabase', 1);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      db.createObjectStore('myStore', { keyPath: 'id', autoIncrement: true });
    };
  }, []);


  // const addDataIndexDB = (key: string) => {
  //   const data = { privateKey: key };
  //   const request = indexedDB.open('vorpDB', 1);

  //   request.onsuccess = (event) => {
  //     const db = (event.target as IDBOpenDBRequest).result;
  //     const transaction = db.transaction(['vorp'], 'readwrite');
  //     const store = transaction.objectStore('vorp');

  //     const addRequest = store.add(data);

  //     addRequest.onsuccess = () => {
  //     };

  //     addRequest.onerror = (event) => {
  //     };
  //   };
  // };

  useEffect(() => {
  }, [loginError])


  const renderErrorMessage = (name: any) => {
    return (
      name == errorMessages.name && (
        <div className="text-red-500 text-15 font-primary">
          {errorMessages.message}
        </div>
      )
    );
  };
  const rememberCheckboxHandler = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const switchEyeHandler = (event: any) => {
    var hideimg = eyehide;
    var showimg = eyeshow;
    const type =
      event.target.previousElementSibling.getAttribute("type") == "password"
        ? "text"
        : "password";
    const img =
      event.target.previousElementSibling.getAttribute("type") == "password"
        ? hideimg
        : showimg;
    event.target.previousElementSibling.setAttribute("type", type);
    event.target.setAttribute("src", img);
  };
  const renderForm = (
    <form
      onSubmit={handleSubmit}
      className="w-full md:w-[440px] px-5"
    >
      <div
        className={classNames(
          "flex justify-start text-left flex-col w-full ",
          styles.signinform
        )}
      >
        <h3 className="text-darkblue text-20 sm:text-30 font-semibold mb-[29px] md:text-left text-center">
          Sign In
        </h3>
        <div
          className={classNames(styles.inputContainer, "md:mb-[29px] mb-4 last:mb-0")}
        >
          <label className="text-black2 text-15 font-medium mb-[12px] block">
            Your Email
          </label>
          <input
            type="text"
            name="email"
            required
            className="htzxs:h-[70px] htzxs:py-0 md:text-sm text-xs py-3 placeholder:text-vorpgraylight placeholder:text-xs md:placeholder:text-14 text-black2 rounded-[9px] px-[24px] w-full focus:outline-none"
            placeholder="visibee@visibleone.com"
            style={{
              border: errorMessages?.name == "email" ? "1px solid red" : "",
            }}
          />
          {renderErrorMessage("email")}
        </div>
        <div
          className={classNames(styles.inputContainer, "htzxs:mb-[29px] mb-4 last:mb-0")}
        >
          <div className="flex items-center mb-[12px]">
            <label
              htmlFor="password"
              className="text-black2 text-15 w-1/2 block "
            >
              Your Password
            </label>
            <a href="/forgetpassword" className="w-1/2">
              <button
                type="button"
                className={classNames(
                  styles.button,
                  "text-15 w-full text-vorpblue text-right"
                )}
                aria-label=""
              >
                Forgot Password?
              </button>
            </a>
          </div>
          <div className="relative">
            <input
              type="password"
              name="password"
              required
              className="htzxs:h-[70px] md:text-sm text-xs htzxs:py-0 py-3 placeholder:text-vorpgraylight  placeholder:text-xs md:placeholder:text-14 text-black2 rounded-[9px] pl-[24px] pr-16 w-full focus:outline-none"
              placeholder="password"
              style={{
                border:
                  errorMessages?.name == "password" ? "1px solid red" : "",
              }}
            />
            <img
              src={eyeshow}
              alt="eye-open"
              className={classNames(
                styles.theeye,
                "absolute top-1/2 right-4 cursor-pointer w-[22px] h-[16px] object-cover"
              )}
              onClick={switchEyeHandler}
            />
          </div>

          {renderErrorMessage("password")}
        </div>
        <div className="">
          <label
            className={classNames(
              styles.customCheckbox,
              "flex items-center self-center min-h-[24px] font-primary text-xs font-semibold text-graydark mr-[22px]"
            )}
          >
            <div className="">
              <input
                checked={isChecked}
                type="checkbox"
                name="remember"
                className="mr-2"
                onChange={rememberCheckboxHandler}
              />
              <span className={styles.checkmark}></span>
            </div>
            Remember Me
          </label>
        </div>
        <div className={classNames(styles.buttonContainer, 'htzxs:mt-[37px] mt-5')}>
          <div className="relative w-full">
            <button
              type="submit"
              className={classNames(customLoading ? 'opacity-50 pointer-events-none' : '', "transition-all hover:bg-white hover:text-vorpblue border border-vorpblue bg-vorpblue text-15 text-white w-full flex items-center justify-center rounded-[6px] py-[0.8rem]")}
            >
              Sign In
            </button>
            <div className={classNames(customLoading ? 'block' : 'hidden', ' absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2')}>
              <CircularProgress />
            </div>
          </div>
        </div>
        {/* <div className="my-4">
          <span className={classNames("flex w-full text-[13px] font-medium text-graydark", styles.loginButtonsContainer)}>or</span>
        </div>
        <button onClick={() => loginSSO()}
          type="button"
          className={classNames("border-1 border-[#F0DDB4] bg-[#FFF7E6] hover:bg-[#F0DDB4] transition-all text-15 text-black2 w-full font-semibold flex items-center justify-center rounded-[6px] py-[0.8rem]")}
        >
          <div className="mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24" fill="none">
              <path d="M20.2085 5.80128L16.7288 3.69794L18.0728 1.88079C18.2154 1.64941 18.2697 1.3706 18.2251 1.09942C18.1828 0.818638 18.0397 0.565219 17.8249 0.390733C17.6174 0.221887 17.356 0.142253 17.0941 0.168133C16.8313 0.191089 16.5862 0.315858 16.4069 0.517934L14.8888 2.57585L10.7523 0.0727324C10.6686 0.0238901 10.5741 -0.00117236 10.4782 4.66873e-05C10.3837 -0.00123411 10.2906 0.0238587 10.2085 0.0727324L6.05023 2.60311L4.51478 0.517934C4.33892 0.302515 4.09054 0.165892 3.82097 0.1363C3.55139 0.106709 3.28123 0.18641 3.06632 0.358933C2.8515 0.533419 2.70841 0.786838 2.66616 1.06762C2.62633 1.35084 2.69338 1.63914 2.85319 1.87171L4.2103 3.71157L0.774034 5.80583C0.691098 5.85616 0.622144 5.92818 0.573951 6.0148C0.526225 6.102 0.500757 6.20056 0.5 6.301V17.7172C0.500091 17.8176 0.525455 17.9162 0.573553 18.0031C0.621651 18.09 0.690794 18.1622 0.774034 18.2124L10.226 23.9228C10.3077 23.973 10.4008 23.9997 10.4956 24C10.5919 23.9996 10.6863 23.973 10.7697 23.9228L20.2259 18.2124C20.3101 18.1622 20.3805 18.0902 20.4304 18.0034C20.4771 17.916 20.5011 17.8173 20.5 17.7172V6.29646C20.4988 6.19403 20.4718 6.09373 20.4217 6.00571C20.3696 5.91871 20.2959 5.84807 20.2085 5.80128ZM19.5213 15.7502L10.5043 20.293L1.49173 15.7502V14.1602L10.5043 18.8893L19.5126 14.1602L19.5213 15.7502ZM19.5213 11.4799L10.5043 16.05L1.49609 11.4799V9.88986L10.5043 14.619L19.5126 9.88986L19.5213 11.4799Z" fill="#FFAE00" />
            </svg>
          </div>
          <span>Login with SSO</span>
        </button> */}
      </div>
      <div className="text-red-500 text-15 font-primary mt-3">
        {loginError}
      </div>
      <div className={classNames(ssoLoading ? 'absolute top-0 left-0 bg-black bg-opacity-10 w-full h-full' : 'hidden')}>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <CircularProgress classes={{ root: styles.customLoading }} />
        </div>
      </div>
    </form>
  );
  const renderFormMobile = (
    <form
      onSubmit={handleSubmitMobile}
      className="w-full md:w-[440px] px-5"
    >
      <div
        className={classNames(
          "flex justify-start text-left flex-col w-full ",
          styles.signinform
        )}
      >
        <h3 className="text-darkblue text-20 sm:text-30 font-semibold mb-[29px] md:text-left text-center">
          Sign In
        </h3>
        <div
          className={classNames(styles.inputContainer, "md:mb-[29px] mb-4 last:mb-0")}
        >
          <label className="text-black2 text-15 font-medium mb-[12px] block">
            Your Email
          </label>
          <input
            type="text"
            name="email"
            required
            className="htzxs:h-[70px] htzxs:py-0 md:text-sm text-xs py-3 placeholder:text-vorpgraylight placeholder:text-xs md:placeholder:text-14 text-black2 rounded-[9px] px-[24px] w-full focus:outline-none"
            placeholder="visibee@visibleone.com"
            style={{
              border: errorMessages?.name == "email" ? "1px solid red" : "",
            }}
          />
          {renderErrorMessage("email")}
        </div>
        <div
          className={classNames(styles.inputContainer, "htzxs:mb-[29px] mb-4 last:mb-0")}
        >
          <div className="flex items-center mb-[12px]">
            <label
              htmlFor="password"
              className="text-black2 text-15 w-1/2 block "
            >
              Your Password
            </label>
            <a href="/forgetpassword" className="w-1/2">
              <button
                type="button"
                className={classNames(
                  styles.button,
                  "text-15 w-full text-vorpblue text-right"
                )}
                aria-label=""
              >
                Forgot Password?
              </button>
            </a>
          </div>
          <div className="relative">
            <input
              type="password"
              name="password"
              required
              className="htzxs:h-[70px] md:text-sm text-xs htzxs:py-0 py-3 placeholder:text-vorpgraylight  placeholder:text-xs md:placeholder:text-14 text-black2 rounded-[9px] pl-[24px] pr-16 w-full focus:outline-none"
              placeholder="password"
              style={{
                border:
                  errorMessages?.name == "password" ? "1px solid red" : "",
              }}
            />
            <img
              src={eyeshow}
              alt="eye-open"
              className={classNames(
                styles.theeye,
                "absolute top-1/2 right-4 cursor-pointer w-[22px] h-[16px] object-cover"
              )}
              onClick={switchEyeHandler}
            />
          </div>

          {renderErrorMessage("password")}
        </div>
        <div className="">
          <label
            className={classNames(
              styles.customCheckbox,
              "flex items-center self-center min-h-[24px] font-primary text-xs font-semibold text-graydark mr-[22px]"
            )}
          >
            <div className="">
              <input
                checked={isChecked}
                type="checkbox"
                name="remember"
                className="mr-2"
                onChange={rememberCheckboxHandler}
              />
              <span className={styles.checkmark}></span>
            </div>
            Remember Me
          </label>
        </div>
        <div className={classNames(styles.buttonContainer, 'htzxs:mt-[37px] mt-5')}>
          <div className="relative w-full">
            <button
              type="submit"
              className={classNames(customLoading ? 'opacity-50 pointer-events-none' : '', "transition-all hover:bg-white hover:text-vorpblue border border-vorpblue bg-vorpblue text-15 text-white w-full flex items-center justify-center rounded-[6px] py-[0.8rem]")}
            >
              Sign In
            </button>
            <div className={classNames(customLoading ? 'block' : 'hidden', ' absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2')}>
              <CircularProgress />
            </div>
          </div>
        </div>
        {/* <div className="my-4">
          <span className={classNames("flex w-full text-[13px] font-medium text-graydark", styles.loginButtonsContainer)}>or</span>
        </div>
        <button onClick={() => loginSSO()}
          type="button"
          className={classNames("border-1 border-[#F0DDB4] bg-[#FFF7E6] hover:bg-[#F0DDB4] transition-all text-15 text-black2 w-full font-semibold flex items-center justify-center rounded-[6px] py-[0.8rem]")}
        >
          <div className="mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24" fill="none">
              <path d="M20.2085 5.80128L16.7288 3.69794L18.0728 1.88079C18.2154 1.64941 18.2697 1.3706 18.2251 1.09942C18.1828 0.818638 18.0397 0.565219 17.8249 0.390733C17.6174 0.221887 17.356 0.142253 17.0941 0.168133C16.8313 0.191089 16.5862 0.315858 16.4069 0.517934L14.8888 2.57585L10.7523 0.0727324C10.6686 0.0238901 10.5741 -0.00117236 10.4782 4.66873e-05C10.3837 -0.00123411 10.2906 0.0238587 10.2085 0.0727324L6.05023 2.60311L4.51478 0.517934C4.33892 0.302515 4.09054 0.165892 3.82097 0.1363C3.55139 0.106709 3.28123 0.18641 3.06632 0.358933C2.8515 0.533419 2.70841 0.786838 2.66616 1.06762C2.62633 1.35084 2.69338 1.63914 2.85319 1.87171L4.2103 3.71157L0.774034 5.80583C0.691098 5.85616 0.622144 5.92818 0.573951 6.0148C0.526225 6.102 0.500757 6.20056 0.5 6.301V17.7172C0.500091 17.8176 0.525455 17.9162 0.573553 18.0031C0.621651 18.09 0.690794 18.1622 0.774034 18.2124L10.226 23.9228C10.3077 23.973 10.4008 23.9997 10.4956 24C10.5919 23.9996 10.6863 23.973 10.7697 23.9228L20.2259 18.2124C20.3101 18.1622 20.3805 18.0902 20.4304 18.0034C20.4771 17.916 20.5011 17.8173 20.5 17.7172V6.29646C20.4988 6.19403 20.4718 6.09373 20.4217 6.00571C20.3696 5.91871 20.2959 5.84807 20.2085 5.80128ZM19.5213 15.7502L10.5043 20.293L1.49173 15.7502V14.1602L10.5043 18.8893L19.5126 14.1602L19.5213 15.7502ZM19.5213 11.4799L10.5043 16.05L1.49609 11.4799V9.88986L10.5043 14.619L19.5126 9.88986L19.5213 11.4799Z" fill="#FFAE00" />
            </svg>
          </div>
          <span>Login with SSO</span>
        </button> */}
      </div>
      <div className="text-red-500 text-15 font-primary mt-3">
        {loginError}
      </div>
      <div className={classNames(ssoLoading ? 'absolute top-0 left-0 bg-black bg-opacity-10 w-full h-full' : 'hidden')}>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <CircularProgress classes={{ root: styles.customLoading }} />
        </div>
      </div>
    </form>
  );
  var isValid = checkToken("");
  if (isValid) {
    navigate("/");
  }
  // end
  return (
    <div
      className={classNames(
        "flex items-stretch h-screen font-primary flex-col md:flex-row md:bg-white bg-darkblackv2",
        styles.logincomponent
      )}
    >
      <div
        className={classNames(
          // styles.leftLogoBox,
          "w-full md:w-2/5 bg-bottom 7xl:bg-auto py-[50px] md:pb-0 bg-no-repeat md:flex hidden"
        )}
        // style={{ backgroundImage: `url(${bglogin})` }}
      >
        <img
          src={logo}
          alt="logo"
          className={classNames("mx-auto lg:w-full max-w-[300px]", styles.companylogo)}
        />
        
      </div>
      <div
        className={classNames(
          "md:hidden block pt-[50px] relative h-full overflow-x-hidden",
        )}
      >
        <div className=" w-full after:z-0 after:content-normal after:absolute after:top-[-30%] after:left-1/2 after:-translate-x-1/2 after:w-[847px] after:h-full after:bg-white after:rounded-[847px]">
          <div className="z-[9999] relative">
            <img
              src={logo}
              alt="logo"
              className={classNames("mx-auto max-w-[300px]", styles.companylogo)}
            />
            {/* <p className="text-vorpgray text-10 md:text-12 xl:text-16 4xl:text-18 font-semibold">
              Visible One Internal Operation System
            </p> */}
          </div>
        </div>
        <div className="md:px-0 px-5 md:top-0 top-[8%] relative pb-[50px]">
          <div
            className={classNames(
              styles.rightLoginBox,
              "flex items-center justify-center w-full md:w-3/5 bg-offwhite relative md:px-0 md:rounded-none rounded-[20px]"
            )}
          >
            {isSubmitted ? (
              <div className="text-black2 text-15 font-primary">
                User is successfully logged in
              </div>
            ) : (
              renderForm
            )}
          </div>
        </div>
      </div>
      <div className="md:flex items-center justify-center hidden w-full md:w-3/5 bg-offwhite">
        <div
          className={classNames(
            styles.rightLoginBox,
            " bg-offwhite relative md:px-0 md:rounded-none rounded-[20px]"
          )}
        >
          {isSubmitted ? (
            <div className="text-black2 text-15 font-primary">
              User is successfully logged in
            </div>
          ) : (
            renderFormMobile
          )}
        </div>
      </div>
    </div>
  );
};

export default LogInComponent;
