import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetMobileLogoQuery } from "../../app/services/mobilelogo";
import CreatePopupLogo from "../../components/ProjectStatusPopup/CreatePopupLogo";
import axios from "../../axios";
import { getAuthUser } from "../../app/services/dashboard";
import { endpointUrl } from "../../app/services/api";
import { toast } from "react-toastify";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import { EditIcon } from "../../components/common/Icons";

const MobileLogo = () => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editedData, setEditData] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const { data: logos } = useGetMobileLogoQuery();
  const [commonMessage, setCommonMessage] = useState<any>([]);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  var getUser = getAuthUser();
  useEffect(() => {
    setEditData(logos?.logoData);
  }, [logos]);
  const saveMobileLogo = async (data: any) => {
    const url = endpointUrl + "mobilelogos";
    await axios(url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
      data: data,
    })
      .then((res: any) => {
        if (res.data.status == true) {
          toast("Successfully Created.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
          setIsOpen(false);
          setIsSubmit(false);
          setErrorPopup(false);
          setUploading(false);
          setEditData(res?.data?.logoData);
        }
      })
      .catch((error) => {
        setErrorPopup(true);
        setIsOpen(true);
        setIsSubmit(false);
        setCommonMessage(error?.response?.data?.message);
      });
  };
  const addMobileLogo = (data: any) => {};
  return (
    <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
      <div>
        <button
          type="button"
          onClick={() => {
            setIsOpen(true);
          }}
          className="w-6 h-6 mb-10 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
        >
          <EditIcon />
        </button>
        <div>
          <div className="mb-5 last:mb-0">
            <p className="text-left text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">
              Logo
            </p>
            {editedData?.logo ? <img src={editedData?.logo} alt="logo" className="max-w-[300px]"/> : ""}
          </div>
          <div className="mb-5 last:mb-0">
            <p className="text-left text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">
              Banner
            </p>
            {editedData?.banner ? (
              <img src={editedData?.banner} alt="banner" className="max-w-[300px]"/>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <CreatePopupLogo
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        editedData={editedData}
        setEditData={setEditData}
        setIsSubmit={setIsSubmit}
        isSubmit={isSubmit}
        setUploading={setUploading}
        savePopupData={saveMobileLogo}
        title="Mobile Logo"
        updatePopupData={saveMobileLogo}
        uploading={uploading}
      />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={commonMessage}
      />
    </div>
  );
};
export default MobileLogo;
