// import React from "react";
import styles from "./EmailSentComponent.module.scss";
//import logo from '../../login/img/companyLogo.svg';
import logo from '../../login/img/companyLogo.svg';
var classNames = require("classnames");
const EmailSentComponent = () => {
  const renderForm = (
    <div className={classNames("flex justify-start flex-col w-full px-5 htzxs:w-[439px] htzxs:px-0",styles.signinform)}>
      <div className="mx-auto mb-[32px]">
        <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50.5" cy="50" r="48.5" stroke="#4E9B47" stroke-width="3"/>
          <path d="M33.1562 52.9062L46.7188 66.4688L73.8438 37.4062" stroke="#4E9B47" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <h3 className="text-darkblue text-20 3xs:text-25 sm:text-30 font-semibold leading-[1.2]">Password reset email has been sent</h3>
      <h6 className="font-primary font-normal text-black2 text-15 mt-[24px] leading-[1.2]">We’ve sent the password reset email to your mailbox. Please follow the email instruction to reset your password.</h6>
      {/* <Link to="/login" className="w-full">
          <button className={classNames(styles.button,"bg-vorpblue text-15 text-white w-full flex items-center justify-center mt-[32px] rounded-[6px] py-[0.8rem] font-semibold font-primary")} aria-label="">
            Sign In
          </button>
      </Link> */}
    </div>
  )
  return (
    <div
    className={classNames(
      "flex items-stretch h-screen font-primary flex-col md:flex-row",
      styles.logincomponent
    )}
  >
    <div
      className={classNames(
        // styles.leftLogoBox,
        "w-full md:w-2/5 bg-bottom 7xl:bg-auto py-[50px] md:pb-0 bg-no-repeat flex"
      )}
     // style={{ backgroundImage: `url(${bglogin})` }}
    >
      <img
        src={logo}
        alt="logo"
        className={classNames("mx-auto lg:w-full max-w-[300px]", styles.companylogo)}
      />
      {/* <p className="text-vorpgray text-10 md:text-12 xl:text-16 4xl:text-18 font-semibold">
        Visible One Internal Operation System
      </p> */}
    </div>
    <div
      className={classNames(
        styles.rightLoginBox,
        "flex items-center justify-center w-full md:w-3/5 bg-offwhite"
      )}>
        {renderForm}         
    </div>
  </div>
    )
}

export default EmailSentComponent;