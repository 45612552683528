import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type DropdownDataWithLanguage = {
  id: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
};
type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  typeTableData: TData;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined,
    search: string | number,
    sort?:string;
}
export type TaskCategoriesList = {
  categoryList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};
export type IFaqTypes={
  typeList:any[]
}

export const faqTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFAQTypeData: build.query<DepartmentsResponse, PaginationData>({
      query: ({limit, page,search}) => ({
        url: `types?limit=${limit}&page=${page}&search=${search}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getAllFAQTypeData: build.query<IFaqTypes, void>({
      query: () => ({
        url: `getTypeList`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createFAQType: build.mutation<
    TaskCategoriesList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `types`,
          body,
          method: "POST",
        };
      },
    }),
    updateFAQType: build.mutation<
    TaskCategoriesList,
      DropdownDataWithLanguage
    >({
      query(body) {
        return {
          headers: authHeader(),
          url: `types/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteFAQType: build.mutation<TaskCategoriesList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/types/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "FAQ", id: "LIST" }];
      },
    }),
  }),
});

export const {
    useGetFAQTypeDataQuery,
    useCreateFAQTypeMutation,
    useUpdateFAQTypeMutation,
    useDeleteFAQTypeMutation,
    useGetAllFAQTypeDataQuery
} = faqTypeApi;

export const {
  endpoints: {
    getFAQTypeData,
    createFAQType,
    updateFAQType,
    deleteFAQType,
    getAllFAQTypeData
  },
} = faqTypeApi;
