import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type UsefulLineData = {
  id: number;
  name_en: string;
  name_tc: string;
  name_sc: string;
  link_en: string;
  link_tc: string;
  link_sc: string;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
export type PaginationData = {
  page: number;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export type TaskCategoriesList = {
  categoryList: TaskDepartmentListData[];
};
export type ManualData = {
  usefulLinkTableData: TData;
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const usefulLineApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsefulLines: build.query<ManualData, PaginationData>({
      query: ({ limit, page, search }) => ({
        url: `usefullinks?limit=${limit}&page=${page}&search=${search}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    createUsefulLine: build.mutation<ManualData, UsefulLineData>({
      query(body) {
        return {
          headers: authHeader(),
          url: `usefullinks`,
          body,
          method: "POST",
        };
      },
    }),
    updateUsefulLine: build.mutation<TaskCategoriesList, UsefulLineData>({
      query(body) {
        return {
          headers: authHeader(),
          url: `usefullinks/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteUsefulLine: build.mutation<TaskCategoriesList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/usefullinks/${id}`,
          method: "DELETE",
        };
      },
    }),
    multiDeleteUsefulLine: build.mutation<TaskCategoriesList, string>({
      query: (id) => ({
          url: `usefullinks`,
          headers: authHeader(),
          method: 'DELETE',
          body: id
      }),
  }),
  }),
});

export const {
  useGetUsefulLinesQuery,
  useCreateUsefulLineMutation,
  useUpdateUsefulLineMutation,
  useDeleteUsefulLineMutation,
  useMultiDeleteUsefulLineMutation
} = usefulLineApi;

export const {
  endpoints: { getUsefulLines, createUsefulLine,
     updateUsefulLine, deleteUsefulLine,multiDeleteUsefulLine },
} = usefulLineApi;
