import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react";
import CommonTableSkeleton from "../../TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import styles from './MyEquipment.module.scss'
import { NumberDropdownList } from "../../common/DropdownList";
import { ColumnDef, ColumnFiltersState, PaginationState, RowSelectionState, SortingState, createColumnHelper } from "@tanstack/react-table";
import CustomDropdownListForFilter from "../../EmployeeTable/CustomDropdownListForFilter";
import { twMerge } from "tailwind-merge";
import { baseObj } from "../../../utils/constants";
import classNames from "classnames";
import { ViewIcon } from "../../common/Icons";
import LayoutAdjustableTable from "../../Tables/LayoutAdjustableTable";
import { IEquipmentLog, IViewEquipment, dummyData } from "../../../equipments/views/Equipments";
import EquipmentViewPopup from "../../../equipments/views/EquipmentViewPopup";
import TablePaginator from "../../Tables/TablePaginator";
interface IMyEquipmentTable {
    data: baseObj[];
    setData: React.Dispatch<React.SetStateAction<baseObj[]>>;
    equipmentLogs: IEquipmentLog[];
    getEquipmentsLogs: any;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    total: number;
    setCurrentPage: any;
    currentPage: number;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    hiddenFields: string[];
}
const MyEquipmentTable: FC<IMyEquipmentTable> = ({
    data,
    setData,
    equipmentLogs,
    getEquipmentsLogs,
    currentPage,
    setCurrentPage,
    columnFilters,
    setColumnFilters,
    total,
    sorting,
    setSorting,
    pagination,
    setPagination,
    hiddenFields

}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [itemCount, setItemCount] = useState<number>(dummyData.length);
    const [viewData, setViewData] = useState<IViewEquipment>();
    const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
    const viewEquipment = useCallback((cell: any) => {
        getEquipmentsLogs(cell.row.original.equipment_id);
        setViewData(cell.row.original)
        setIsViewOpen(true)
    }, [])
    const columns = useMemo(() => {
        return [
            columnHelper.accessor("equipment_code", {
                header: "Equipment ID",
                id: "equipment_code",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const equipment_code = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{equipment_code}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("serialno", {
                header: "Serial Number",
                id: "serialno",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const serialno = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className="h-full flex items-center">{serialno}</p>
                            </div>
                        </div>
                    );
                },
            }),

            columnHelper.accessor("category", {
                header: "Category",
                id: "category",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const value = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className="h-full flex items-center">{value?.name}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("name", {
                header: "Equipment Name",
                id: "name",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const value = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className="h-full flex items-center">{value}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("employee", {
                header: "Employee",
                id: "employee",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const employee = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className="h-full flex items-center">{employee?.name}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("application_type", {
                header: "Application Type",
                id: "application_type",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const application_type = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className="h-full flex items-center">{application_type}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("assign_date", {
                header: "Date Borrowed/Assigned",
                id: "assign_date",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const assign_date = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className="h-full flex items-center">{assign_date}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("expected_return_date", {
                header: "Expected Return Date",
                id: "expected_return_date",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const expected_return_date = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className={twMerge(expected_return_date?.isOverdue == true ? 'text-redPink' : "h-full flex items-center")}>{expected_return_date?.date}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("return_date", {
                header: "Actual Return Date",
                id: "return_date",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const return_date = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className="h-full flex items-center">{return_date}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("status", {
                header: "Status",
                id: "status",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const status = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className={twMerge(status == "Approved" ? 'text-vorpgreen' : status == "Pending" ? 'text-black2' : 'text-redPink', "h-full flex items-center")}>{status}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("reason", {
                header: "Reason",
                id: "reason",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const reason = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className="h-full flex items-center">{reason}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("return_remark", {
                header: "Remark",
                id: "return_remark",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const return_remark = getValue();
                    return (
                        <div className="min-w-[140px] text-left">
                            <div className="  text-left bg-white text-black2 px-[10px]">
                                <p className="h-full flex items-center">{return_remark}</p>
                            </div>
                        </div>
                    );
                },
            }),
            columnHelper.display({
                header: "Action",
                id: "actions",
                cell: ({ cell }) => {
                    var isHaveNew: any = data?.find((x) => x.isNew == true);
                    var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
                    const isNew: boolean = cell?.row?.original?.isNew;
                    const is_own: number = cell?.row?.original?.is_own;
                    return (
                        <div
                            className={classNames(
                                // stylesEmp.actionedBtn,
                                "flex justify-center h-full items-center min-w-max max-w-[98px]"
                            )}
                        >
                            <button
                                onClick={() => {
                                    viewEquipment(cell)
                                }}
                                className={classNames(
                                    styles.customViewdBtn,
                                    "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-darkpurple hover:bg-[#EAE0FF] transition-all duration-300"
                                )}
                            >
                                <ViewIcon />
                            </button>
                        </div>
                    );
                },
            }),
        ];
    }, [columnHelper, data]);
    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );
    const visibleColumns: { [key: string]: boolean } = {};

    hiddenFields?.map((x: any) => {
        visibleColumns[x] = true;
    });

    if (hiddenFields.length > 0) {
        columns.map((x: any) => {
            if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
                visibleColumns[x.id ? x.id : ""] = true;
            } else if (x.id == "uniqeID") {
                visibleColumns[x.id ? x.id : ""] = false;
            } else if (x.id?.includes("none")) {
                visibleColumns[x.id ? x.id : ""] = true;
            } else {
                var isHidden = hiddenFields.find((h) => h == x.id);
                if (isHidden) {
                    visibleColumns[x.id ? x.id : ""] = true;
                } else {
                    visibleColumns[x.id ? x.id : ""] = false;
                }
            }
        });
    }
    return (
        <>
            <EquipmentViewPopup setIsOpen={setIsViewOpen}
                isOpen={isViewOpen}
                editedData={viewData}
                equipmentLogs={equipmentLogs}
            />
            <LayoutAdjustableTable
                columns={columns}
                data={data}
                sorting={sorting}
                setSortingState={setSorting}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                unDraggableFields={["actions", "selection", "id"]}
                theaderColor="text-graydark"
                label="Equipment List"
                setItemCount={setItemCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                noPaddingCells={[
                    "department",
                    "designation",
                    "gender",
                    "nationality",
                    "status",
                    "currency_id",
                ]}
            />
            <div className="pt-6">
                <TablePaginator
                    total={itemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    itemCount={total}
                    setCurrentPage={setCurrentPage}
                    currentPageDefault={currentPage}
                    changePage={()=>1}
                />
            </div>
        </>
    );
};

export default MyEquipmentTable;
