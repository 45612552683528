import { Dispatch, FC, SetStateAction, useState, useEffect, memo, useRef } from "react";
import { CircularProgress, Modal } from "@mui/material";
import whiteCancel from "../common/img/white-cancel.svg";
import DOMPurify from "dompurify";
import classNames from "classnames";
import { CloseIcon, SendIcon } from "../common/Icons";
import CustomDatePicker from "../common/BaseDatePicker";
import BaseRadioButton from "../common/BaseRadioButton";
import { StringDropdownList } from "../common/DropdownList";
import Button from "../common/Button";
import { IDepartment } from "../../ot-list/views/OTList";
import styles from "./OTListTable.module.scss";
import { renderDateFormatDMY, renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
import { baseObj } from "../../utils/constants";
import { StringDropdownListWithSearch } from "../common/DropdownListWithSearch";
import { StringMultipleSelectBox } from "../common/MultipleSelectBox";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';

interface IOTModal {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    totalLength: number;
    departments: IDepartment[];
    saveData: (data: any) => void;
    assignUserListData: baseObj[];
    setDept: Dispatch<SetStateAction<string>>;
    dept: string;
    setEmployeeData: Dispatch<SetStateAction<any>>;
    employeeData: any;
    uploading: boolean;
    setUploading: Dispatch<SetStateAction<boolean>>;
    setSkip: Dispatch<SetStateAction<boolean>>;
    data: baseObj[];
    setEmployeeDataID: any;
}

interface IFilterStates {
    tag: string;
}

const otTypeList = [
    {
        id: 1,
        name: "OverTime Pay",
    },
    {
        id: 2,
        name: "Substitution Leave",
    },
];
const OTCreatePopupUpdate: FC<IOTModal> = ({
    isOpen,
    setIsOpen,
    totalLength,
    departments,
    saveData,
    assignUserListData,
    setDept,
    dept,
    setEmployeeData,
    employeeData,
    uploading,
    setUploading,
    setSkip,
    data,
    setEmployeeDataID
}) => {
    const [fromDate, setFromDate] = useState<Date | undefined>(new Date());
    const [toDate, setToDate] = useState<Date | undefined>(new Date());
    const [leaveDuration, setLeaveDuration] = useState<string>("Full");
    const [userList, setUserList] = useState<baseObj[]>([]);
    const [totalDay, setTotalDay] = useState<number>(0);
    const [typeOT, setTypeOT] = useState<any[]>([]);
    const [otType, setOTType] = useState<string>("");
    const [otTypeArr, setOTTypeArr] = useState<any[]>(otTypeList);
    const [reason, setReason] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<string[]>([]);
    const [hour, setHour] = useState<number>(0);
    const hourRef: any = useRef(null);
    useEffect(() => {
        setToDate(fromDate);
    }, [fromDate]);

    useEffect(() => {
        setToDate(toDate);
    }, [toDate]);

    useEffect(() => {
        var diff = 0;
        var startDate: any = fromDate?.getTime();
        var endDate: any = toDate?.getTime();

        if (endDate && startDate) {
            diff = endDate - startDate;
        }
        if (toDate == undefined) {
            setToDate(fromDate);
            diff = 1;
        }

        if (startDate != undefined && endDate != undefined) {
            if (leaveDuration) {
                if (leaveDuration.includes("Half")) {
                    diff = diff / (1000 * 3600 * 24) / 2;
                    if (startDate === endDate) {
                        diff = 0.5;
                    }
                    if (endDate > startDate) {
                        diff += 0.5;
                    }
                } else {
                    diff = diff / (1000 * 3600 * 24);
                    if (startDate === endDate) {
                        diff = 1;
                    }
                    if (endDate > startDate) {
                        diff += 1;
                    }
                }
                setTotalDay(Math.ceil(diff));
            }
        }
    }, [toDate, fromDate, leaveDuration]);

    useEffect(() => {
        const data = otTypeArr.filter((f: any) => f.id == otType);
        setTypeOT(data);
    }, [otType]);

    useEffect(() => {
        if (assignUserListData) {
            setUserList(assignUserListData);
        }
    }, [assignUserListData]);

    const saveOTData = () => {
        const clean_reason = reason ? DOMPurify.sanitize(reason) : "";
        var data = {
            // from_date: renderDateFormatYMD(fromDate ? fromDate : new Date(), "-"),
            // to_date: renderDateFormatYMD(toDate ? toDate : new Date(), "-"),
            dates: selectedDate,
            period: leaveDuration,
            type: typeOT[0] ? typeOT[0].name : "",
            reason: clean_reason,
            department_id: dept,
            total_hours: hourRef?.current?.value
        };
        saveData(data);

    };
    const clearData = () => {
        setEmployeeData([]);
        setEmployeeDataID([])
        setFromDate(new Date());
        setToDate(new Date());
        setLeaveDuration('Full');
        setOTType('');
        setDept('');
        setReason('');
        setSelectedDate([]);
        if (hourRef.current) {
            hourRef.current.value = '';
        }

    }
    useEffect(() => {
        clearData();
    }, [isOpen])

    const calendarRef: any = useRef(null);
    const handleSelectedDate = () => {
        selectedDate?.map((selected) => {
            document.querySelector('td[data-date="' + selected + '"]')?.classList.add('selected-date');
        })
    }
    const handleChangeNext = () => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.next();
            handleSelectedDate()
        }

    }
    const handleChangePrev = () => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.prev();
            handleSelectedDate()
        }

    }
    const clearSelectedDate = (date: string) => {
        setSelectedDate(selectedDate.filter(x => x != date));
        document.querySelector('td[data-date="' + date + '"]')?.classList.remove('selected-date');
    }
    const isValidTime = (time: string) => {
        const timePattern = /^([01]\d|2[0-3])$/;
        const timePatternMin = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return (timePattern.test(time) || timePatternMin.test(time));
    }
    const inputChange = (event: any) => {
        const input = event.target;
        let value = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        if (value.length == 2) {
            if (value > 24) {
                const addzero = (0 + value.slice(0, 1));
                value = addzero.slice(0, 2);
            }
        }

        if (value.length > 4) {
            value = value.slice(0, 4); // Limit to 4 digits (hhmm)
        }

        if (value.length >= 3) {
            value = value.slice(0, 2) + ':' + value.slice(2); // Insert colon
        }

        input.value = value;
        setHour(value);
    }

    return (
        <Modal
            open={isOpen}
            style={{
                background: "rgb(0 0 0 / 0.3)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            onClose={(event, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                    setIsOpen(false);
                    setUploading(false);
                    setSkip(false);
                    clearData();
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalContent lg:w-[1044px] max-w-[90%] max-h-[90vh] annoucement-modal w-full ot_modal">
                <div
                    id="modal-modal-title"
                    className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
                >
                    <h3 className="text-18 font-primary font-semibold text-white">
                        Add New OT
                    </h3>
                    <button
                        className="flex items-center justify-center"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        <img src={whiteCancel} alt="close modal icon" />
                    </button>
                </div>

                <div
                    id="modal-modal-description"
                    className="py-9 pl-5 pr-2 bg-white rounded-b-xl"
                >
                    <div className="lg:flex  max-h-[56vh] overflow-y-auto pr-3">
                        <div
                            className={classNames(
                                //styles.verticalLine,
                                "lg:w-1/2 w-full block lg:border-r border-r-vorpgraylight lg:pr-5 h-full"
                            )}
                        >
                            <div className="w-full">
                                <div className="mb-8">
                                    <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                                        OT Type
                                    </h4>
                                    <div className="flex flex-wrap items-center">
                                        <BaseRadioButton
                                            state={leaveDuration}
                                            setState={setLeaveDuration}
                                            label="Full"
                                            value="Full"
                                            // value="full"
                                            id="full"
                                            className="mr-2 xs:mr-4 sm:mr-8"
                                            fontSize="xl:text-15 text-12"
                                            name="leave-duration"
                                        />
                                        <BaseRadioButton
                                            state={leaveDuration}
                                            setState={setLeaveDuration}
                                            label="Half"
                                            value="Half"
                                            // value="half-am"
                                            id="half-am"
                                            className="mr-2 xs:mr-4 sm:mr-8"
                                            fontSize="xl:text-15 text-12"
                                            name="leave-duration"
                                        />
                                        <div className="flex items-center">
                                            <BaseRadioButton
                                                state={leaveDuration}
                                                setState={setLeaveDuration}
                                                label="By Hour"
                                                value="Hourly"
                                                // value="half-pm"
                                                id="half-pm"
                                                className="xs:mr-4 mr-2"
                                                fontSize="xl:text-15 text-12"
                                                name="leave-duration"
                                            />
                                            <div className="xl:px-[10px] px-2 py-2 border border-goalinputborder rounded-4 flex max-w-[80px] items-center">
                                                <input type="text" onInput={(e) => {
                                                    inputChange(e)
                                                }} ref={hourRef} placeholder="00"
                                                    className={classNames(leaveDuration == 'Hourly' ? '' : 'pointer-events-none opacity-50', "font-normal lg:text-sm text-xs border-none focus-within:outline-none focus:outline-none focus-visible:outline-none max-w-[43px] mr-1")} />
                                                <span className="font-normal lg:text-sm text-xs text-graydark">HR</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                                        Please pick a date(s)
                                    </h4>
                                    <div className="flex flex-wrap bg-anncolor px-[10px] py-7 rounded-xl">
                                        <div className="sm:w-[70%] w-full">
                                            <div className="ot-create-popup sm:pr-[10px] sm:border-r border-r-vorpgraylight sm:border-b-0 border-b border-b-vorpgraylight sm:pb-0 pb-5">
                                                <FullCalendar headerToolbar={
                                                    {
                                                        start: 'prevCustomButton',
                                                        center: 'title',
                                                        end: 'nextCustomButton',
                                                    }
                                                }
                                                    customButtons={{
                                                        prevCustomButton: {
                                                            icon: "chevron-left",
                                                            click: function () {
                                                                handleChangePrev();
                                                            },
                                                        },
                                                        nextCustomButton: {
                                                            icon: "chevron-right",
                                                            click: function () {
                                                                handleChangeNext();
                                                            },
                                                        },
                                                    }}
                                                    ref={calendarRef} plugins={[dayGridPlugin, interactionPlugin]}
                                                    dateClick={(arg) => {
                                                        const clickDate = arg.dateStr;
                                                        document.querySelector('td[data-date="' + clickDate + '"]')?.classList.toggle('selected-date');
                                                        if (selectedDate.find(x => x == clickDate)) {
                                                            setSelectedDate(selectedDate.filter(x => x != clickDate))
                                                        }
                                                        else {
                                                            setSelectedDate([...selectedDate, clickDate])
                                                        }
                                                    }}
                                                    contentHeight="auto"
                                                    height="auto"
                                                    fixedWeekCount={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:w-[30%] w-full pl-[10px] sm:mt-0 mt-5">
                                            <p className="font-normal text-sm text-[#1B3757] mt-[10px] mb-5 text-center">Dates</p>
                                            <ul>
                                                {selectedDate?.map((selected: string, key: number) => {
                                                    return <li key={key} className="w-[120px] mx-auto font-normal text-sm flex items-center text-white px-2 py-1 rounded-3xl bg-darkblue mb-1 justify-center">
                                                        <span className="4xl:mr-3 mr-1">{renderDateFormatDMY(new Date(selected), '/')}</span>
                                                        <span onClick={() => clearSelectedDate(selected)} className="block cursor-pointer">&times;</span>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/2 w-full lg:pl-5 lg:mt-0 mt-5">
                            <div className={`mb-5 relative ${styles.otCreatedPopup}`}>
                                <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                                    Department<span className="text-redPink">*</span>
                                </label>
                                <StringDropdownList
                                    isOTList={true}
                                    setSkip={setSkip}
                                    state={dept}
                                    setState={setDept}
                                    placeholder="Select Department"
                                    className="w-full py-[12px] pl-3 mb-3 text-14"
                                    dropdownItems={departments?.map((status: any) => ({
                                        value: status.id,
                                        label: status.name,
                                    }))}
                                    textAlign={"text-left"}
                                    fontSize="xl:text-15 text-12"
                                />
                            </div>
                            <div className="">
                                <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                                    Employee<span className="text-redPink">*</span>
                                </label>
                                <div className="flex">
                                    <div className="w-full">
                                        <div className="mb-5">
                                            {/* <StringDropdownListWithSearch
                        isShow={isShow}
                        setIsShow={setIsShow}
                        setSearchData={setSelectedMember}
                        state={employeeData}
                        setState={setEmployeeData}
                        dropdownItems={userList?.map((member) => {
                          return {
                            label: member?.employee_name,
                            value: member?.employee_id,
                            photo: member?.photo,
                            email: member?.email,
                          };
                        })}
                        className={classNames(
                          styles.memberDropdownList,
                          "w-full min-w-[100px] 2xl:min-w-[128px]  xl:text-sm text-xs"
                        )}
                        placeholder="Select Employee"
                        textAlign="text-left py-2 pl-2 pr-3"
                      /> */}
                                            <CommonTagDropdown
                                                placeholder="Select Employee"
                                                isCreate={false}
                                                showAll={true}
                                                state={employeeData ? employeeData : []}
                                                setState={setEmployeeData}
                                                setSelected={setEmployeeDataID}
                                                options={userList?.map((member) => {
                                                    return {
                                                        label: member?.employee_name,
                                                        value: member?.employee_id,
                                                    };
                                                })}
                                                className="border-goalinputborder 6xs:text-goalinputborder text-14"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                                    Claim Type<span className="text-redPink">*</span>
                                </h4>
                                <StringDropdownList
                                    state={otType}
                                    setState={setOTType}
                                    placeholder="Select OT Pay Type"
                                    className="w-full py-[12px] pl-3 mb-3 text-14"
                                    dropdownItems={otTypeArr?.map((status: any) => ({
                                        value: status.id,
                                        label: status.name,
                                    }))}
                                    textAlign={"text-left"}
                                    fontSize="xl:text-15 text-12"
                                />

                                <textarea
                                    onChange={(ev) => setReason(ev.target.value)}
                                    onBlur={(ev) => setReason(ev.target.value)}
                                    rows={5}
                                    className="placeholder:text-vorpgraylight border border-vorpgraylight w-full rounded-sm focus:outline-none p-3 xl:text-15 text-12 leading-5 text-graydark resize-none mb-5"
                                    placeholder="Reason of OT"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 last:mb-0 flex items-center">
                        <Button
                            type="button"
                            label={
                                <span className="flex items-center text-12 leading-[18px] relative justify-between">
                                    {uploading ? (
                                        <div
                                            className={classNames(
                                                uploading ? "block" : "hidden",
                                                " absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2"
                                            )}
                                        >
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                                    )}
                                    {uploading ? "Uploading" : "Submit"}
                                </span>
                            }
                            onClick={() => { saveOTData(); }}
                            variant="no-outline"
                            size="lg"
                            customClass={classNames(
                                uploading ? "pointer-events-none opacity-50 " : "",
                                "hover:bg-vorpblue mr-2"
                            )}
                        />
                        <Button
                            type="button"
                            label={
                                <span className="flex items-center text-12 leading-[18px] ">
                                    <CloseIcon
                                        width={14}
                                        height={14}
                                        className="mr-[10px] [&_path]:fill-graydark"
                                    />
                                    Cancel
                                </span>
                            }
                            onClick={() => {
                                setIsOpen(false);
                                setUploading(false);
                                setSkip(false);
                                clearData();
                            }}
                            variant="no-outline"
                            size="lg"
                            customClass="min-w-[112px] min-h-[43.6px] bg-offwhite text-graydark border-none"
                        />
                    </div>
                </div>

            </div>
        </Modal>
    );
};

export default memo(OTCreatePopupUpdate);
