// import { Modal } from "@material-ui/core";
import { CircularProgress, Modal } from "@mui/material"

import classNames from "classnames";
import React, {
  createRef,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { CloseIcon } from "../../components/common/Icons";
import { twMerge } from "tailwind-merge";
import BaseRadioButton from "../../components/common/BaseRadioButton";
import CustomDatePicker from "../../components/common/BaseDatePicker";
import Button from "../../components/common/Button";
import { CommonTagDropdown } from "../../components/common/CommonTagDropdown";
import { EquipmentObjectKeys, IEquipmentObj } from "../../app/services/equipments";
import { StringDropdownList } from "../../components/common/DropdownList";
import classes from './EquipmentPopup.module.scss'
import { IEquipment } from "./Equipments";
import { renderDateFormatYMD } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
interface ICreateData {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  tags: EquipmentObjectKeys[];
  categories: EquipmentObjectKeys[];
  locations: EquipmentObjectKeys[];
  departments: EquipmentObjectKeys[];
  employees: EquipmentObjectKeys[];
  editedData: IEquipment | undefined;
  createEquipmentTag: any;
  storeEquipment: any;
  errors: any;
  setEditedData: React.Dispatch<React.SetStateAction<IEquipment | undefined>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setErrors: React.Dispatch<any>;
}
const EquipmentPopup: FC<ICreateData> = ({ isOpen, setIsOpen, tags, categories, locations, departments, employees,
  editedData, createEquipmentTag, storeEquipment, errors, setEditedData, isLoading, setIsLoading, setErrors }) => {
  const [tab, setTab] = useState<number>(1);
  const [usageDuration, setUsageDuration] = useState<Date | undefined>();
  const [warantyFromDate, setWarantyFromDate] = useState<Date | undefined>();
  const [licenseFromDate, setLicenseFromDate] = useState<Date | undefined>();
  const [warantyToDate, setWarantyToDate] = useState<Date | undefined>();
  const [licenseToDate, setLicenseToDate] = useState<Date | undefined>();
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [condition, setCondition] = useState<string>('Damaged');
  const [licenseType, setLicenseType] = useState<string>('Subscription');
  const [application, setApplication] = useState<string>('Borrow');
  const [category, setCategory] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [department, setDepartment] = useState<string>('');
  const [employee, setEmployee] = useState<string>('');
  const [uploadFile, setUploadFile] = useState<string>('');
  const [updatedData, setUpdatedData] = useState<any>([]);
  const [imgError, setImgError] = useState<string>('');
  const equipmentFile = useRef<any>(null);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const clearData = () => {
    setEditedData(undefined);
    setUpdatedData(undefined);
    setChosenTags([]);
    setCategory('0');
    setApplication('Borrow');
    setLicenseType('Subscription');
    setCondition('Damaged');
    setUsageDuration(undefined);
    setLocation('0');
    setUploadFile('')
    setWarantyFromDate(undefined);
    setWarantyToDate(undefined);
    setLicenseToDate(undefined);
    setLicenseFromDate(undefined);
    setIsSubmit(false);
    setIsLoading(false);
    setErrors({})
  }
  const changeEquipmentTab = (id: number) => {
    setTab(id);
  }
  const uploadEquipmentFile = () => {
    const file = equipmentFile?.current.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setImgError('Only allow image file.')
      } else {
        setImgError('')
      }
    }
    setUploadFile(file?.name)
  }
  useEffect(() => {
    if (editedData) {
      setChosenTags(editedData?.tags.map(x => x.name))
      setEmployee(editedData?.employee?.id?.toString());
      setCondition(editedData?.condition);
      setLocation(editedData?.location?.id.toString());
      setCategory(editedData?.category?.id.toString());
      setDepartment(editedData?.department?.id?.toString());
      setWarantyFromDate(editedData?.warranty?.fromDate ? new Date(editedData?.warranty?.fromDate) : undefined);
      setWarantyToDate(editedData?.warranty?.toDate ? new Date(editedData?.warranty?.toDate) : undefined);
      setLicenseFromDate(editedData?.license_date?.fromDate ? new Date(editedData?.license_date?.fromDate) : undefined);
      setLicenseToDate(editedData?.license_date?.toDate ? new Date(editedData?.license_date?.toDate) : undefined);
      setUsageDuration(editedData?.usage_duration ? new Date(editedData?.usage_duration) : undefined);
      setLicenseType(editedData?.license);
      setApplication(editedData?.application);
      const img = editedData?.image?.split('/');
      setUploadFile(img ? img[img.length - 1] : '')
      setUpdatedData({
        "id": editedData.id,
        "equipmentID": editedData?.code,
        "serialno": editedData?.serialno,
        "name": editedData?.name,
        "availablity": updatedData?.availability,
        "date": editedData?.date,
        "return_date": { isOverdue: false, date: '' },
        "version": editedData?.version ? (editedData?.version == "undefined" ? '' : editedData?.version) : '',
        "service_provider": editedData?.service_provider ? (editedData?.service_provider == "undefined" ? '' : editedData?.service_provider) : '',
        "pricing_detail": editedData?.pricing_detail ? (editedData?.pricing_detail == "undefined" ? '' : editedData?.pricing_detail) : '',
        "warranty": warantyFromDate,
        "license": licenseType,
        "license_date": licenseFromDate,
        "remark": editedData?.remark ? (editedData?.remark == "undefined" ? '' : editedData?.remark) : '',
        "usage_duration": usageDuration?.toString(),
        "application": application
      })
    }
  }, [editedData])
  const changeInputData = (event: any) => {
    setUpdatedData({ ...updatedData, [event.target.name]: event.target.value })

  }
  useEffect(() => {
    if (!editedData)
      clearData()
  }, [isOpen])
  const handleConfirm = async () => {
    setIsLoading(true);
    setIsSubmit(true);
    const body: IEquipmentObj = {
      id: editedData ? editedData.id : 0,
      serial_number: updatedData?.serialno,
      equipment_category_id: category,
      tags: chosenTags?.map(x => x),
      name: updatedData?.name,
      availablity: updatedData?.availability,
      condition: condition,
      working_location_id: location,
      version: updatedData?.version,
      service_provider: updatedData?.service_provider,
      price_detail: updatedData?.pricing_detail,
      warranty_start_date: warantyFromDate ? renderDateFormatYMD(warantyFromDate, '-') : '',
      warranty_end_date: warantyToDate ? renderDateFormatYMD(warantyToDate, '-') : '',
      license_type: licenseType,
      license_validaty_start_date: licenseFromDate ? renderDateFormatYMD(licenseFromDate, '-') : '',
      license_validaty_end_date: licenseToDate ? renderDateFormatYMD(licenseToDate, '-') : '',
      remark: updatedData?.remark,
      photo: equipmentFile?.current?.files[0] ? equipmentFile?.current?.files[0] : null,
    }

    var bodyFormData = new FormData();
    bodyFormData.append('id', body.id.toString());
    if (body.id != 0) {
      if (body.photo && body.id != 0) {
        bodyFormData.append('photo', body.photo);
      }
    }
    else {
      bodyFormData.append('photo', body.photo);
    }

    bodyFormData.append('serial_number', body.serial_number);
    bodyFormData.append('equipment_category_id', body.equipment_category_id);
    bodyFormData.append('tags', tags.filter(x => body.tags.includes(x.name)).map(x => x.id).toString());
    bodyFormData.append('name', body.name);
    bodyFormData.append('condition', body.condition);
    bodyFormData.append('working_location_id', body.working_location_id);
    bodyFormData.append('version', body.version);
    bodyFormData.append('service_provider', body.service_provider);
    bodyFormData.append('price_detail', body.price_detail);
    bodyFormData.append('warranty_start_date', body.warranty_start_date);
    bodyFormData.append('warranty_end_date', body.warranty_end_date);
    bodyFormData.append('license_type', body.license_type);
    bodyFormData.append('license_validaty_start_date', body.license_validaty_start_date);
    bodyFormData.append('license_validaty_end_date', body.license_validaty_end_date);
    bodyFormData.append('remark', body.remark);
    if (validateData(body) == false) {
      storeEquipment(bodyFormData, body.id);
    } else {
      setIsLoading(false);
    }
  }
  const validateData = (data: IEquipmentObj) => {
    if (!equipmentFile || !data.serial_number || !data.equipment_category_id || data.tags.length <= 0 || !data.name
      //|| !data.condition || !data.working_location_id || !data.version || !data.service_provider || !data.price_detail || !data.warranty_start_date
      //|| !data.warranty_end_date || !data.license_type || ! data.license_validaty_start_date || !data.license_validaty_end_date || !data.remark
    ) {
      return true
    }
    return false;
  }  
  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modalContent rounded-xl lg:w-[1000px] max-w-[90%] annoucement-modal overflow-y-auto max-h-[90vh] w-full focus-visible:outline-none">
        <div
          id="modal-modal-title"
          className="bg-vorpblue relative flex justify-between py-6 px-5 rounded-t-xl"
        >
          <h3 className="text-18 font-primary font-semibold text-white">
            {editedData ? "Update Equipment" : "Add New Equipment"}
          </h3>
          <button
            className="flex items-center justify-center"
            onClick={() => {
              changeEquipmentTab(1);
              setIsOpen(false);
              setEditedData(undefined)
            }}
          >
            <CloseIcon />
          </button>
        </div>

        <div
          id="modal-modal-description"
          className="py-9 pl-5 pr-2 bg-white rounded-b-xl"
        >
          <div className="w-full">
            <div className="flex mb-5">
              <button onClick={() => changeEquipmentTab(1)} className={twMerge(tab == 1 ? 'text-vorpblue' : 'text-vorpgraylight', "font-semibold md:text-sm text-xs pr-3 border-r border-r-filtergray")}>Equipment Information</button>
              <button onClick={() => changeEquipmentTab(2)} className={twMerge(tab == 2 ? 'text-vorpblue' : 'text-vorpgraylight', "font-semibold md:text-sm text-xs px-3")}>Equipment Detail</button>
              {/* <button onClick={() => changeEquipmentTab(3)} className={twMerge(tab == 3 ? 'text-vorpblue' : 'text-vorpgraylight', "font-semibold md:text-sm text-xs px-3 ")}>Assign Employee </button> */}
            </div>
            <div className="overflow-y-auto max-h-[50vh] custom_scrollbar_popup">
              <div className={twMerge(tab == 1 ? 'grid' : 'hidden', "grid-cols-1 md:grid-cols-2 gap-4 pr-3")}>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Equipment ID</label>
                  <input type="text" value={editedData?.code} readOnly className="md:text-sm text-xs px-3 py-11 w-full bg-offwhite rounded mt-1 border border-goalinputborder focus-within:outline-none focus:outline-none focus-visible:outline-none" />
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Serial Number</label>
                  <input type="number"
                    value={updatedData?.serialno ?? ""}
                    name="serialno"
                    onChange={changeInputData}
                    placeholder="21861320154" className="md:text-sm text-xs  px-3 py-11 w-full bg-white rounded mt-1 border border-goalinputborder focus-within:outline-none focus:outline-none focus-visible:outline-none" />
                  {(isSubmit == true && updatedData?.serialno == undefined) ? <span className="text-redPink font-normal text-xs my-1">Serial number is required.</span> : ''}
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Equipment Name <span className="text-redPink">*</span></label>
                  <input type="text"
                    value={updatedData?.name ?? ''}
                    name="name"
                    onChange={changeInputData}
                    placeholder="Designer Laptop 2" className="md:text-sm text-xs  px-3 py-11 w-full bg-white rounded mt-1 border border-goalinputborder focus-within:outline-none focus:outline-none focus-visible:outline-none" />
                  {(isSubmit == true && updatedData?.name == undefined) ? <span className="text-redPink font-normal text-xs my-1">Equipment name is required.</span> : ''}
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Category <span className="text-redPink">*</span></label>
                  <div className="mt-1">
                    <StringDropdownList
                      state={category}
                      setState={setCategory}
                      placeholder="Category"
                      className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                      dropdownItems={categories?.map((cat: any) => ({
                        value: cat.id,
                        label: cat.name,
                      }))}
                      textAlign={"text-left"}
                      fontSize="md:text-sm text-xs"
                      border="border-goalinputborder"
                      color="text-black2"
                      paddingLi="py-2"
                    />
                  </div>
                  {(isSubmit == true && (!category)) ? <span className="text-redPink font-normal text-xs my-1">{errors?.equipment_category_id ? errors?.equipment_category_id[0] : "Category is required."}</span> : ''}
                </div>
                <div className="md:col-span-2">
                  <div>
                    <label className="text-black2 font-normal body-15" htmlFor="">Tags</label>
                    <div className="mt-1">
                      <CommonTagDropdown
                        createData={createEquipmentTag}
                        isCreate={true}
                        isObject={true}
                        showAll={true}
                        state={chosenTags}
                        setState={setChosenTags}
                        options={tags?.map((tag) => {
                          return {
                            label: tag.name,
                            value: tag.id
                          }
                        })}
                        className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px] mb-0"
                      />
                    </div>
                    {(isSubmit == true && (chosenTags.length <= 0 || errors?.tags)) ? <span className="text-redPink font-normal text-xs my-1">{errors.tags ? errors.tags[0] : "Tags is required."}</span> : ''}
                  </div>
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Location <span className="text-redPink">*</span></label>
                  <div className="mt-1">
                    <StringDropdownList
                      state={location}
                      setState={setLocation}
                      placeholder="Location"
                      className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                      dropdownItems={locations?.map((location: any) => ({
                        value: location.id,
                        label: location.name,
                      }))}
                      textAlign={"text-left"}
                      fontSize="md:text-sm text-xs"
                      border="border-goalinputborder"
                      color="text-black2"
                      paddingLi="py-2"
                    />
                  </div>
                  {((isSubmit == true && (location=="" || location=="0")||errors?.working_location_id)==true) ? <span className="text-redPink font-normal text-xs my-1">{errors?.working_location_id ? errors?.working_location_id[0] : "Location is required."}</span> : ''}
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Upload Photo <span className="text-redPink">*</span></label>
                  <div className="mt-1">
                    <div className="flex items-center">
                      <div className="cursor-pointer relative">
                        <input accept="image/*" onChange={uploadEquipmentFile} ref={equipmentFile} type="file" className="absolute opacity-0 cursor-pointer left-0 w-full" />
                        <button className="px-2 py-2 text-xs text-graydark text-center mr-3 bg-voeee border border-voc2 rounded-4">Choose File</button>
                      </div>
                      <span className="font-normal text-xs text-black2">{uploadFile ? uploadFile : "No file chosen"}</span>
                    </div>
                    {(isSubmit == true && (uploadFile == undefined || uploadFile == '') || errors?.photo) ? <span className="text-redPink font-normal text-xs my-1">{errors?.photo ? errors?.photo : "Photo is required."}</span> : <span className="text-redPink font-normal text-xs my-1">{imgError}</span>}
                  </div>
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Condition <span className="text-redPink">*</span></label>
                  <div className="flex items-center mt-1">
                    <BaseRadioButton
                      state={condition}
                      setState={setCondition}
                      label="Used"
                      value="Used"
                      // value="full"
                      id="full"
                      className="mr-4 sm:mr-8"
                      fontSize="md:text-sm text-xs"
                      name="condition"
                    />
                    <BaseRadioButton
                      state={condition}
                      setState={setCondition}
                      label="New"
                      value="New"
                      // value="half-am"
                      id="half-am"
                      className="mr-4 sm:mr-8"
                      fontSize="md:text-sm text-xs"
                      name="condition"
                    />
                    <BaseRadioButton
                      state={condition}
                      setState={setCondition}
                      label="Damaged"
                      value="Damaged"
                      // value="half-pm"
                      id="half-pm"
                      fontSize="md:text-sm text-xs"
                      name="condition"
                    />
                  </div>
                </div>
              </div>
              <div className={twMerge(tab == 2 ? 'grid' : 'hidden', "grid-cols-1 md:grid-cols-2 gap-4 pr-3")}>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Version</label>
                  <input type="text"
                    name="version"
                    value={updatedData?.version ?? ""}
                    onChange={changeInputData}
                    placeholder="v1.23.001c" className="md:text-sm text-xs  px-3 py-11 w-full bg-white rounded mt-1 border border-goalinputborder focus-within:outline-none focus:outline-none focus-visible:outline-none" />
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Service Provider</label>
                  <input type="text"
                    name="service_provider"
                    value={updatedData?.service_provider}
                    onChange={changeInputData}
                    placeholder="" className="md:text-sm text-xs  px-3 py-11 w-full bg-white rounded mt-1 border border-goalinputborder focus-within:outline-none focus:outline-none focus-visible:outline-none" />
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Pricing Detail</label>
                  <input type="text"
                    name="pricing_detail"
                    value={updatedData?.pricing_detail ?? ""}
                    onChange={changeInputData}
                    placeholder="MYR 8230" className="md:text-sm text-xs  px-3 py-11 w-full bg-white rounded mt-1 border border-goalinputborder focus-within:outline-none focus:outline-none focus-visible:outline-none" />
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Warranty</label>
                  <div className="flex sm:flex-row flex-col mt-1">
                    <div className="sm:mr-1 sm:w-1/2 sm:mb-0 mb-2">
                      <CustomDatePicker
                        format="yyyy-mm-dd"
                        date={warantyFromDate}
                        //minDate={warantyFromDate ? warantyFromDate : new Date()}
                        setDate={setWarantyFromDate}
                        isFilterSection={false}
                        placeholder="yyyy-mm-dd"
                        iconColor="#858795"
                        borderColor="border-goalinputborder"
                        fontSize="md:text-sm text-xs"
                      />
                    </div>

                    <div className="sm:ml-1 sm:w-1/2">
                      <CustomDatePicker
                        format="yyyy-mm-dd"
                        date={warantyToDate}
                        minDate={warantyFromDate != undefined ? warantyFromDate : new Date()}
                        setDate={setWarantyToDate}
                        isFilterSection={false}
                        placeholder="yyyy-mm-dd"
                        iconColor="#858795"
                        borderColor="border-goalinputborder"
                        fontSize="md:text-sm text-xs"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">License Type</label>
                  <div className="flex items-center mt-1">
                    <BaseRadioButton
                      state={licenseType}
                      setState={setLicenseType}
                      label="Subscription"
                      value="Subscription"
                      // value="full"
                      id="full"
                      className="mr-4 sm:mr-8"
                      fontSize="md:text-sm text-xs"
                      name="licenseType"
                    />
                    <BaseRadioButton
                      state={licenseType}
                      setState={setLicenseType}
                      label="Lifetime"
                      value="Lifetime"
                      // value="half-am"
                      id="half-am"
                      className="mr-4 sm:mr-8"
                      fontSize="md:text-sm text-xs"
                      name="licenseType"
                    />
                  </div>
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">License Validity Date</label>
                  <div className="flex sm:flex-row flex-col mt-1">
                    <div className="sm:mr-1 sm:w-1/2 sm:mb-0 mb-2">
                      <CustomDatePicker
                        format="yyyy-mm-dd"
                        date={licenseFromDate}
                        // minDate={licenseFromDate != undefined ? licenseFromDate : new Date()}
                        setDate={setLicenseFromDate}
                        isFilterSection={false}
                        placeholder="yyyy-mm-dd"
                        iconColor="#858795"
                        borderColor="border-goalinputborder"
                        fontSize="md:text-sm text-xs"
                      />
                    </div>
                    <div className="sm:ml-1 sm:w-1/2">
                      <CustomDatePicker
                        format="yyyy-mm-dd"
                        date={licenseToDate}
                        minDate={licenseFromDate != undefined ? licenseFromDate : new Date()}
                        setDate={setLicenseToDate}
                        isFilterSection={false}
                        placeholder="yyyy-mm-dd"
                        iconColor="#858795"
                        borderColor="border-goalinputborder"
                        fontSize="md:text-sm text-xs"
                      />
                    </div>
                  </div>
                </div>
                <div className="md:col-span-2">
                  <label className="text-black2 font-normal body-15" htmlFor="">Remark</label>
                  <textarea
                    name="remark"
                    value={updatedData?.remark}
                    onChange={changeInputData}
                    rows={3} placeholder="Remark" className="md:text-sm text-xs  px-3 py-11 w-full bg-white rounded mt-1 border border-goalinputborder focus-within:outline-none focus:outline-none focus-visible:outline-none resize-none">
                  </textarea>
                </div>
              </div>
              {/* <div className={twMerge(tab == 3 ? 'grid' : 'hidden', "grid-cols-1 md:grid-cols-2 gap-4 pr-3")}>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Department</label>
                  <div className="mt-1">
                    <StringDropdownList
                      state={department}
                      setState={setDepartment}
                      placeholder="Departments"
                      className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                      dropdownItems={departments?.map((department: any) => ({
                        value: department.id,
                        label: department.name,
                      }))}
                      textAlign={"text-left"}
                      fontSize="md:text-14 text-12"
                      border="border-goalinputborder"
                      color="text-black2"
                    />
                  </div>
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Employee</label>
                  <div className="mt-1">
                    <StringDropdownList
                      state={employee}
                      setState={setEmployee}
                      placeholder="Employee"
                      className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                      dropdownItems={employees?.map((employee: any) => ({
                        value: employee.id,
                        label: employee.name,
                      }))}
                      textAlign={"text-left"}
                      fontSize="md:text-14 text-12"
                      border="border-goalinputborder"
                      color="text-black2"
                    />
                  </div>
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Type of application</label>
                  <div className="flex items-center mt-1">
                    <BaseRadioButton
                      state={application}
                      setState={setApplication}
                      label="Borrow"
                      value="Borrow"
                      // value="full"
                      id="borrow"
                      className="mr-4 sm:mr-8"
                      fontSize="md:text-sm text-xs"
                      name="application"
                    />
                    <BaseRadioButton
                      state={application}
                      setState={setApplication}
                      label="Assign"
                      value="Assign"
                      // value="half-am"
                      id="assign"
                      className="mr-4 sm:mr-8"
                      fontSize="md:text-sm text-xs"
                      name="application"
                    />
                  </div>
                </div>
                <div>
                  <label className="text-black2 font-normal body-15" htmlFor="">Usage Duration</label>
                  <div className="mt-1">
                    <CustomDatePicker
                    format="yyyy-mm-dd"
                      date={usageDuration}
                      minDate={usageDuration != undefined ? usageDuration : new Date()}
                      setDate={setUsageDuration}
                      isFilterSection={false}
                      placeholder="Select Date"
                      iconColor="#858795"
                      borderColor="border-goalinputborder"
                      fontSize="md:text-sm text-xs"
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="mt-5 last:mb-0 flex items-center">
              <Button
                type="button"
                label={
                  <span className={classNames(isLoading == true ? 'opacity-50 pointer-events-none' : '', "flex items-center text-12 leading-[18px] relative justify-center")}>
                    <div className="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M1.75 0.875C1.51794 0.875 1.29538 0.967187 1.13128 1.13128C0.967187 1.29538 0.875 1.51794 0.875 1.75V12.25C0.875 12.4821 0.967187 12.7046 1.13128 12.8687C1.29538 13.0328 1.51794 13.125 1.75 13.125H12.25C12.4821 13.125 12.7046 13.0328 12.8687 12.8687C13.0328 12.7046 13.125 12.4821 13.125 12.25V1.75C13.125 1.51794 13.0328 1.29538 12.8687 1.13128C12.7046 0.967187 12.4821 0.875 12.25 0.875H8.3125C8.08044 0.875 7.85788 0.967187 7.69378 1.13128C7.52969 1.29538 7.4375 1.51794 7.4375 1.75V8.13138L9.75275 5.81525C9.8349 5.7331 9.94632 5.68695 10.0625 5.68695C10.1787 5.68695 10.2901 5.7331 10.3722 5.81525C10.4544 5.8974 10.5006 6.00882 10.5006 6.125C10.5006 6.24118 10.4544 6.3526 10.3722 6.43475L7.30975 9.49725C7.26911 9.53799 7.22083 9.57032 7.16768 9.59237C7.11453 9.61443 7.05755 9.62578 7 9.62578C6.94245 9.62578 6.88547 9.61443 6.83232 9.59237C6.77917 9.57032 6.73089 9.53799 6.69025 9.49725L3.62775 6.43475C3.58707 6.39407 3.55481 6.34578 3.53279 6.29264C3.51078 6.23949 3.49945 6.18253 3.49945 6.125C3.49945 6.06747 3.51078 6.01051 3.53279 5.95736C3.55481 5.90422 3.58707 5.85593 3.62775 5.81525C3.66843 5.77457 3.71672 5.74231 3.76986 5.72029C3.82301 5.69828 3.87997 5.68695 3.9375 5.68695C3.99503 5.68695 4.05199 5.69828 4.10514 5.72029C4.15828 5.74231 4.20657 5.77457 4.24725 5.81525L6.5625 8.13138V1.75C6.5625 1.28587 6.74687 0.840752 7.07506 0.512563C7.40325 0.184374 7.84837 0 8.3125 0L12.25 0C12.7141 0 13.1592 0.184374 13.4874 0.512563C13.8156 0.840752 14 1.28587 14 1.75V12.25C14 12.7141 13.8156 13.1592 13.4874 13.4874C13.1592 13.8156 12.7141 14 12.25 14H1.75C1.28587 14 0.840752 13.8156 0.512563 13.4874C0.184374 13.1592 0 12.7141 0 12.25V1.75C0 1.28587 0.184374 0.840752 0.512563 0.512563C0.840752 0.184374 1.28587 0 1.75 0L3.9375 0C4.05353 0 4.16481 0.0460936 4.24686 0.128141C4.32891 0.210188 4.375 0.321468 4.375 0.4375C4.375 0.553532 4.32891 0.664812 4.24686 0.746859C4.16481 0.828906 4.05353 0.875 3.9375 0.875H1.75Z" fill="#FEFCFF" />
                      </svg>
                    </div>

                    {isLoading == true ? <div className="flex items-center justify-center w-full">
                      <CircularProgress sx={{ color: 'white' }} className="w-5 h-5" style={{ width: '20px', height: '20px' }} /><span className="ml-2">Submit</span>
                    </div> : "Save"}
                  </span>
                }
                onClick={() => { handleConfirm(); changeEquipmentTab(1); }}
                variant="no-outline"
                size="lg"
                customClass={twMerge(
                  "saveButton hover:bg-vorpblue mr-2"
                )}
              />
              <Button
                type="button"
                label={
                  <span className="flex items-center text-12 leading-[18px] justify-center">
                    <div className="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <g clip-path="url(#clip0_17372_20468)">
                          <path d="M4.23821 9.38673C4.08389 9.38702 3.93575 9.32618 3.82618 9.21751L0.173911 5.60579C0.118815 5.5513 0.0750749 5.48643 0.0452229 5.41492C0.0153709 5.34341 0 5.26668 0 5.18919C0 5.1117 0.0153709 5.03498 0.0452229 4.96347C0.0750749 4.89196 0.118815 4.82708 0.173911 4.77259L3.82618 1.16111C3.93667 1.05183 4.08604 0.99092 4.24144 0.991777C4.39684 0.992634 4.54553 1.05519 4.65481 1.16568C4.76409 1.27617 4.825 1.42554 4.82414 1.58094C4.82328 1.73634 4.76073 1.88503 4.65024 1.99431L1.41915 5.18931L4.65024 8.38431C4.73279 8.466 4.7892 8.57038 4.81231 8.6842C4.83542 8.79802 4.82419 8.91613 4.78004 9.02355C4.73588 9.13097 4.6608 9.22285 4.56433 9.28751C4.46786 9.35217 4.35435 9.38671 4.23821 9.38673Z" fill="#464E5F" />
                          <path d="M8.77664 11.0088H7.89844C7.74304 11.0088 7.594 10.9471 7.48412 10.8372C7.37423 10.7273 7.3125 10.5782 7.3125 10.4228C7.3125 10.2674 7.37423 10.1184 7.48412 10.0085C7.594 9.89864 7.74304 9.83691 7.89844 9.83691H8.77664C9.90773 9.83691 10.8281 8.9259 10.8281 7.80605C10.8281 6.68621 9.90773 5.77519 8.77664 5.77519H0.773438C0.618037 5.77519 0.469002 5.71346 0.359117 5.60358C0.249233 5.49369 0.1875 5.34466 0.1875 5.18925C0.1875 5.03385 0.249233 4.88482 0.359117 4.77493C0.469002 4.66505 0.618037 4.60332 0.773438 4.60332H8.77664C10.5539 4.60332 12 6.04004 12 7.80605C12 9.57207 10.5539 11.0088 8.77664 11.0088Z" fill="#464E5F" />
                        </g>
                        <defs>
                          <clipPath id="clip0_17372_20468">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    Back
                  </span>
                }
                onClick={() => {
                  setEditedData(undefined)
                  setIsOpen(false);
                  changeEquipmentTab(1);
                }}
                variant="no-outline"
                size="lg"
                customClass="min-w-[112px] min-h-[43.6px] bg-offwhite text-graydark border-none backButton"
              />
            </div>
          </div>

        </div>

      </div>
    </Modal>
  );
};
export default EquipmentPopup;
