import { FC, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ProfileInfoData } from "../../app/services/profile";
interface ILocation {
    weatherData: any;
    weatherCode: any;
    weatherError: string;
    coordData:any;
}


const settings = {
    initialSlide:3,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};
const ProfileWeather: FC<ILocation> = ({ weatherData, weatherCode, weatherError,coordData }) => {   
    const sliderRef=useRef<any>(null);
    const [defaultSlider,setDefaultSlider]=useState<any>();
    useEffect(()=>{
        setDefaultSlider({
            initialSlide:2,//weatherData?weatherData[0]?.is_default:0,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoPlay:false,
            beforeChange: (prev:any, next:any) => {                
                if(sliderRef){
                    if(sliderRef.current){
                    }
                }
              },
        })
        if(sliderRef.current){
            sliderRef.current.slickGoTo(weatherData?weatherData?.find((x:any)=>x.is_default==1)?.id-1:0)
        }
       
    },[weatherData])
    return <div>{weatherError ?
        <div className="min-h-[164px] w-full bg-no-repeat bg-center bg-[#72C1ED] rounded-xl relative">
            <p className="absolute top-1/2 -translate-y-1/2 px-4 break-words w-full font-semibold lg:text-lg text-sm text-left text-white">{weatherError}</p>
        </div> :
        <Slider  ref={sliderRef} {...defaultSlider}>
            {weatherData?.map((coord: any, key: number) => {
                const image = coord.weather?.icon;
                const time=coordData?.map((x:any)=>(x.country==coord.country)?x.time:'')
                return <div key={key}>
                    <div style={{ backgroundImage: "url('" + image + "')" }} className="min-h-[164px] w-full bg-no-repeat bg-center bg-[#72C1ED] rounded-xl relative">
                    {coord.weather?<div className="5xl:px-5 px-2 5xl:py-4 py-2">
                            <div className=" flex justify-between">
                                <div className="mr-3">
                                    <p className="font-semibold xl:text-24 text-lg text-left text-white">{coord.country}</p>
                                    <p className="font-normal xl:text-base text-sm text-left text-white">{time}</p>
                                </div>
                                <div>
                                    <p className="font-normal 5xl:text-[52px] text-36 text-white">{(coord.weather?.temp!="" && coord.weather?.temp !=null )?coord.weather?.temp?.toFixed():0}<sup className="text-xl font-normal">°C</sup></p>
                                </div>
                            </div>
                            <div className="mt-3 absolute bottom-4 left-0 flex justify-between items-end w-full">
                                <div className=" mr-3 5xl:pl-5 pl-2">
                                    {weatherCode && coord.country == "Kwai Chung" ? <p className="rounded-[4px] font-medium text-sm text-white border border-white py-[2px] px-2 mb-1 inline-block">{weatherCode}</p> : ""}
                                    <p className="font-medium text-sm capitalize text-white leading-[110%]">{coord.weather? coord.weather?.description : ''}</p>
                                </div>
                                <p className="font-medium text-sm capitalize text-white 5xl:pr-5 pr-2 whitespace-nowrap leading-[110%]">H: {(coord.weather?.temp_max!="" && coord.weather?.temp_max!=null)?coord.weather?.temp_max?.toFixed():''}<sup>°</sup> L: {(coord.weather?.temp_min!="" && coord.weather?.temp_min!=null)?coord.weather?.temp_min?.toFixed():''}<sup>°</sup></p>
                            </div>
                        </div>:''}
                    </div>
                </div>
            })}
        </Slider>}
    </div>
}
export default ProfileWeather;