import { type } from "os";
import { redirectToLogin } from "./annoucement-sheet";
import { api, endpointUrl } from "./api";
import authHeader from "./auth-header";
import { ListResponse } from "./employee";
import authHeaderMultipart from "./auth-header-multipart";
import { getAuthUser } from "./dashboard";
export type EquipmentObjectKeys = {
    id: number,
    name: string,
}
export type EquipmentDataWithPagination = {
    equipments: {
        current_page: number;
        from: number;
        to: number;
        total: number;
        per_page: number;
        data: [];
        first_page_url: string;
        last_page_url: string;
        next_page_url: string;
    },
    module_id:number;

}
export type EquipmentProfileDataWithPagination = {
    equipment_applications: {
        current_page: number;
        from: number;
        to: number;
        total: number;
        per_page: number;
        data: [];
        first_page_url: string;
        last_page_url: string;
        next_page_url: string;
    },
    module_id:number;

}
export type EquipmentAppDataWithPagination = {
    equipment_applications: {
        current_page: number;
        from: number;
        to: number;
        total: number;
        per_page: number;
        data: [];
        first_page_url: string;
        last_page_url: string;
        next_page_url: string;
    },
    module_id:number;

}

export type EquipmentApplicationDataWithPagination = {
    equipments: {
        current_page: number;
        from: number;
        to: number;
        total: number;
        per_page: number;
        data: [];
        first_page_url: string;
        last_page_url: string;
        next_page_url: string;
    }

}
export type PaginationData = {
    page: number;
    category: number | string | undefined,
    status: string | string | undefined,
    application_type?:string,
    limit: number | string | undefined,
    search: string | number,
    sort?: string,
    tab?: string
}
export type IEquipmentFilterData = {
    tags: EquipmentObjectKeys[],
    working_locations: EquipmentObjectKeys[],
    employees: EquipmentObjectKeys[],
    equipment_categories: EquipmentObjectKeys[],
    departments: EquipmentObjectKeys[],
    is_admin:boolean
}
export type IEquipmentProfileFilterData={
    equipment_categories: EquipmentObjectKeys[],
}
export type IEquipmentObj = {
    "id": number;
    "serial_number": string,
    "equipment_category_id": string,
    "tags": string[],
    "name": string,
    "availablity": string,
    "condition": string,
    "working_location_id": string,
    "version": string,
    "service_provider": string,
    "price_detail": string,
    "warranty_start_date": string,
    "warranty_end_date": string,
    "license_type": string,
    "license_validaty_start_date": string,
    "license_validaty_end_date": string,
    "remark": string,
    "photo": string,
}
export type IEquipmentApp = {
    "id":number,
    "equipment_id": number,
    "application_type": string,
    "assign_date": string,
    "expected_return_date": string,
    "department_id": number,
    "employee_id": number,
    "reason": string
}
export const equipmentApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEquipments: build.query<EquipmentDataWithPagination, PaginationData>({
            query: ({ category, status, limit, page, search, sort }) => (
                // console.log("status", status),
                {
                    url: `${endpointUrl}equipments?equipment_category_id=${category}&condition=${status}&limit=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    headers: authHeader(),
                    keepalive: false,
                }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            transformResponse: (res: any) => {
                // console.log("re", res);
                return res;
            },
            providesTags: (result: any = []) => {
                if (result.length > 0) {
                    return [
                        ...result?.employeeList?.data?.map(
                            (id: any) => ({ type: "Equipments", id } as const)
                        ),
                        { type: "Equipments" as const, id: "LIST" },
                    ]
                } else {
                    return [{ type: "Equipments" as const, id: "LIST" },];
                }
            },
        }),
        getEquipmentsFilterData: build.query<IEquipmentFilterData, void>({
            query: () => (
                {
                    url: `${endpointUrl}equipments-filter-data`,
                    headers: authHeader(),
                    keepalive: false,
                }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            transformResponse: (res: any) => {
                // console.log("re", res);
                return res;
            },
        }),
        storeEquipment: build.mutation<IEquipmentObj, Partial<any>>({
            query: (body) => {
                var bodyFormData = new FormData();
                // bodyFormData.append('photo', body.photo);
                bodyFormData.append('serial_number', "body.serial_number");
                // bodyFormData.append('equipment_category_id', body.equipment_category_id);
                // bodyFormData.append('tags', body.tags);
                // bodyFormData.append('name', body.name);
                // bodyFormData.append('availablity', body.availablity);
                // bodyFormData.append('condition', body.condition);
                // bodyFormData.append('working_location_id', body.working_location_id);
                // bodyFormData.append('version', body.version);
                // bodyFormData.append('service_provider', body.service_provider);
                // bodyFormData.append('price_detail', body.price_detail);
                // bodyFormData.append('warranty_start_date', body.warranty_start_date);
                // bodyFormData.append('warranty_end_date', body.warranty_end_date);
                // bodyFormData.append('license_type', body.license_type);
                // bodyFormData.append('license_validaty_start_date', body.license_validaty_start_date);
                // bodyFormData.append('license_validaty_end_date', body.license_validaty_end_date);
                // bodyFormData.append('remark', body.remark);
                
                var getUser = getAuthUser();
                return {
                    url: `${endpointUrl}equipments`,
                    method: "POST",
                    headers: authHeaderMultipart(),
                    body: body,
                };
            },
        }),
        applyEquipment: build.mutation<IEquipmentObj, Partial<IEquipmentApp>>({
            query: (body) => {
                return {
                    url: `${endpointUrl}equipment-applications`,
                    method: "POST",
                    headers: authHeader(),
                    body: body,
                };
            },
            transformErrorResponse: (err) => {
                if (err?.status === 403) {
                    
                }
            },
        }),
        editEquipmentApplication: build.mutation<IEquipmentObj, Partial<IEquipmentApp>>({
            query: (body) => {
                return {
                    url: `${endpointUrl}equipment-applications/${body.id}`,
                    method: "PUT",
                    headers: authHeader(),
                    body: body,
                };
            },
        }),
        getEquipmentApplicationsMutation: build.mutation<EquipmentApplicationDataWithPagination, Partial<PaginationData>>({
            query: (body) => {
                console.log('api ', body)
                return {
                    url: `${endpointUrl}equipment-applications`,
                    method: "GET",
                    body,
                    headers: authHeader(),
                };
            },
        }),
        getEquipmentApplications: build.query<EquipmentAppDataWithPagination, PaginationData>({
            query: ({ category, status, limit, page, search, sort }) => (
                {
                    url: `${endpointUrl}equipment-applications?condition=${status}&equipment_category_id=${category}&limit=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    headers: authHeader(),
                    keepalive: false,
                }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            transformResponse: (res: any) => {
                // console.log("re", res);
                return res;
            },
            providesTags: (result: any = []) => {
                if (result.length > 0) {
                    return [
                        ...result?.employeeList?.data?.map(
                            (id: any) => ({ type: "EquipmentsApplication", id } as const)
                        ),
                        { type: "EquipmentsApplication" as const, id: "LIST" },
                    ]
                } else {
                    return [{ type: "EquipmentsApplication" as const, id: "LIST" },];
                }
            },
        }),
        getEquipmentLogs: build.mutation<EquipmentApplicationDataWithPagination, Partial<PaginationData>>({
            query: (body) => {
                return {
                    url: `${endpointUrl}equipments-application`,
                    method: "GET",
                    body,
                    headers: authHeader(),
                };
            },
        }),
        deleteEquipment: build.mutation<EquipmentApplicationDataWithPagination, Number>({
            query: (id) => {
                return {
                    url: `${endpointUrl}equipments/${id}`,
                    method: "DELETE",
                    headers: authHeader(),
                };
            },
        }),
        approveEquipment: build.mutation<EquipmentApplicationDataWithPagination, Number>({
            query: (id) => {
                return {
                    url: `${endpointUrl}equipment-applications/${id}/approve`,
                    method: "POST",
                    headers: authHeader(),
                };
            },
        }),
        rejectEquipment: build.mutation<EquipmentApplicationDataWithPagination, Number>({
            query: (id) => {
                return {
                    url: `${endpointUrl}equipment-applications/${id}/reject`,
                    method: "POST",
                    headers: authHeader(),
                };
            },
        }),
        returnEquipment: build.mutation<EquipmentApplicationDataWithPagination, Number>({
            query: (id) => {
                return {
                    url: `${endpointUrl}equipments/${id}/return`,
                    method: "POST",
                    headers: authHeader(),
                };
            },
        }),
        getProfileFilterEquipments: build.query<IEquipmentFilterData, void>({
            query: () => (
                // console.log("status", status),
                {
                    url: `${endpointUrl}profile/equipment-applications-filter-data`,
                    headers: authHeader(),
                    keepalive: false,
                }),
            keepUnusedDataFor: 0,
        }),
        getProfileEquipments: build.query<EquipmentProfileDataWithPagination, PaginationData>({
            query: ({ category, status, limit, page, search, sort,application_type }) => (
                // console.log("status", status),
                {
                    url: `${endpointUrl}profile/equipment-applications?equipment_category_id=${category}&status=${status}&application_type=${application_type}&limit=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    headers: authHeader(),
                    keepalive: false,
                }),
            keepUnusedDataFor: 0,
        }),
        getMyEquipmentLogs: build.mutation<EquipmentApplicationDataWithPagination, Partial<PaginationData>>({
            query: (body) => {
                return {
                    url: `${endpointUrl}profile/equipments-application`,
                    method: "GET",
                    body,
                    headers: authHeader(),
                };
            },
        }),
    })
});
export const {
    useGetEquipmentsQuery,
    useGetEquipmentsFilterDataQuery,
    useStoreEquipmentMutation,
    useGetEquipmentApplicationsMutationMutation,
    useGetEquipmentApplicationsQuery,
    useApplyEquipmentMutation,
    useEditEquipmentApplicationMutation,
    useDeleteEquipmentMutation,
    useApproveEquipmentMutation,
    useRejectEquipmentMutation,
    useGetProfileEquipmentsQuery,
    useGetProfileFilterEquipmentsQuery,
    useGetMyEquipmentLogsMutation
} = equipmentApi;

export const {
    endpoints: {
        getEquipments,
        getEquipmentsFilterData,
        storeEquipment,
        getEquipmentApplicationsMutation,
        getEquipmentApplications,
        applyEquipment,
        editEquipmentApplication,
        deleteEquipment,
        approveEquipment,
        rejectEquipment,
        getProfileEquipments,
        getProfileFilterEquipments,
        getMyEquipmentLogs
    },
} = equipmentApi;