import { FC, useEffect, useMemo, useState, useCallback } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import {
  ExportIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import {
  checkedIsNew,
  useGetDepartmentListQuery,
} from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useSpring, animated } from "react-spring";
import { renderDateFormatYMD } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
import OTListTable from "../../components/OTListTable/OTListTable";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import OTCreatePopup from "../../components/OTListTable/OTCreatePopup";
import {
  useCreateOTListMutation,
  useGetEmployeeOTListQuery,
  useGetOTListQuery,
  useUpdatedOTListMutation,
} from "../../app/services/otlist";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import OTUpdatePopup from "../../components/OTListTable/OTUpdatePopup";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import OT2Component from "../../components/OTListTable/OT2Component";
import OTCreatePopupUpdate from "../../components/OTListTable/OTCreatePopupUpdate";

export interface IOTData {
  id: number;
  employeeID: string;
  employeeName: string;
  department: string;
  date: string;
  fullHalfDay: string;
  timeIn: string;
  timeOut: string;
  otAmt: string;
}

interface IOT { }

export interface IDepartment {
  id: number;
  name: string;
  label: string;
}

const defaultFields = [
  "id",
  "employeeID",
  "employeeName",
  "department",
  "from_date",
  "to_date",
  "created_at",
  "fullHalfDay",
  "current_status",
  "type",
  "total_days",
  "created_user",
  "reason",
  "timeIn",
  "timeOut",
  "otAmt",
  "lasted",
  "actions",
];

export type TStatusListData = {
  id: number | string;
  name: string;
};
const statusListData: TStatusListData[] = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 0,
    name: "Pending",
  },
  {
    id: 1,
    name: "Approved(Leader)",
  },
  {
    id: 2,
    name: "Approved",
  },
  {
    id: 3,
    name: "Cancelled",
  },
  {
    id: -1,
    name: "Rejected(Leader)",
  },
  {
    id: -2,
    name: "Rejected",
  },
];

const OTList: FC<IOT> = () => {
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [department, setDepartment] = useState<string | number>("All");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [message, setMessage] = useState<any>([]);
  const [empCode, setEmpCode] = useState<string>("");
  const [dept, setDept] = useState<string>("");
  const [employeeData, setEmployeeData] = useState<string[]>([]);
  const [employeeDataID, setEmployeeDataID] = useState<any[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [assignUserListData, setAssignUserListData] = useState<baseObj[]>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [departData, setDepartData] = useState<IDepartment[]>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [tempList, setTempList] = useState<any>(templateList);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [moduleList, setModuleList] = useState<any>([]);
  const [data, setData] = useState<baseObj[]>([]);
  const [editOTData, setEditOTData] = useState<any>({});
  const [otStatus, setOtStatus] = useState<any>("All");

  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [updatedSuccess, setUpdatedSuccess] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(false);
  const [listSkip, setListSkip] = useState<boolean>(false);
  const [createPopup, setCreatePopup] = useState<boolean>(false);
  const [manageOT, setManageOT] = useState<boolean>(false);
  const [manageOwnOT, setManageOwnOT] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  var disabledData = checkedIsNew(data);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const { data: employeeList } = useGetEmployeeOTListQuery(
    {
      search: "",
      code: empCode,
      department: dept,
    },
    {
      skip,
    }
  );

  const { data: OTList } = useGetOTListQuery(
    {
      page: currentPage,
      limit: pagination.pageSize,
      department: department,
      search: globalSearch,
      start: startDate === undefined ? "" : renderDateFormatYMD(startDate, "-"),
      end: endDate === undefined ? "" : renderDateFormatYMD(endDate, "-"),
      period: "",
      sort: sorting.map((s) => `${s.desc ? '-' : ''}${s.id == "id" ? 'code' : s.id == 'employeeName' ? 'employee_name' : s.id == 'employeeID' ? 'employee_code' : s.id == 'department' ? 'department_name' : s.id == 'fullHalfDay' ? 'period' : s.id == 'fullHalfDay' ? 'period' : s.id == 'type' ? 'pay_type' : s.id}`).join(','),
      status: otStatus,
    },
    {
      skip: listSkip,
    }
  );
  const { data: empDepartmentData } = useGetDepartmentListQuery();
  const [createOT] = useCreateOTListMutation();
  const [updateOT] = useUpdatedOTListMutation();

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList);
  };

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  const changePage = (pageNo: number) => {
    var pageData = {
      limit: pagination.pageSize,
      currentPage: pagination.pageIndex + 1,
    };
  };

  const checkIsValidate = (editedData: any) => {
    var msg: any = [];
    if (editedData.from_data === "") {
      msg.push("The from Date field is required!");
    }
    if (editedData.to_data === "") {
      msg.push("The to date field is required!");
    }
    if (editedData.period === "") {
      msg.push("The period field is required!");
    }
    if (editedData.type === "") {
      msg.push("The type field is required!");
    }
    if (editedData.reason === "") {
      msg.push("The reason field is required!");
    }
    if (editedData.employee_ids.length <= 0) {
      msg.push("The employee name field is required!");
    }
    // if (editedData.department_id === "") {
    //   msg.push("The department id field is required!");
    // }
    return msg;
  };

  const SaveOTListData = (data: any) => {
    setListSkip(true);
    setSkip(false);
    setUploading(true);
    const otData = { ...data, employee_ids: employeeDataID?.map(x => x.id) };
    var message: any = checkIsValidate(otData);
    if (message.length > 0 && !isEditing) {
      setErrorPopup(true);
      setMessage(message);
      setUploading(false);
      return;
    } else {
      createOT(otData)
        .unwrap()
        .then((res) => {
          console.log("r", ...res.data);

          setCreatePopup(false);
          toast("Successfully Create.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          setUploading(false);
          setData((prev: baseObj[]) => [...res.data, ...prev]);
        })
        .catch((error) => {
          setUploading(false);
          setCreatePopup(false);
          setErrorPopup(true);
          setMessage(
            error?.response
              ? [error?.data?.message]
              : ["Something wrong!. Trying again."]
          );
        });
    }
  };

  const UpdatedOTListData = (data: any) => {
    setUploading(true);
    const otData = {
      ...data,
      employee_ids: employeeDataID?.map(x => x.id),
      id: editOTData ? editOTData?.id : 0,
    };
    var message: any = checkIsValidate(otData);
    if (message.length > 0 && !isEditing) {
      setErrorPopup(true);
      setMessage(message);
      setUploading(false);
      return;
    } else {
      updateOT(otData)
        .unwrap()
        .then((res: any) => {
          // toast("Successfully Updated.", {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          //   type: "success",
          // });
          // setUploading(false);
          //setData((prev: baseObj[]) => [res.data,...prev]);
          setData((prev: baseObj[]) => prev.map((pr: any) => {
            if (pr.id == res.data?.id) {
              return res.data;
            }
            else {
              return pr;
            }
          }));
          setSkip(false);
          setUploading(false);
          setIsEdit(false);
          setUpdatedSuccess(true);
          setTimeout(() => {
            setUpdatedSuccess(false);
          }, 2000);
        })
        .catch((error) => {
          setUpdatedSuccess(false);
          setUploading(false);
          setIsEdit(false);
          setErrorPopup(true);
          setSkip(false);
          setMessage(
            error ? [error?.data?.message] : ["Something wrong!. Trying again."]
          );
        });
    }
  };

  const sampleImportCSV = () => {
    downloadSampleFile();
  };

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "over-time-sample/download",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sampleOTList.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };

  const exportData = async (format: string, isChecked: string) => {
    await axios({
      url:
        endpointUrl +
        `over-time?limit=${pagination.pageSize
        }&search=${globalSearch}&department_id=${department}&page=${currentPage}&start=${startDate === undefined ? "" : renderDateFormatYMD(startDate, "-")
        }&end=${endDate === undefined ? "" : renderDateFormatYMD(endDate, "-")
        }&export=true&only_this_page=${isChecked}&format=` +
        format +
        "",
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "OTList." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  const importData = async (formSaveData: any) => {
    await axios(endpointUrl + "import-over-times", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formSaveData,
    })
      .then((res) => {
        setSuccessPopup(true);
        setMessage(["Successfully import."]);
        axios.get(`${endpointUrl}over-time`, {}).then((result) => {
          var getData = result?.data?.overtimeList;
          setData(getData?.data);
          setItemCount(getData?.total);
          setIsImporting(true);
          setIsImportModalOpen(false);
          toast("Successfully Import.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        });
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  let updatedOTList = null;

  if (editOTData) {
    updatedOTList = (
      <OTUpdatePopup
        editData={editOTData}
        updateData={UpdatedOTListData}
        departments={departData}
        setIsOpen={setIsEdit}
        isOpen={isEditing}
        totalLength={itemCount}
        assignUserListData={assignUserListData}
        setDept={setDept}
        dept={dept}
        setEmpCode={setEmpCode}
        setEmployeeData={setEmployeeData}
        employeeData={employeeData}
        uploading={uploading}
        setSkip={setSkip}
        setUploading={setUploading}
        setEditOTData={setEditOTData}
      />
    );
  }

  useEffect(() => {
    if (employeeList) {
      setAssignUserListData(employeeList?.data ?? []);
    }
  }, [employeeList]);

  useEffect(() => {
    const filterManage = getAuthPermis.filter(
      (f) => f === "Manage_All_OT_List"
    );
    const manageOwn = getAuthPermis.filter((f) => f === "Manage_Own_OT_List");
    if (filterManage.length > 0) {
      setManageOT(true);
    }

    if (manageOwn.length > 0) {
      setManageOwnOT(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (OTList?.overtimeList.data) {
      setData(OTList?.overtimeList.data ?? []);
      setItemCount(OTList.overtimeList.total);
      setLocalModuleId(OTList.module_id);
    } else {
      setData([]);
    }
  }, [OTList]);

  useEffect(() => {
    if (empDepartmentData) {
      setDepartData([
        { name: "All", id: 0, label: "All" },
        ...empDepartmentData,
      ]);
    }
  }, [empDepartmentData]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() == currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    hideAllNoneField();
  }, [templateList, currentView]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  useEffect(() => {
    if (editOTData) {
      setDept(editOTData.department_id ?? "");
      setEmployeeData(editOTData.employee_id ?? "");
    }
  }, [editOTData]);

  useEffect(() => {
    if (updatedSuccess) {
      toast("Successfully Updated.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }
  }, [updatedSuccess]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    department,
    startDate,
    endDate,
    pagination.pageSize,
    currentView,
    globalSearch,
    otStatus,
  ]);

  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ImportUsersModal
        isOpen={isImportModalOpen}
        setIsOpen={setIsImportModalOpen}
        sampleCSV={sampleImportCSV}
        importEmployee={importData}
        isImporting={isImporting}
        setIsImporting={setIsImporting}
        setIsDownloading={setIsDownloading}
        isDownloading={isDownloading}
        isChecked={true}
      />

      <ExportUsersModal
        isOpen={isExportModalOpen}
        setIsOpen={setIsExportModalOpen}
        exportToCSV={exportData}
        exportToExcel={exportData}
        exportToPDF={exportData}
        exportToZip={exportData}
        isExporting={isExporting}
        setIsExporting={setIsExporting}
        isShowOnlyCheckbox={true}
      />

      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {(setState) => (
                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    id="message"
                    autoComplete="off"
                    name="search"
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={(e) => setState(e.target.value)}
                  />
                  <SearchIcon className={classNames("mr-2")} />
                </div>
              )}
            </DebounceSearchInput>
          </div>
          <div className="flex w-full sm:w-auto items-center justify-between md:justify-end lg:justify-end">
            {manageOT || manageOwnOT ? (
              <>
                <Button
                  label={
                    <span className="flex items-center">
                      <ImportIcon className="mr-[5px]" />
                      Import
                    </span>
                  }
                  onClick={() => {
                    setIsImportModalOpen(true);
                    setIsImporting(false);
                  }}
                  type="button"
                  variant="no-outline"
                  customClass="bg-transparent border-transparent sm:flex hover:border-vorpblue hover:text-vorpblue transition-all duration-1000 text-black2 xs:text-[12px] leading-[18px] font-medium py-[7px] px-[6px] mr-[10px]"
                  size="base"
                />

                <Button
                  label={
                    <span className="flex items-center">
                      <ExportIcon className="mr-[5px]" />
                      Export
                    </span>
                  }
                  onClick={() => {
                    setIsExportModalOpen(true);
                    setIsExporting(false);
                  }}
                  type="button"
                  variant="no-outline"
                  customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                  size="base"
                />

                <Button
                  disabled={disabledData?.disabled}
                  label="+ Add New"
                  onClick={() => {
                    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                    setCreatePopup(true);
                    setSkip(true);
                  }}
                  type="button"
                  variant="primary"
                  customClass={
                    disabledData?.disabledClass +
                    "py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                  }
                  size="base"
                />
              </>
            ) : null}
          </div>
        </div>

        <OT2Component
          department={department}
          setDepartment={setDepartment}
          setPagination={setPagination}
          pagination={pagination}
          departData={departData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setLimit={setLimit}
          setIsTemplate={setIsTemplate}
          isTemplate={isTemplate}
          currentViewData={currentViewData}
          currentView={currentView}
          setCurrentView={setCurrentView}
          moduleList={moduleList}
          deleteTemplateData={deleteTemplateData}
          setErrorPopup={setErrorPopup}
          setSuccessPopup={setSuccessPopup}
          setMessage={setMessage}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          otStatus={otStatus}
          setOtStatus={setOtStatus}
          statusListData={statusListData}
        />

        <animated.div style={props}>
          <OTListTable
            data={data}
            setData={setData}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            pagination={pagination}
            setPagination={setPagination}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields ? hiddenFields : []}
            templateList={tempList}
            setCurrentView={setCurrentView}
            setIsEdit={setIsEdit}
            isEditing={isEditing}
            localModuleId={localModuleId}
            total={itemCount}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsTemplate={setIsTemplate}
            setCols={setCols}
            visArray={visArray}
            setEditOTData={setEditOTData}
            setSkip={setSkip}
            sorting={sorting}
            setSorting={setSorting}
          />
        </animated.div>
        <OTCreatePopupUpdate
          saveData={SaveOTListData}
          departments={departData}
          setIsOpen={setCreatePopup}
          isOpen={createPopup}
          totalLength={itemCount}
          assignUserListData={assignUserListData}
          setDept={setDept}
          dept={dept}
          setEmployeeData={setEmployeeData}
          employeeData={employeeData}
          uploading={uploading}
          setUploading={setUploading}
          setSkip={setSkip}
          data={data}
          setEmployeeDataID={setEmployeeDataID}
        />
        {updatedOTList}
      </div>
    </div>
  );
};
export default OTList;
