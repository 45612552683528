import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DropdownICon,
  RoundedCloseIcon,
  SearchIcon,
  SelectedIcon,
} from "../common/Icons";
import DebounceSearchInput from "../Tables/DebounceSearchInput";
import classNames from "classnames";
import styles from "./EmployeeTable.module.scss";
import { Box, Fade, Popper, ClickAwayListener } from "@mui/material";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
interface ICustomDropdownList {
  state: any;
  setState: Dispatch<SetStateAction<any>> | (() => void);
  placeholder?: string;
  dropdownItems?: IListItem[];
  className?: string;
  textAlign?: string;
  dropdownborder?: string;
  isFilter?: boolean;
  classNameCus?: string;
  isAction?: boolean;
  customClassName?: string;
  isObjectGet?: boolean;
  isBoard?: boolean;
  isBoardSelected?: boolean;
  clearStatus?: boolean;
  setClearStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  clearTech?: boolean;
  setClearTech?: React.Dispatch<React.SetStateAction<boolean>>;
  clearMember?: boolean;
  setClearMember?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IListItem {
  label: string;
  value: string | number | undefined;
}

const CustomDropdownListMultipleForFilter: FC<ICustomDropdownList> = ({
  state,
  setState,
  dropdownItems,
  placeholder = "All",
  classNameCus,
  isAction,
  customClassName,
  isObjectGet,
  isBoard,
  isBoardSelected,
  clearStatus,
  setClearStatus,
  setClearTech,
  clearTech,
  setClearMember,
  clearMember,
}) => {
  const [placement, setPlacement] = useState<any>("bottom-start");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const labels = dropdownItems
    ?.filter((item) => item.value?.toString() == state)
    ?.map((x) => x.label);
  const [label, setLabel] = useState<string[]>(labels || [placeholder]);

  // console.log("llaxxx", label);
  // console.log("placeholder", placeholder);
  // console.log("state", state);

  // useEffect(() => {

  //   setLabel(labels ?? [placeholder]);
  //   console.log("indede", labels);
  // }, [dropdownItems, placeholder, state]);

  const [search, SetSearch] = useState<string>("");
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const mainContainer = useRef<HTMLDivElement | null>(null);
  const [selectedObj, setSelectedObj] = useState<any>([]);
  // console.log("state", selectedObj);

  const [width, setWidth] = useState<number>();

  const [filteredList, setFilteredList] = useState<any>([]);

  useEffect(() => {
    const keyword = search?.trim().toLowerCase();
    if (keyword.length > 0) {
      const matchedItems = dropdownItems?.filter((item) =>
        item.label?.toLowerCase().includes(keyword)
      );
      setFilteredList(matchedItems);
    } else {
      setFilteredList(dropdownItems);
    }
  }, [search, setFilteredList, dropdownItems]);

  useEffect(() => {
    setWidth(mainContainer.current?.clientWidth as number);
    const resizer: any = window.addEventListener("resize", () =>
      setWidth(mainContainer.current?.clientWidth as number)
    );
    return () => window.removeEventListener("resize", resizer);
  }, []);

  const handleClick = (
    newPlacement: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
  };

  const removeFromSelection = (selected: any) => {
    setSelectedObj((prev: any) =>
      prev.filter((x: any) => x.value != selected.value)
    );
    setLabel((prev: any) => prev.filter((x: any) => x != selected.label));
    setState((prev: any) => prev.filter((x: any) => x != selected.value));
  };

  const renderLabel = (labels: any, placeholder: string) => {
    return (
      <div className="flex items-center">
        <span className="mr-[2px]">{placeholder}: </span>
        {selectedObj.map((selected: any, key: any) => {
          return (
            <div className="flex" key={key}>
              <span className=" py-[0px] mr-[2px] sm:mr-1 px-[10px] border border-[#D8D8D8] rounded-full flex justify-between items-center text-10 sm:text-12 leading-[normal] text-black2">
                {selected.label}
                <span
                  aria-label="remove-item-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFromSelection(selected);
                    // ClearData(selectedObj, setLabel, setState, selected)
                  }}
                >
                  <RoundedCloseIcon className="ml-[10px]" />
                </span>
              </span>
            </div>
          );
        })}
      </div>
    );
  };
  const handleClickAway = () => {
    if (showCreatePopup == true || isCreated == true) {
      setOpen(true);
      setShow(true);
      setIsCreated(false);
    } else {
      setOpen(false);
      setShow(false);
      setIsCreated(false);
    }
  };

  useEffect(() => {
    if (clearStatus) {
      setSelectedObj([]);
      setLabel([]);
      setState([]);
    }
    setTimeout(() => {
      if (setClearStatus) {
        setClearStatus(false);
      }
    }, 500);
  }, [clearStatus]);

  useEffect(() => {
    if (clearTech) {
      setSelectedObj([]);
      setLabel([]);
      setState([]);
    }
    setTimeout(() => {
      if (setClearTech) {
        setClearTech(false);
      }
    }, 500);
  }, [clearTech]);

  useEffect(() => {
    if (clearMember) {
      setSelectedObj([]);
      setLabel([]);
      setState([]);
    }
    setTimeout(() => {
      if (setClearMember) {
        setClearMember(false);
      }
    }, 500);
  }, [clearMember]);

  return (
    <div ref={mainContainer} className="h-full w-full">
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          sx={{ position: "relative" }}
          className="h-full w-full transition-all duration-500"
        >
          <button
            onClick={(event) => {
              handleClick("bottom-start", event);
              setOpen(true);
              setShow(true);
            }}
            className={classNames(
              open ? "bg-[#EFEFEF]" : "bg-white",
              customClassName,
              "hover:text-vorpblue dropdown-hover-btn rounded-[28px] flex w-full items-center justify-between h-full transition-all duration-300 py-[1px] px-[10px] border border-vorpgraylight custom-dropdown-container"
            )}
          >
            <p className="whitespace-nowrap xl:text-sm text-12 text-graydark font-normal mr-1 flex">
              {/* {isAction
                ? state
                : label
                ? label?.find((x) => x != placeholder) && placeholder != "All"
                  ? typeof label == "object"
                    ? renderLabel(label, placeholder)
                    : label //placeholder + ": "
                  : placeholder
                : "All"} */}
                {renderLabel(label, placeholder)}
            </p>
            <DropdownICon />
          </button>
          {show ? (
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={500}>
                  <div>
                    <div
                      style={{ width }}
                      className={classNames(
                        styles.customList,
                        isBoard ? "max-w-[200px]" : "max-w-[170px]",
                        "rounded-4 overflow-hidden w-full  z-10 bg-white",
                        classNameCus
                      )}
                    >
                      <div className="bg-offwhite py-2 px-3">
                        <div className="relative">
                          <DebounceSearchInput
                            setState={SetSearch}
                            debounce={400}
                          >
                            {(setState) => (
                              <input
                                type="text"
                                placeholder="Search"
                                className="py-1 pl-3 pr-2 placeholder:text-black2 text-black2 border border-vorpgraylight rounded-4 focus:outline-none w-full xl:text-sm text-12 leading-5"
                                onChange={(e) => setState(e.target.value)}
                                onKeyDown={(e) => e.stopPropagation()}
                              />
                            )}
                          </DebounceSearchInput>
                          <SearchIcon className="absolute top-0 bottom-0 my-auto right-2" />
                        </div>
                      </div>
                      <div className="flex flex-col overflow-y-auto w-full h-[218px] max-h-[218px] bg-white">
                        <CustomScrollbar
                          color="#CACACA"
                          isVertical={true}
                          isHor={false}
                        >
                          <div className="">
                            {filteredList?.map((item: any, index: number) => (
                              <div
                                onClick={() => {
                                  const duplicate = state?.find(
                                    (x: any) => x == item?.value
                                  );
                                  if (!duplicate) {
                                    setState([...state, item?.value as any]);
                                    setLabel([...label, item?.label]);
                                    setSelectedObj([...selectedObj, item]);
                                  } else {
                                    setState((prev: any) =>
                                      prev?.filter((x: any) => x != item?.value)
                                    );
                                    setLabel((prev: any) =>
                                      prev?.filter((x: any) => x != item?.label)
                                    );
                                    setSelectedObj((prev: any) =>
                                      prev?.filter(
                                        (x: any) => x.value != item?.value
                                      )
                                    );
                                  }
                                }}
                                key={Math.random() * 1000 * index}
                                className={classNames(
                                  item.id == 0 ? "hidden" : "flex",
                                  isBoardSelected
                                    ? "hover:bg-vorpblue hover:text-white px-2"
                                    : "hover:bg-[#EFEFEF] ",
                                  " flex-row items-center justify-between  py-1  text-graydark "
                                )}
                              >
                                {isBoardSelected ? (
                                  <div className="relative max-h-6 mr-1">
                                    <input
                                      readOnly
                                      type="checkbox"
                                      checked={
                                        Boolean(
                                          label?.find((x) => x == item?.label)
                                        ) ?? false
                                      }
                                      id={String(Math.random() * 100)}
                                      className="checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-offwhite rounded hover:cursor-pointer"
                                    />
                                    {label?.find((x) => x == item.label) && (
                                      <SelectedIcon className="absolute top-2 left-0 right-0 m-auto" />
                                    )}
                                  </div>
                                ) : null}
                                <button
                                  type="button"
                                  // onClick={() => {
                                  //   const duplicate = state?.find(
                                  //     (x: any) => x == item?.value
                                  //   );
                                  //   if (!duplicate) {
                                  //     setState([...state, item?.value as any]);
                                  //     setLabel([...label, item?.label]);
                                  //     setSelectedObj([...selectedObj, item]);
                                  //   } else {
                                  //     setState((prev: any) =>
                                  //       prev?.filter(
                                  //         (x: any) => x != item?.value
                                  //       )
                                  //     );
                                  //     setLabel((prev: any) =>
                                  //       prev?.filter(
                                  //         (x: any) => x != item?.label
                                  //       )
                                  //     );
                                  //     setSelectedObj((prev: any) =>
                                  //       prev?.filter(
                                  //         (x: any) => x.value != item?.value
                                  //       )
                                  //     );
                                  //   }
                                  // }}
                                  className={classNames(
                                    !isBoardSelected
                                      ? label?.find((x) => x == item.label)
                                        ? "bg-vorpblue text-white px-3"
                                        : ""
                                      : "",
                                    " px-3 whitespace-normal break-words min-w-[130px] w-full capitalize focus:outline-none flex items-center justify-between group  py-1  transition-all duration-300 text-left xl:text-sm text-12 leading-5"
                                  )}
                                >
                                  {item.label}
                                </button>
                              </div>
                            ))}
                          </div>
                        </CustomScrollbar>
                      </div>
                    </div>
                  </div>
                </Fade>
              )}
            </Popper>
          ) : null}
        </Box>
      </ClickAwayListener>
    </div>
  );
};

export default CustomDropdownListMultipleForFilter;
