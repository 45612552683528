import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type FaqData = {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  description_en: string;
  description_tc: string;
  description_sc: string;
  type: string;
};
type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  categoryTableData: TData;
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
export type PaginationData = {
  page: number;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export type TaskCategoriesList = {
  categoryList: TaskDepartmentListData[];
};
export type ManualData = {
  manualTableData: TData;
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const manualApi = api.injectEndpoints({
  endpoints: (build) => ({
    getManuals: build.query<ManualData, PaginationData>({
      query: ({ limit, page, search }) => ({
        url: `manuals?limit=${limit}&page=${page}&search=${search}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Manuals" as const, id: "LIST" }],
    }),
    createManual: build.mutation<ManualData, FaqData>({
      query(body) {
        return {
          headers: authHeader(),
          url: `manuals`,
          body,
          method: "POST",
        };
      },
    }),
    updateManual: build.mutation<TaskCategoriesList, FaqData>({
      query(body) {
        return {
          headers: authHeader(),
          url: `manuals/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteManual: build.mutation<TaskCategoriesList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/manuals/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Manuals", id: "LIST" }],
    }),
    multiDeleteManual: build.mutation<TaskCategoriesList, string>({
      query: (id) => ({
          url: `manuals`,
          headers: authHeader(),
          method: 'DELETE',
          body: id
      }),
  }),
  }),
});

export const {
  useGetManualsQuery,
  useCreateManualMutation,
  useUpdateManualMutation,
  useDeleteManualMutation,
  useMultiDeleteManualMutation
} = manualApi;

export const {
  endpoints: { getManuals, createManual, updateManual, deleteManual,multiDeleteManual },
} = manualApi;
