import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { withErrorBoundary } from './layouts/RoutedErrorBoundary';
import LoginRoute from './login';
import ForgetPasswordRoute from './forgetpassword';
import EmailSentRoute from './emailsent';
import SectionRoute from './auth/components/SectionRoute';
import UsersRoute, { empRoutes, userRoutes } from './userProfile';
import DashboardRoute from './dashboard';
import { Dashboard } from './dashboard/views/Dashboard';
import MedaiRoute from './media';
import PermissionRoute from './permissions';
import BackendUserRoute, { backendUserRoutes } from './backendUsers';
import NotFoundPage from './components/NotFoundPage';
import ProjectsRoute, { projectRoutes } from './projects';
import jwt_decode from 'jwt-decode';
import { passwordRoutes } from './passwords';
import ActiveTimeReportRoute from './active-time-report';
import { employeeRoutes } from './employee-list';
import AnnouncementRoute, { announcementRoutes } from './announcements';
import Documents, { documentRoutes } from './documents';
// import LeaveApplication from "./components/DashboardProfile/MyLeave/LeaveApplication";
// import LeaveApplicationRoute from "./leave-application";
import { logout } from './app/services/auth.service';
import ResetPasswordRoute from './reset-password';
import PasswordResetSuccessRoute from './password-reset-success';
import Passwords from './passwords';
import CrmRoute, { crmRoutes } from './crm';
import PerformanceAppraisalRoute, {
  performanceRoutes,
} from './performance-appraisal';
import PerformanceAppraisalDetailRoute from './performance-appraisal-detail';
import PageNotFound from './components/PageNotFound/PageNotFound';
import ActivityLogRoute from './activity-log';
// import ProjectBoardRoute from "./pro";
import NotificationView from './components/NotificationView/NotificationView';
import NotificationRoute from './notificationlist';
import { lazy, Suspense } from 'react';
import { useGetLoginDataMutation } from './app/services/dashboard';
import { useEffect } from 'react';
// import CommonTableSkeleton from "./components/TableSkeleton/CommonTableSkeleton";
import EmployeeIncentiveRoute from './employee-incentive';
import ExchangeRateRoute from './exchange-rate';
import DocumentViews from './components/DocumentView/DocumentView';
import ProjectCheckerRoute from './project-checker';
import ProjectCheckerAccRoute from './projectCheckerAcc';
import TaskListRoute from './tasks-list';
import TaskDetailListRoute from './task-detail-list';
import TaskCategoryList from './taskCategory/view/TaskCategory';
import BasicLayout from './layouts/BasicLayout';
import ProjectStatus from './projectStatus/view/ProjectStatus';
import ProjectTech from './project-tech/view/ProjectTech';
import AnnoucementListRoute from './annoucementlist';
import EmployeeLeaveRoute from './employeeLeave';
import MonitoringDetailRoute, {
  monitoringDetailRoute,
} from "./monitoring-detail";
// import Courses from "./lms/courses/view/Courses";
import CRMDetailRoute, { crmDetailRoute } from "./crmDetail";
import Department from './department/view/Department';
import Designation from './designation/view/Designation';
import WorkingShiftRoute from './working-shift';
import CoursesRoute from './lms/courses/index';
import Courses from './lms/courses/index';
import { courseSection, instructorSection } from './lms/urls';
import {
  DashBoardPermis,
  MediaPermis,
  ActivityPermis,
  UserPemis,
  BKUserPermis,
  ProjectPermis,
  ProjectCheckerPermis,
  ProjectCheckerAccPermis,
  TaskListPermis,
  PasswordPermis,
  EmployeePermis,
  AnnouncPermissions,
  DocumentPemis,
  CrmPermis,
  claimTypePermissions,
  claimFormPermissions,
  incentivePermissions,
  exchangeRatePermissions,
  offDayPermissions,
  TaskCategoryPermis,
  AllPermissions,
  NoticeBoardPermissions,
  DepartmentPermis,
  CategoryPermis,
  FAQPermis,
  ManualPermis,
  CMSPermis,
  ReminderPermis,
} from './types/permissions';
import InstructorRoute from './lms/instructor';
import NoticeBoard from './notice_board/views/NoticeBoard';
import NoticeBoardRoute from './notice_board';
import EmployeeRoute from './employees';
import LanuageRoute from './tasks-list';
import NoticeBoardCategory from './noticeboard-category/view/NoticeBoardCategory';
import FAQRoute from './faq';
import FAQ from './faq/view/FAQ';
import FAQType from './faqType/view/FAQType';
import Manuals from './manuals/view/Manuals';
import UsefulLine from './usefulline/view/UsefulLine';
import CMS from './cms/view/CMS';
import EventType from './eventtype/view/EventType';
import EventRemainder from './eventRemainder/view/EventRemainder';
import MobileLogo from './mobileLogo/views/MobileLogo';
// import TaskDetailList from "./task-detail-list/view/TaskDetailList";
// import { useJwt } from "react-jwt";

const LeaveTypeRoute = lazy(() => import('./leave-type'));
const ProjectBoardRoute = lazy(() => import('./project-board'));
const EmployeeListRoute = lazy(() => import('./employee-list'));
const ProjectKeyDateRoute = lazy(() => import('./project-keydate'));
// const PayRollRoute = lazy(() => import("./payroll"));
const ClaimFormRoute = lazy(() => import('./claim-form'));
// const PayrollRateRoute = lazy(() => import("./payroll-rate"));
const ClaimType = lazy(() => import('./claim-type'));
const OffDayRoute = lazy(() => import('./off-day-office-working'));
const AddClaimTypeRoute = lazy(() => import('./claim-new'));

export interface userData {
  email: string;
  token: string;
  role: string;
}

export const checkToken = (route: any) => {
  var userData = localStorage.getItem('userData');
  var userJson: any = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token = userJson.accessToken;
  }
  var isValid = false;
  if (userJson.token != undefined && userJson.token != null) {
    try {
      const decodedToken: { sub: string; role: string; exp: number } =
        jwt_decode(userJson.token);
      const userId = decodedToken.sub;
      const userRole = decodedToken.role;
      if (decodedToken.exp * 1000 < Date.now()) {
        logout();
        route('/login');
      }
      isValid = true;
    } catch (e) {
      isValid = false;
    }
  }
  return isValid;
};

const MainRouter = () => {
  const [getLoginData] = useGetLoginDataMutation();
  var pathData = new URLSearchParams(window.location.search);
  var code = pathData.get('code');
  var state = pathData.get('state');
  var userData = localStorage.getItem('userData');
  useEffect(() => {
    if (code && state) {
      var data = {
        code: code,
        state: state,
      };
      getLoginData(data).then((res: any) => {
        if (res.data) {
          userData = localStorage.getItem('userData');
        }
      });
    }
  }, []);
  const navigate = useNavigate();

  var userJson: any = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token = userJson.accessToken;
  }
  var data = userJson;

  var isValid = checkToken(navigate);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <LoginRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/forgetpassword"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <ForgetPasswordRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <ResetPasswordRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/resetpassword-success"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <PasswordResetSuccessRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/emailsent"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <EmailSentRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/"
          element={
            <SectionRoute
              loginData={data}
              permissions={DashBoardPermis}
              isValid={isValid}
            >
              <DashboardRoute loginData={data} permissions={DashBoardPermis} />
            </SectionRoute>
          }
        />

        <Route
          path="/media"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={MediaPermis}
            >
              <MedaiRoute loginData={data} permissions={MediaPermis} />
            </SectionRoute>
          }
        />

        <Route
          path="/activity_log"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ActivityPermis}
            >
              <ActivityLogRoute loginData={data} permissions={ActivityPermis} />
            </SectionRoute>
          }
        />

        {/* Activity log */}

        <Route
          path="/working-shift"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ActivityPermis}
            >
              <WorkingShiftRoute
                loginData={data}
                permissions={ActivityPermis}
              />
            </SectionRoute>
          }
        />

        {/* project board route */}

        <Route
          path="/projects-board"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ProjectPermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <ProjectBoardRoute
                  loginData={data}
                  permissions={ProjectPermis}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route>

        <Route
        path="/dashboard"
        element={
          <SectionRoute
            loginData={data}
            permissions={UserPemis}
            isValid={isValid}
          >
            <DashboardRoute loginData={data} />
          </SectionRoute>
        }
      ></Route>
        <Route
          path="/profile"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <UsersRoute loginData={data} permissions={UserPemis} />
            </SectionRoute>
          }
        >
          {userRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/employeeprofile/:id"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <UsersRoute loginData={data} permissions={UserPemis} />
            </SectionRoute>
          }
        >
          {userRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/users"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <BackendUserRoute loginData={data} permissions={UserPemis} />
            </SectionRoute>
          }
        >
          {backendUserRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/projects"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ProjectPermis}
            >
              <ProjectsRoute loginData={data} permissions={ProjectPermis} />
            </SectionRoute>
          }
        >
          {projectRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>

        <Route
          path="/progress-checker"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ProjectCheckerPermis}
            >
              <ProjectCheckerRoute
                loginData={data}
                permissions={ProjectCheckerPermis}
              />
            </SectionRoute>
          }
        />

        <Route
          path="/project-checker-acc"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ProjectCheckerAccPermis}
            >
              <ProjectCheckerAccRoute
                loginData={data}
                permissions={ProjectCheckerAccPermis}
              />
            </SectionRoute>
          }
        />

        <Route
          path="/language"
          element={
            <LanuageRoute loginData={data} permissions={[]} />
          }
        />

        <Route
          path="/progress-checker/:id/task-detail-list"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={TaskListPermis}
            >
              <TaskDetailListRoute
                loginData={data}
                permissions={TaskListPermis}
              />
            </SectionRoute>
          }
        />

        <Route
          path="/password"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={PasswordPermis}
            >
              <Passwords loginData={data} permissions={PasswordPermis} />
            </SectionRoute>
          }
        >
          {passwordRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        {/* employee  list */}
        <Route
          path="/employeeList"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <EmployeeRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {employeeRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/announcement"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={AnnouncPermissions}
            >
              <AnnouncementRoute
                loginData={data}
                permissions={AnnouncPermissions}
              />
            </SectionRoute>
          }
        >
          {announcementRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/notice_board"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={NoticeBoardPermissions}
            >
              <NoticeBoardRoute
                loginData={data}
                permissions={NoticeBoardPermissions}
              />
            </SectionRoute>
          }
        >
          
        </Route>




        {/* end crm */}
        <Route
          path="/profile/announcement"
          element={
            <AnnoucementListRoute
              loginData={data}
              permissions={EmployeePermis}
            />
          }
        ></Route>
        {/* ----------------for claim form type-------------------- */}
       
        {/* <Route
          path="/payroll-rate"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={claimFormPermissions}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <PayrollRateRoute
                  loginData={data}
                  permissions={claimFormPermissions}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route> */}
       
        <Route
          path="*"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <PageNotFound />
            </SectionRoute>
          }
        ></Route>

        {/* ----------------for project key date list-------------------- */}
        <Route
          path="/project-keydate"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={TaskCategoryPermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <ProjectKeyDateRoute
                  loginData={data}
                  permissions={TaskCategoryPermis}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end project key date list-------------------- */}

        {/* ----------------for department list-------------------- */}
        <Route
          path="/departments"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={DepartmentPermis}
            >
              <BasicLayout userData={data} permissions={DepartmentPermis}>
                <Department />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end department list-------------------- */}
        {/* ----------------for category list-------------------- */}
        <Route
          path="/category"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CategoryPermis}
            >
              <BasicLayout userData={data} permissions={CategoryPermis}>
                <NoticeBoardCategory />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
         <Route
          path="/eventtype"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CategoryPermis}
            >
              <BasicLayout userData={data} permissions={CategoryPermis}>
                <EventType />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/event-reminder"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ReminderPermis}
            >
              <BasicLayout userData={data} permissions={ReminderPermis}>
                <EventRemainder />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/custom_logo"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CategoryPermis}
            >
              <BasicLayout userData={data} permissions={CategoryPermis}>
                <MobileLogo />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/faq"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={FAQPermis}
            >
              <BasicLayout userData={data} permissions={FAQPermis}>
              <FAQ/>
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/manuals"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ManualPermis}
            >
              <BasicLayout userData={data} permissions={ManualPermis}>
              <Manuals/>
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/usefullines"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CMSPermis}
            >
              <BasicLayout userData={data} permissions={CMSPermis}>
              <UsefulLine/>
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/cms"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CMSPermis}
            >
              <BasicLayout userData={data} permissions={CMSPermis}>
              <CMS/>
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/faq_types"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={FAQPermis}
            >
              <BasicLayout userData={data} permissions={FAQPermis}>
              <FAQType/>
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end department list-------------------- */}

        {/* ----------------for leave type list-------------------- */}
        {/* <Route
          path="/leave-type"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <LeaveTypeRoute loginData={data} permissions={EmployeePermis} />
              </Suspense>
            </SectionRoute>
          }
        ></Route> */}
        {/* ----------------end leave type list-------------------- */}

        {/* ----------------for designations list-------------------- */}
        {/* <Route
          path="/designations"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <BasicLayout userData={data} permissions={EmployeePermis}>
                <Designation />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route> */}
        {/* ----------------end designations list-------------------- */}

        
        {/* ----------------end courses list-------------------- */}
      </Routes>
    </>
  );
};

export default withErrorBoundary(MainRouter);
