import React from "react";
import { StringDropdownListWithDeleteBtn } from "../common/StringDropdownListWithDeleteBtn";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
// import CustomDropdownListForFilter from "../EmployeeTable/CustomDropdownListForFilter";
// import DatePopupFoFilter from "../EmployeeTable/DatePopupFoFilter";
import { NumberDropdownList } from "../common/DropdownList";
import { AddIcon, CloseIcon, CustomizeLayoutIcon, ExportIcon, SearchIcon } from "../common/Icons";
import { PaginationState } from "@tanstack/react-table";
import { IDepartment, TStatusListData } from "../../ot-list/views/OTList";
import classNames from "classnames";
import Button from "../common/Button";
import { useNavigate } from "react-router-dom";
import DebounceSearchInput from "../Tables/DebounceSearchInput";
import CustomDropdownListMultipleForFilter from "../EmployeeTable/CustomDropdownListMultipleForFilter";

export type OT2Component = {
  // department: string | number;
  // setDepartment: React.Dispatch<React.SetStateAction<string | number>>;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  pagination: PaginationState;
  // departData: IDepartment[];
  // startDate: Date | undefined;
  // setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  // setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  // endDate: Date | undefined;
  // setLimit: React.Dispatch<React.SetStateAction<number>>;
  setIsTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  isTemplate: boolean;
  currentViewData: number;
  currentView: any;
  setCurrentView: React.Dispatch<any>;
  deleteTemplateData: (id: string) => void;
  moduleList: any;
  setErrorPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<any>;
  setIsLayoutControlOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOtStatus: React.Dispatch<any>;
  otStatus: any;
  statusListData: TStatusListData[];
  // limitStatus: boolean;
  // setLimitStatus: React.Dispatch<React.SetStateAction<boolean>>;
  // limitedData: TStatusListData[];
  filterOneName?: string;
  // filterTwoName?: string;
  setGlobalSearch: React.Dispatch<React.SetStateAction<string>>;
  // isExportModalOpen:boolean;
  setIsExportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>
};
const ProjectChecker2Component = ({
  // department,
  // setDepartment,
  setPagination,
  pagination,
  // departData,
  // startDate,
  // setStartDate,
  // setEndDate,
  // endDate,
  // setLimit,
  setIsTemplate,
  isTemplate,
  currentViewData,
  currentView,
  setCurrentView,
  deleteTemplateData,
  moduleList,
  setErrorPopup,
  setSuccessPopup,
  setMessage,
  setIsLayoutControlOpen,
  otStatus,
  setOtStatus,
  statusListData,
  // limitedData,
  // limitStatus,
  // setLimitStatus,
  filterOneName,
  // filterTwoName,
  setGlobalSearch,
  // isExportModalOpen,
  setIsExportModalOpen,
  setIsExporting
}: OT2Component) => {
  const navigate = useNavigate();

  return (
    <div className="flex lg:flex-row flex-col justify-between items-center mb-1">
      <div className="flex w-full sm:mr-5 custom-scrollbar sm:mt-0 mt-1 sm:mb-0 mb-1">
        <CustomScrollbar isVertical={false} isHor={true}>
          <div className="flex w-full items-center">
            <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px]">
              <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                {(setState) => (
                  <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                    <input
                      type="text"
                      placeholder="Search"
                      className="focus:outline-none py-[2px] px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                      onChange={(e) => setState(e.target.value)}
                    />
                    <SearchIcon className={classNames("mr-2")} />
                  </div>
                )}
              </DebounceSearchInput>
            </div>
            <div className="flex items-center w-auto">
              <div
                className={classNames(
                  "flex items-center mr-[10px] w-auto ml-2"
                )}
              >
                {/* <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                  {filterOneName}
                </p> */}
                <CustomDropdownListMultipleForFilter
                  placeholder={filterOneName}
                  state={otStatus}
                  setState={setOtStatus}
                  dropdownItems={
                    statusListData
                      ? [...statusListData.map((st) => ({
                        value: st.id,
                        label: st.name,
                      }))]
                      : []
                  }
                  textAlign="text-left"
                  className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                />
              </div>
              {/* <div
                className={classNames(
                  "flex items-center mr-[10px] w-auto ml-2"
                )}
              >
                <CustomDropdownListForFilter
                  placeholder={filterTwoName}
                  state={limitStatus}
                  setState={setLimitStatus}
                  dropdownItems={
                    limitedData
                      ? limitedData.map((st) => ({
                          value: st.id,
                          label: st.name,
                        }))
                      : []
                  }
                  textAlign="text-left"
                  className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                />
              </div> */}
            </div>
          </div>
        </CustomScrollbar>
      </div>
      <div className="flex items-center lg:justify-between justify-end lg:w-auto w-full md:flex-nowrap flex-wrap">
        <Button
          label={
            <span className="flex items-center">
              <ExportIcon className="mr-[5px]" />
              Export
            </span>
          }
          onClick={() => {
            setIsExportModalOpen(true);
            setIsExporting(false);
          }}
          type="button"
          variant="no-outline"
          customClass="mb-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
          size="base"
        />
        <div className="flex items-center  mr-3">
          <p className="base-label xl:text-sm text-12 hidden 3xs:block">
            DISPLAY
          </p>
          <NumberDropdownList
            state={pagination.pageSize}
            setState={(value) => {
              setPagination((prev) => ({
                ...prev,
                pageSize: value as number,
              }));
            }}
            dropdownItems={new Array(5).fill(1).map((_, index: number) => {
              let display = (index + 1) * 20;
              return {
                value: display,
                label: String(display),
              };
            })}
            className="max-w-[72px] pl-2 pr-3"
            textAlign="text-left pl-2 pr-3"
            placeholder="20"
            paddingClass="py-[2px]"
          />
        </div>
        <StringDropdownListWithDeleteBtn
          haveDeleteBtn={true}
          setIsTemplate={setIsTemplate}
          state={isTemplate ? currentViewData : currentView}
          setState={setCurrentView}
          deleteTemplate={deleteTemplateData}
          dropdownItems={moduleList.map((x: any, index: number) => {
            return {
              label: x.id == -1 ? x.name : "View #" + x.index,
              value: x.id,
            };
          })}
          className="w-full min-w-[100px] 2xl:min-w-[137px] 6xs:text-12"
          placeholder="View #1"
          textAlign="text-left py-2 pl-2 pr-3"
          custPadding="py-[2px]"
          setErrorPopup={setErrorPopup}
          setSuccessPopup={setSuccessPopup}
          setMessage={setMessage}
        />
        <button
          type="button"
          onClick={() => setIsLayoutControlOpen(true)}
          className="flex relative justify-center items-center rounded-lg bg-vorphongkongborder w-[29px] h-[29px] ml-2 group transition-all duration-300  mr-2"
        >
          <CustomizeLayoutIcon pathClassName="group-hover:fill-white" />
          <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder hover:bg-vorphongkongborder flex justify-center items-center">
            <AddIcon width={8} height={8} color="white" />
          </div>
        </button>
        <div className="my-1">
          <Button
            type="button"
            label={
              <span className="flex items-center text-md leading-[12px] justify-center ">
                {/* <CloseIcon
                width={14}
                height={14}
                className="mr-[10px] [&_path]:fill-graydark"
              /> */}
                Task List
              </span>
            }
            onClick={() => {
              // setIsOpen(false);
              // setUploading(false);
              // setSkip(false);
              // cleanData();
              navigate("/all-task-list");
            }}
            variant="no-outline"
            size="sm"
            customClass="min-w-[80px] ml-3 min-h-[30.6px] bg-[#1B3757] hover:text-[#1B3757] hover:bg-white border border-[#1B3757] text-white transition-all"
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectChecker2Component;
