export const PERMISSIONS = {
  View_Announcement: "View_Announcement",
  Import_Announcement: "Import_Announcement",
  Export_Announcement: "Export_Announcement",
  Create_New_Announcement: "Create_New_Announcement",
  Management_Announcement: "Management_Announcement",
  Delete_Announcement: "Delete_Announcement",
  View_Own_ClaimForm_List: "View_Own_ClaimForm_List",
  View_All_ClaimForm_List: "View_All_ClaimForm_List",
  Manage_All_ClaimForm_List: "Manage_All_ClaimForm_List",
  Manage_Own_ClaimForm_List: "Manage_Own_ClaimForm_List",
  Manage_ClaimForms: "Manage_ClaimForms",
  Approve_ClaimForms: "Approve_ClaimForms",
  Create_Claim_Form: "Create_Claim_Form",
  Manage_Exchange_Rates: "Manage_Exchange_Rates",
  Manage_Employee_Incentives: "Manage_Employee_Incentives",
  View_Document: "View_Document",
  View_Own_Document: "View_Own_Document",
  Download_My_Document: "Download_My_Document",
  Upload_Document: "Upload_Document",
  Download_Document: "Download_Document",
  Assign_Document: "Assign_Document",
  Delete_Document: "Delete_Document",
  View_My_Profile: "View_My_Profile",
  Export_User: "Export_User",
  Import_User: "Import_User",
  Manage_User: "Manage_User",
  View_User: "View_User",
  Activate_User: "Activate_User",
  Create_New_User: "Create_New_User",
  Delete_User: "Delete_User",
  View_Employee: "View_Employee",
  View_Leave_Application: "View_Leave_Application",
  View_OTList: "View_OTList",
  View_All_OT_List: "View_All_OT_List",
  View_Own_OT_List: "View_Own_OT_List",
  View_Active_Time_Report: "View_Active_Time_Report",
  Import_Employee: "Import_Employee",
  Create_New_Employee: "Create_New_Employee",
  Manage_Employee: "Manage_Employee",
  View_Employee_Profile: "View_Employee_Profile",
  View_Employee_Coded_Value: "View_Employee_Coded_Value",
  Send_Employee_Message: "Send_Employee_Message",
  Delete_Employee: "Delete_Employee",
  Manage_Employee_Basic_Info: "Manage_Employee_Basic_Info",
  Manage_Payroll: "Manage_Payroll",
  View_Admin_Dashboard: "View_Admin_Dashboard",
  Manage_Office_Working_Days: "Manage_Office_Working_Days",
  View_Media_Library: "View_Media_Library",
  Manage_Media_Library: "Manage_Media_Library",

  View_Activity_Log: "View_Activity_Log",
  Manage_Activity_Log: "Manage_Activity_Log",
  View_Progress_Checker: "View_Progress_Checker",
  View_Progress_Checker_Account: "View_Progress_Checker_Account",
  View_Role: "View_Role",
  View_Project: "View_Project",
  View_Crm: "View_Crm",
  View_Password: "View_Password",
  View_Own_Password: "View_Own_Password",
  Manage_Task_Categories: "Manage_Task_Categories",
  View_NotiBoard: "View_NotiBoard",
  Import_NoticeBoard: "Import_NoticeBoard",
  Export_NoticeBoard: "Export_NoticeBoard",
  Create_NotiBoard: "Create_NotiBoard",
  Manage_NotiBoard: "Manage_NotiBoard",
  Delete_NoticeBoard: "Delete_NoticeBoard",
  View_Department: "View_Department",
  Create_Department: "Create_Department",
  Manage_Department: "Manage_Department",
  View_Category: "View_Category",
  Create_Category: "Create_Category",
  Manage_Category: "Manage_Category",
  Create_Faq: "Create_Faq",
  Manage_Faq: "Manage_Faq",
  View_Faq: "View_Faq",
  Create_Manuals: "Create_Manuals",
  Manage_Manuals: "Manage_Manuals",
  View_Manuals: "View_Manuals",
  Create_CMS: "Create_CMS",
  Manage_CMS: "Manage_CMS",
  View_CMS: "View_CMS",
  View_ActivityLog: "View_ActivityLog",
  Manage_ActivityLog: "Manage_ActivityLog",
  Create_CalendarReminder: "Create_CalendarReminder",
  Manage_CalendarReminder: "Manage_CalendarReminder",
  View_CalendarReminder: "View_CalendarReminder",
} as const;
export type PermissionEnum = keyof typeof PERMISSIONS;

export const AnnouncPermissions: PermissionEnum[] = [
  "View_Announcement",
  "Import_Announcement",
  "Export_Announcement",
  "Create_New_Announcement",
  "Management_Announcement",
  "Delete_Announcement",
];
export const NoticeBoardPermissions: PermissionEnum[] = [
  "View_NotiBoard",
  "Import_NoticeBoard",
  "Export_NoticeBoard",
  "Create_NotiBoard",
  "Manage_NotiBoard",
  "Delete_NoticeBoard",
];
export const claimFormPermissions: PermissionEnum[] = [
  "View_Own_ClaimForm_List",
  "View_All_ClaimForm_List",
  "Manage_All_ClaimForm_List",
  "Manage_Own_ClaimForm_List",
  "Manage_ClaimForms",
  "Approve_ClaimForms",
  // "Create_Claim_Form",
];
export const exchangeRatePermissions: PermissionEnum[] = [
  "Manage_Exchange_Rates",
];
export const incentivePermissions: PermissionEnum[] = [
  "Manage_Employee_Incentives",
];
export const claimTypePermissions: PermissionEnum[] = [
  "Manage_ClaimForms",
  // "Create_Claim_Form",
];

export const DocumentPemis: PermissionEnum[] = [
  "View_Document",
  "View_Own_Document",
  "Download_My_Document",
  "Upload_Document",
  "Download_Document",
  "Assign_Document",
  "Delete_Document",
];

export const UserPemis: PermissionEnum[] = [
  "View_My_Profile",
  "Export_User",
  "Import_User",
  "Manage_User",
  "View_User",
  "Activate_User",
  "Create_New_User",
  "Delete_User",
];

export const DashBoardPermis: PermissionEnum[] = ["View_Admin_Dashboard"];
export const offDayPermissions: PermissionEnum[] = [
  "Manage_Office_Working_Days",
];

export const MediaPermis: PermissionEnum[] = [
  "View_Media_Library",
  "Manage_Media_Library",
];

export const ActivityPermis: PermissionEnum[] = [
  "View_ActivityLog",
  "Manage_ActivityLog",
];

export const ProjectCheckerPermis: PermissionEnum[] = ["View_Progress_Checker"];
export const ProjectCheckerAccPermis: PermissionEnum[] = [
  "View_Progress_Checker_Account",
];

export const TaskListPermis: PermissionEnum[] = ["View_Progress_Checker"];

export const BKUserPermis: PermissionEnum[] = ["View_Role", "View_User"];
export const AllPermissions = ["View", "Updated", "Created", "Deleted"];

export const ProjectPermis: PermissionEnum[] = ["View_Project"];

export const CrmPermis: PermissionEnum[] = ["View_Crm", "View_Project"];

export const PasswordPermis: PermissionEnum[] = [
  "View_Password",
  "View_Own_Password",
];

export const EmployeePermis: PermissionEnum[] = [
  "View_Employee",
  "View_Leave_Application",
  "View_OTList",
  "View_All_OT_List",
  "View_Own_OT_List",
  "View_Active_Time_Report",
  "Import_Employee",
  "Create_New_Employee",
  "Manage_Employee",
  "View_Employee_Profile",
  "View_Employee_Coded_Value",
  "Send_Employee_Message",
  "Delete_Employee",
  "Manage_Employee_Basic_Info",
  "Manage_Payroll",
];
export const DepartmentPermis: PermissionEnum[] = [
  "View_Department",
  "Create_Department",
  "Manage_Department",
];
export const CategoryPermis: PermissionEnum[] = [
  "View_Category",
  "Create_Category",
  "Manage_Category",
];
export const FAQPermis: PermissionEnum[] = [
  "Create_Faq",
  "Manage_Faq",
  "View_Faq",
];
export const ManualPermis: PermissionEnum[] = [
  "Create_Manuals",
  "Manage_Manuals",
  "View_Manuals",
];
export const CMSPermis: PermissionEnum[] = [
  "Create_CMS",
  "Manage_CMS",
  "View_CMS",
];
export const ReminderPermis: PermissionEnum[] = [
  "Create_CalendarReminder",
  "Manage_CalendarReminder",
  "View_CalendarReminder",
];
export const TaskCategoryPermis: PermissionEnum[] = ["Manage_Task_Categories"];
