import { redirectToLogin } from './annoucement-sheet';
import { api, endpointUrl } from './api';
import authHeader from './auth-header';
export type RoleResponse = { data: rTData[] };
export type rTData = {
    module_id: number,
    rolesTableData: RoleData,
}
export type RoleData = {
    data: rData[],
    total: number,
}

export type RoleList = {
    rolesList: RoleListData[]
}

export type RoleListData = {
    id: number;
    name: string;
    label: string;
}

export type rData = {
    id: number,
    name: string,
    label: string,
    permissions: PermissionData[],
}

export type PaginateRole = {
    search?: string | number;
    page?: number | string;
    limit?: number | string;
    sort?:string
}
export type PermissionData = {
    name: string
}
export const rolesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getRoleList: build.query<RoleList, void>({
            query: () => ({
                headers: authHeader(),
                url: `${endpointUrl}getRoleList`,
                method: "GET"
            }),
            transformErrorResponse:(err)=>{
                if(err?.status===401){
                 redirectToLogin();
                }
              },
            providesTags: [{ type: 'RoleListData', id: "LIST" }]
        }),

        getRoles: build.query<rTData, PaginateRole>({
            query: ({ page, limit, search,sort }) => ({
                headers: authHeader(),
                url: `${endpointUrl}roles?page=${page}&limit=${limit}&search=${search}&sort=${sort}`,
                // method: "GET"
            }),
            transformErrorResponse:(err)=>{
                if(err?.status===401){
                 redirectToLogin();
                }
              },
            providesTags: [{ type: 'RoleData', id: "LIST" }]
        }),
        updateRole: build.mutation<rData, Partial<rData>>({
            query: ({ id, ...rest }) => ({
                url: `${endpointUrl}roles/${id}`,
                headers: authHeader(),
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: (RoleData) => [{ type: 'RoleData', id: RoleData?.id }],
        }),

        addRole: build.mutation<rData, Partial<rData>>({
            query: (data) => ({
                url: `${endpointUrl}roles`,
                headers: authHeader(),
                method: "POST",
                body: data
            }),
            invalidatesTags: [{ type: 'RoleData', id: "LIST" }],
        }),
        deleteRole: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `${endpointUrl}roles/${id}`,
                headers: authHeader(),
                method: 'DELETE',
            }),
            invalidatesTags: (RoleData) => [{ type: 'RoleData', id: RoleData?.id }],
        }),
        multiDeleteRole: build.mutation<rData, Partial<rData>>({
            query: ({ id, ...rest }) => ({
                url: `${endpointUrl}deleteRoles`,
                headers: authHeader(),
                method: 'DELETE',
                body: rest
            }),
            invalidatesTags: (RoleData) => [{ type: 'RoleData', id: RoleData?.id }],
        }),
    }),
});

export const {
    useGetRoleListQuery,
    useGetRolesQuery,
    useUpdateRoleMutation,
    useAddRoleMutation,
    useDeleteRoleMutation,
    useMultiDeleteRoleMutation
} = rolesApi;

export const {
    endpoints: {
        getRoles,
        updateRole,
        addRole,
        deleteRole,
        multiDeleteRole,
        getRoleList
    },
} = rolesApi;